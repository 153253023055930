import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTimeISO: any;
  Decimal: any;
  LocalDate: any;
  NonNegativeInt: any;
  PositiveInt: any;
};

export enum AccountType {
  BankAccount = 'BankAccount',
  Bpay = 'Bpay',
  PayId = 'PayId',
}

export type ActivateBaPhysicalCardInput = {
  /** The business account id */
  accountId: Scalars['String'];
  /** The card id of the card to activate */
  cardId: Scalars['String'];
  /** The last 4 digits of the card number */
  lastFourDigits: Scalars['String'];
  /** The step up token obtained from stepUpVerify endpoint */
  stepUpToken: Scalars['String'];
};

export type ActivateBaPhysicalCardOutput = {
  __typename?: 'ActivateBAPhysicalCardOutput';
  success: Scalars['Boolean'];
};

export type Address = {
  __typename?: 'Address';
  /** Administrative region of address. Usually state or territory */
  administrativeRegion: Scalars['String'];
  /** Country of this address */
  country: Scalars['String'];
  /** Line 1 of address including subunit number if applicable */
  line1: Scalars['String'];
  /** Postal code of this address */
  postcode: Scalars['String'];
  /** Town or City of address */
  townOrCity: Scalars['String'];
};

export type AddressInputType = {
  administrativeRegion: Scalars['String'];
  /** A valid ISO 3166-1 alpha-3 officially assigned country code eg `AUS` is valid, `OZZ` is invalid */
  country: Scalars['String'];
  line1: Scalars['String'];
  postalCode: Scalars['String'];
  townOrCity: Scalars['String'];
};

/** BAPayee payee details. Avaliable for AU only */
export type BaPayee = IPayee & {
  __typename?: 'BAPayee';
  /** Customer/Account ID. Shared for AU and NZ */
  account: Scalars['String'];
  /** Bank payee: The name of the owner of the destination account. BPay payee: The nickname of the biller */
  accountName?: Maybe<Scalars['String']>;
  /** BSB number. AU only */
  bsb?: Maybe<Scalars['String']>;
  /** County of a payee */
  countryCode: Country;
  id: Scalars['ID'];
  paymentType: PaymentType;
};

/** Recipient Bpay details */
export type BPayDetails = {
  __typename?: 'BPayDetails';
  /** Biller code */
  billerCode: Scalars['String'];
  /** Biller name */
  billerName?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  /** Biller nickname */
  nickname: Scalars['String'];
  /** BPAY Customer reference number (CRN) */
  reference: Scalars['String'];
};

/** Recipient account number */
export type BankDetails = {
  __typename?: 'BankDetails';
  /** The account number of the transaction if applicable */
  accountNumber?: Maybe<Scalars['String']>;
  /** The bsb of the transaction if applicable. */
  bsb?: Maybe<Scalars['String']>;
  /** Recipient name */
  name: Scalars['String'];
};

/** Bank payee details */
export type BankPayee = {
  __typename?: 'BankPayee';
  /** Name associated with bank account */
  accountName: Scalars['String'];
  /** The account number of the payee */
  accountNumber: Scalars['String'];
  /** The BSB number of the payee */
  bankStateBranch: Scalars['String'];
  /** Date of creation of the payee */
  createDate: Scalars['DateTimeISO'];
  id: Scalars['String'];
};

/** Bank payee details */
export type BankPayeeV2 = IPayee & {
  __typename?: 'BankPayeeV2';
  /** Customer/Account ID. Shared for AU and NZ */
  account: Scalars['String'];
  /** Bank payee: The name of the owner of the destination account. BPay payee: The nickname of the biller */
  accountName?: Maybe<Scalars['String']>;
  /** Identifies the bank. NZ only */
  bank?: Maybe<Scalars['String']>;
  /** Branch of the bank. NZ only */
  branch?: Maybe<Scalars['String']>;
  /** BSB number in XXX-XXX format. AU only */
  bsb?: Maybe<Scalars['String']>;
  /** County of a payee */
  countryCode: Country;
  /** The name of the financial institution. This is a read-only field. */
  financialInstitution?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentType: PaymentType;
  /** This usually changes for the account type. i.e the same person may have a checking and savings account, and only the last 3 digits would be different. NZ only */
  suffix?: Maybe<Scalars['String']>;
};

/** An Australian bank transfer payee input */
export type BankTransferAuPayeeInput = {
  /** The name of the owner of the destination account */
  accountName?: InputMaybe<Scalars['String']>;
  /** Customer/Account ID */
  accountNumber: Scalars['String'];
  /** BSB number in XXX-XXX format */
  bsb: Scalars['String'];
};

/** A New Zealand bank transfer payee input */
export type BankTransferNzPayeeInput = {
  /** The name of the owner of the destination account */
  accountName?: InputMaybe<Scalars['String']>;
  /** Customer/Account ID */
  accountNumber: Scalars['String'];
  /** Identifies the bank. */
  bankId: Scalars['String'];
  /** Branch of the bank. */
  branch: Scalars['String'];
  /** This usually changes for the account type. i.e the same person may have a checking and savings account, and only the last 3 digits would be different. */
  suffix: Scalars['String'];
};

/** Bpay detail used in bill pay */
export type BillBPayPayment = {
  __typename?: 'BillBPayPayment';
  /** Biller code used in Bpay Payment */
  billerCode?: Maybe<Scalars['String']>;
  /** Bpay reference */
  reference?: Maybe<Scalars['String']>;
};

/** Bpay details used in bill pay */
export type BillBPayPaymentInput = {
  /** Biller code used in Bpay Payment */
  billerCode?: InputMaybe<Scalars['String']>;
  /** Bpay reference */
  reference?: InputMaybe<Scalars['String']>;
};

/** Bank transfer details used in bills pay */
export type BillBankTransferPayment = {
  __typename?: 'BillBankTransferPayment';
  /** Account Name */
  accountName?: Maybe<Scalars['String']>;
  /** Account number */
  accountNumber?: Maybe<Scalars['String']>;
  /** BSB number */
  bsb?: Maybe<Scalars['String']>;
  /** Bank transfer reference */
  reference?: Maybe<Scalars['String']>;
};

/** Bank transfer details used in bills pay */
export type BillBankTransferPaymentInput = {
  /** Account Name */
  accountName?: InputMaybe<Scalars['String']>;
  /** Account number */
  accountNumber?: InputMaybe<Scalars['String']>;
  /** BSB number */
  bsb?: InputMaybe<Scalars['String']>;
  /** Bank transfer reference */
  reference?: InputMaybe<Scalars['String']>;
};

/** User Bill Details */
export type BillDetails = {
  __typename?: 'BillDetails';
  /** Amount */
  amount?: Maybe<Money>;
  /** Bill source */
  billSource?: Maybe<BillSource>;
  /** Biller ABN (issuer ABN) */
  billerABN?: Maybe<Scalars['String']>;
  /** Biller name (issuer name) */
  billerName?: Maybe<Scalars['String']>;
  /** Description (aka invoice number) */
  description?: Maybe<Scalars['String']>;
  /** Due date */
  dueDate?: Maybe<Scalars['DateTimeISO']>;
  /** File name */
  fileName?: Maybe<Scalars['String']>;
  /** File URL */
  fileUrl: Scalars['String'];
  /** Bill ID */
  id: Scalars['ID'];
  /** Bill Reference Payment */
  linkedTransactions?: Maybe<Array<LinkedTransaction>>;
  /** Bill payment methods */
  paymentMethods?: Maybe<BillPaymentMethods>;
  /** Bill status */
  status: BillStatus;
  /** Upload Date */
  uploadDate?: Maybe<Scalars['DateTimeISO']>;
};

/** The way user uploaded the bill */
export enum BillInputMethod {
  /** Indicates user uploaded the bill via email */
  Email = 'Email',
}

/** A Prospa user's BillPay */
export type BillPay = {
  __typename?: 'BillPay';
  /** get a bill by id */
  bill?: Maybe<BillDetails>;
  /** get bills by billStatus */
  bills?: Maybe<Array<BillDetails>>;
  dueNextMonthAmount?: Maybe<Money>;
  dueThisMonthAmount?: Maybe<Money>;
  /** BillPay ID (we use unique bill pay email for caching) */
  id: Scalars['ID'];
  /**
   * Returns true if user should be shown the bill pay feature.
   *
   * A user can see the bill pay feature if they have a BA or LOCM
   */
  isVisible: Scalars['Boolean'];
  /**
   * Unique bill pay email.
   *
   * If null is returned initialiseBillPay mutation needs to be run.
   */
  uploadEmailAddress?: Maybe<Scalars['String']>;
};

/** A Prospa user's BillPay */
export type BillPayBillArgs = {
  id: Scalars['ID'];
};

/** A Prospa user's BillPay */
export type BillPayBillsArgs = {
  dueDateFrom?: InputMaybe<Scalars['String']>;
  dueDateTo?: InputMaybe<Scalars['String']>;
  status: Array<BillStatus>;
};

export type BillPayDeleteOutput = {
  __typename?: 'BillPayDeleteOutput';
  success: Scalars['Boolean'];
};

/** The input argument for update bills mutation */
export type BillPaymentMethodInput = {
  /** Bank transfer payment details */
  bankTransferPayment?: InputMaybe<BillBankTransferPaymentInput>;
  /** BPAY payment details */
  bpayPayment?: InputMaybe<BillBPayPaymentInput>;
  /** Available bill payment types */
  paymentTypes: Array<BillPaymentType>;
};

/** Bill Payment Method */
export type BillPaymentMethods = {
  __typename?: 'BillPaymentMethods';
  /** Bank transfer payment details */
  bankTransferPayment?: Maybe<BillBankTransferPayment>;
  /** BPAY payment details */
  bpayPayment?: Maybe<BillBPayPayment>;
  /** Available bill payment types */
  paymentTypes?: Maybe<Array<BillPaymentType>>;
};

/** Bill Payment Type */
export enum BillPaymentType {
  /** BPAY payment type */
  Bpay = 'BPAY',
  /** Bank transfer payment type */
  BankTransfer = 'BankTransfer',
}

/** User Bill Details */
export type BillSource = {
  __typename?: 'BillSource';
  /** The way user uploaded the bill */
  inputMethod: BillInputMethod;
  /** The email address user used to receive the bill */
  receiverAddress?: Maybe<Scalars['String']>;
  /** The email address user used to upload the bill */
  senderEmailAddress?: Maybe<Scalars['String']>;
};

/** The internal status of the bill */
export enum BillStatus {
  /** Indicate a failed payment. */
  Failed = 'Failed',
  /** Indicate a successful payment. */
  Paid = 'Paid',
  /** A bill becomes pending when we add a reference payment with a scheduled date as today */
  Pending = 'Pending',
  /** A bill has been received and handled */
  Received = 'Received',
  /** A bill becomes reviewed when we update it for the first time (aka `NOT_SCHEDULED`) */
  Reviewed = 'Reviewed',
  /** A bill becomes scheduled when we add a reference payment with a scheduled date later than today */
  Scheduled = 'Scheduled',
}

/** Bill Transaction Status */
export enum BillTransactionStatus {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Pending = 'Pending',
  Success = 'Success',
}

/** Bill Transaction Type */
export enum BillTransactionType {
  PayNow = 'PayNow',
  Scheduled = 'Scheduled',
}

/** Prospa’s Blog */
export type Blog = {
  __typename?: 'Blog';
  /** Prospa’s Blog ID */
  id: Scalars['ID'];
  /** A collection of blog posts */
  posts?: Maybe<BlogConnection>;
};

/** Prospa’s Blog */
export type BlogPostsArgs = {
  after?: InputMaybe<Scalars['NonNegativeInt']>;
  first: Scalars['PositiveInt'];
};

/** A blog post list connection */
export type BlogConnection = {
  __typename?: 'BlogConnection';
  edges: Array<BlogEdge>;
  pageInfo: PageInfo;
  /** The total number of posts in the connection */
  totalEdges: Scalars['Float'];
  /** The total number of pages with the current arguments */
  totalPages: Scalars['Float'];
};

/** A node of a blog post list */
export type BlogEdge = {
  __typename?: 'BlogEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Post;
};

/** Bpay payee details */
export type BpayPayee = {
  __typename?: 'BpayPayee';
  /** Bpay biller code */
  billerCode: Scalars['String'];
  /** Biller name of the bpay bill */
  billerName: Scalars['String'];
  id: Scalars['String'];
  /** Nickname of the bill */
  nickname: Scalars['String'];
  /** Reference of the bill */
  reference: Scalars['String'];
};

/** Bpay payee details. Avaliable for AU only */
export type BpayPayeeV2 = IPayee & {
  __typename?: 'BpayPayeeV2';
  /** Bank payee: The name of the owner of the destination account. BPay payee: The nickname of the biller */
  accountName?: Maybe<Scalars['String']>;
  bPayBillerCode: Scalars['String'];
  bPayReference: Scalars['String'];
  /** Official name of the company you're paying. This is different to accountName. */
  billerName?: Maybe<Scalars['String']>;
  /** County of a payee */
  countryCode: Country;
  id: Scalars['ID'];
  paymentType: PaymentType;
};

/** A bPay payee input */
export type BpayTransferPayeeInput = {
  /** Bpay Biller nickname */
  accountName: Scalars['String'];
  /** Bpay Biller code */
  bPayBillerCode: Scalars['String'];
  /** Reference number */
  bPayReference: Scalars['String'];
};

/** Financial institution with bsb fields */
export type Bsb = {
  __typename?: 'Bsb';
  /** Address of the BSB */
  bsbAddress: Scalars['String'];
  /** Name of the BSB, could be branch location or FI name */
  bsbName: Scalars['String'];
  /** BSB of financial institution */
  bsbNumber: Scalars['String'];
  /** City associated with the BSB */
  city: Scalars['String'];
  /** Financial institution code of associated bsb */
  financialInstitutionCode: Scalars['String'];
  /** BSB id */
  id: Scalars['ID'];
  /** Postcode associated with BSB */
  postcode: Scalars['String'];
  /** State or territory within Australia */
  state: Scalars['String'];
};

/** A Prospa Business account */
export type BusinessAccount = {
  __typename?: 'BusinessAccount';
  /** ABN associated with this business account */
  abn?: Maybe<Scalars['String']>;
  /** Account name */
  accountName: Scalars['String'];
  /** Account number. */
  accountNumber: Scalars['String'];
  /** Indicates if an overdraft has been enabled for the account. */
  allowOverdraft: Scalars['Boolean'];
  /** Account balance values. */
  balance: BusinessAccountBalance;
  /** Branch / BSB number for the account. */
  branchNumber: Scalars['String'];
  /** Business Account Detail ID */
  businessAccountDetailId: Scalars['ID'];
  /** Get list of cards */
  cards?: Maybe<Array<Card>>;
  /** Account creation date in UTC. */
  createdAt: Scalars['DateTimeISO'];
  /** Currency in 3 letter code (ISO 4127). */
  currency: CurrencyCode;
  /** Address associated with this business account */
  homeAddress?: Maybe<Address>;
  /** Hay Account ID */
  id: Scalars['ID'];
  /** Overdraft details of this user if enabled */
  overdraft: OverdraftDetail;
  /** Get list of payees for business account */
  payees: Array<BusinessAccountPayee>;
  /** Get list of pending transactions */
  pendingTransactions: Array<BusinessAccountTransaction>;
  /** Get a scheduled transaction */
  scheduledTransaction?: Maybe<BusinessAccountScheduledTransaction>;
  /** Get a list of scheduled transactions */
  scheduledTransactions?: Maybe<Array<BusinessAccountScheduledTransaction>>;
  /** Account status. */
  status: BusinessAccountStatus;
  /** Get a single transaction by ID. */
  transaction?: Maybe<BusinessAccountTransaction>;
  /** Get a list of transactions */
  transactions: BusinessAccountTransactionConnection;
  /** Last account update date in UTC. */
  updatedAt?: Maybe<Scalars['DateTimeISO']>;
};

/** A Prospa Business account */
export type BusinessAccountPayeesArgs = {
  paymentType: PayeePaymentType;
};

/** A Prospa Business account */
export type BusinessAccountScheduledTransactionArgs = {
  id: Scalars['ID'];
};

/** A Prospa Business account */
export type BusinessAccountScheduledTransactionsArgs = {
  status?: InputMaybe<BusinessAccountScheduledPaymentStatus>;
};

/** A Prospa Business account */
export type BusinessAccountTransactionArgs = {
  id: Scalars['ID'];
  isPending?: Scalars['Boolean'];
};

/** A Prospa Business account */
export type BusinessAccountTransactionsArgs = {
  after?: InputMaybe<Scalars['NonNegativeInt']>;
  first: Scalars['PositiveInt'];
};

/** The balance for a Prospa Business account */
export type BusinessAccountBalance = {
  __typename?: 'BusinessAccountBalance';
  /** Total balance minus the held and locked balances. */
  available: Money;
  /** In certain circumstances, a card transaction will be authorised but not settled. This may result in the value of the transaction being deducted (held) from the balance and therefore unavailable for use - We refer to this as a 'held' balance. */
  held: Money;
  /** An amount that has been locked for any other purpose. */
  locked: Money;
  /** The amount of an overdraft used within the agreed limit. */
  overdraft: Money;
  /** A total amount allocated to stacks. */
  stacks: Money;
  /** The amount of an overdraft used extra to the arranged overdraft limit, or amount overdrawn when an arranged overdraft is not in place. */
  technicalOverdraft: Money;
  /** The current balance. Can be negative if in overdraft. */
  total: Money;
};

/** Successful response from business account qualify check request */
export type BusinessAccountOriginationQualifyCheck = {
  __typename?: 'BusinessAccountOriginationQualifyCheck';
  /** Automated qualification decision for a Business Account application */
  automatedQualificationDecision: QualificationDecisions;
  /** Correlation Id */
  correlationId: Scalars['ID'];
  /** Current stage/status of application */
  cxStage: CxStage;
  /** Is an existing contact */
  isExistingContact: Scalars['Boolean'];
  /** Is an existing Prospa customer */
  isExistingCustomer: Scalars['Boolean'];
  /** Is a director or owner of the business */
  isOwner: Scalars['Boolean'];
};

export type BusinessAccountPayee = BankPayee | BpayPayee;

/** Possible Scheduled payment types */
export enum BusinessAccountSchedulePaymentType {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
}

/** Possible Scheduled payment frequencies */
export enum BusinessAccountScheduledPaymentFrequency {
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
}

/** Possible Scheduled payment types */
export enum BusinessAccountScheduledPaymentStatus {
  /** Schedule is active, will execute */
  Active = 'ACTIVE',
  /** Scheduled payment suspended. Such payment can be re-activated, but this functionality is not yet exposed to clients */
  Cancelled = 'CANCELLED',
  /** All payments related to a scheduled payment have executed (requested no. of payments has been made, or expiry date has passed) */
  Completed = 'COMPLETED',
  /** Deleted scheduled payment. Can not be re-enabled */
  Deleted = 'DELETED',
  /** This only applies for scheduled payments created with cancelOnFailure flag set. If such scheduled payment fails because of insufficient funds or another rule that makes a payment fail, all the future payments will not be executed and the status will be FAILED (failed on sending account) */
  Failed = 'FAILED',
  /** This only applies for scheduled payments created with cancelOnFailure flag set. If such outbound schedule payment is returned, it will stop and the status will be rejected (failed on receiver account and payment returned) */
  Rejected = 'REJECTED',
  /** After a scheduled payment is edited, a new one is created and the old version becomes REPLACED */
  Replaced = 'REPLACED',
}

/** Get list of all scheduled transactions */
export type BusinessAccountScheduledTransaction = {
  __typename?: 'BusinessAccountScheduledTransaction';
  /** Scheduled payment amount & currency */
  amount: Money;
  /** Indicates whether the scheduled payment should be cancelled on the first failure */
  cancelOnFailure?: Maybe<Scalars['Boolean']>;
  /** Transaction description (max 120 chars) */
  description: Scalars['String'];
  /** Recipient bank or BPay details */
  details: TransactionDetails;
  /** Date of the last payment in UTC */
  endDate?: Maybe<Scalars['Date']>;
  /** Scheduled payment frequency */
  frequency?: Maybe<BusinessAccountScheduledPaymentFrequency>;
  /** Hay transaction ID */
  id: Scalars['ID'];
  /** Next transaction date. Note: this is a calculated field */
  nextDate?: Maybe<Scalars['Date']>;
  /** Number of payments to be sent */
  numberOfPayments?: Maybe<Scalars['NonNegativeInt']>;
  /** Transaction reference (max 35 chars) */
  reference?: Maybe<Scalars['String']>;
  /** Date of the first payment in UTC */
  startDate: Scalars['Date'];
  /** Scheduled payment status */
  status?: Maybe<BusinessAccountScheduledPaymentStatus>;
  /** Scheduled payment type */
  type: BusinessAccountSchedulePaymentType;
};

/** The internal status of the business account */
export enum BusinessAccountStatus {
  /** Account is active */
  Active = 'Active',
  /** Account is blocked (isn't used) */
  Blocked = 'Blocked',
  /** Account is closed */
  Closed = 'Closed',
  /** Account has been frozen (risk level is high) */
  Frozen = 'Frozen',
  /** Status doesn't match expected values (e.g. ACTIVE_IN_ARREARS, DORMANT, PENDING_APPROVAL) */
  Unknown = 'Unknown',
}

/** A bank transfer or bpay transaction details */
export type BusinessAccountTransaction = {
  __typename?: 'BusinessAccountTransaction';
  /** Transaction amount & currency */
  amount: Money;
  /** Transaction description (max 120 chars) */
  description?: Maybe<Scalars['String']>;
  /** Recipient bank or BPay details */
  details?: Maybe<TransactionDetails>;
  /** The name of counterpart */
  displayName?: Maybe<Scalars['String']>;
  /** Hay transaction ID */
  id: Scalars['ID'];
  /**
   * Who initiated the transaction
   * @deprecated This value is not available and will always return null. If you need this value please fetch from the transaction list.
   */
  initiatedBy?: Maybe<Scalars['String']>;
  /** If the transaction is awaiting settlement */
  isPending: Scalars['Boolean'];
  /** Transaction reference (max 35 chars) */
  reference?: Maybe<Scalars['String']>;
  /** The date of the transaction */
  transactionTime: Scalars['DateTimeISO'];
};

/** A business transactions list connection */
export type BusinessAccountTransactionConnection = {
  __typename?: 'BusinessAccountTransactionConnection';
  edges: Array<BusinessAccountTransactionEdge>;
  pageInfo: PageInfo;
};

/** A node of a business transaction list */
export type BusinessAccountTransactionEdge = {
  __typename?: 'BusinessAccountTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node: BusinessAccountTransactionListItem;
};

/** A bank transfer or bpay transaction details  */
export type BusinessAccountTransactionListItem = {
  __typename?: 'BusinessAccountTransactionListItem';
  /** Transaction amount & currency */
  amount: Money;
  /** Transaction description (max 120 chars) */
  description?: Maybe<Scalars['String']>;
  /**
   * Recipient bank or BPay details
   * @deprecated We cannot get these values reliably from the payload, do not use this value and fetch from transaction(id) instead.
   */
  details?: Maybe<TransactionDetails>;
  /** The name of counterpart */
  displayName?: Maybe<Scalars['String']>;
  /** Hay transaction ID */
  id: Scalars['ID'];
  /** Who initiated the transaction */
  initiatedBy?: Maybe<Scalars['String']>;
  /** If the transaction is awaiting settlement */
  isPending: Scalars['Boolean'];
  /** Transaction reference (max 35 chars) */
  reference?: Maybe<Scalars['String']>;
  /** The date of the transaction */
  transactionTime: Scalars['DateTimeISO'];
};

/** Cancel a scheduled payment input */
export type CancelScheduledPaymentInput = {
  /** Correlation Id / only used for loc cl pay */
  correlationId?: InputMaybe<Scalars['String']>;
  /** Indicates the product id */
  productId: Scalars['String'];
  /** Indicates the payment id to be cancelled */
  transactionId: Scalars['String'];
  /** Verification code */
  verificationCode?: InputMaybe<Scalars['String']>;
};

/** Cancel a scheduled payment output */
export type CancelScheduledPaymentOutput = {
  __typename?: 'CancelScheduledPaymentOutput';
  success: Scalars['Boolean'];
};

/** Prospa Card Details */
export type Card = {
  __typename?: 'Card';
  /** Indicates whether contactless payments are enabled */
  contactless: Scalars['Boolean'];
  /** Design reference of card */
  designReference: Scalars['String'];
  /** Details of the digital wallets */
  digitalWalletDetails?: Maybe<DigitalWalletDetails>;
  /** Indicates whether the card is frozen */
  frozen: Scalars['Boolean'];
  /** Unique ID of the card */
  id: Scalars['ID'];
  /** Prospa card image */
  image?: Maybe<Scalars['String']>;
  /** Indicates whether magstrip payments are enabled */
  magStrip: Scalars['Boolean'];
  /** Indicates whether mobile wallet payments are enabled */
  mobileWalletPaymentEnabled: Scalars['Boolean'];
  /** Name printed on the card */
  nameOnCard: Scalars['String'];
  /** Status of the card PIN - true for enabled */
  pinEnabled: Scalars['Boolean'];
  /** Card status */
  status: CardStatus;
};

/** Card Meta Response */
export type CardMeta = {
  __typename?: 'CardMeta';
  /** Indicates whether contactless payments are enabled */
  contactless: Scalars['Boolean'];
  /** Design reference of card */
  designReference: Scalars['String'];
  /** Indicates whether the card is frozen */
  frozen: Scalars['Boolean'];
  /** Unique ID of the card */
  id: Scalars['ID'];
  /** Indicates whether magstrip payments are enabled */
  magStrip: Scalars['Boolean'];
  /** Indicates whether mobile wallet payments are enabled */
  mobileWalletPaymentEnabled: Scalars['Boolean'];
  /** Card status */
  status: CardStatus;
};

/** Possible Prospa card statuses */
export enum CardStatus {
  Active = 'Active',
  AwaitingActivation = 'AwaitingActivation',
  Blocked = 'Blocked',
  Expired = 'Expired',
  Inactive = 'Inactive',
}

export type Contract = {
  __typename?: 'Contract';
  /** Application ID. */
  applicationId: Scalars['ID'];
  /** Contract amount to display. */
  displayAmount: Money;
  /** Label to display for contract amount. */
  displayAmountLabel: Scalars['String'];
  /** Contract status to display user. This should only ever be in Pending or Awaiting. */
  displayStatus: Scalars['String'];
  /** Contract title to display user. This should be the product name. */
  displayTitle: Scalars['String'];
  /** Contract expiry timestamp. */
  expiryDateTime?: Maybe<Scalars['DateTimeISO']>;
  /** Contract ID. */
  id: Scalars['ID'];
  /** Name of product the contract is for. */
  productName: Scalars['String'];
  /** Contract status. */
  status: ContractStatus;
};

export enum ContractStatus {
  /** All directors/shareholders have signed. */
  AllSigned = 'ALL_SIGNED',
  /** The contract has been cancelled. */
  Cancelled = 'CANCELLED',
  /** User has chosen to declined the contract. */
  Declined = 'DECLINED',
  /** The contract has been expired. */
  Expired = 'EXPIRED',
  /** User has not signed. */
  NotSigned = 'NOT_SIGNED',
  /** Multi director, user has signed but we require another director/shareholder to sign. */
  WaitingForOthersToSign = 'WAITING_FOR_OTHERS_TO_SIGN',
}

/** ISO 3166-1 alpha-2 Country Code information */
export enum Country {
  /** Australia */
  Au = 'AU',
  /** New Zealand */
  Nz = 'NZ',
}

/** Possible known validation errors when creating a new BPay payee */
export enum CreateBPayErrorStatusV2 {
  InternalError = 'INTERNAL_ERROR',
  InvalidBillerCode = 'INVALID_BILLER_CODE',
  InvalidNickname = 'INVALID_NICKNAME',
  InvalidReference = 'INVALID_REFERENCE',
  NicknameAlreadyExists = 'NICKNAME_ALREADY_EXISTS',
}

export type CreateBusinessAccountInput = {
  /** ABN */
  ABN: Scalars['String'];
  /** businessStructure */
  businessStructure: Scalars['String'];
  /** Country Code */
  countryCode: Scalars['String'];
  /** Date Of Birth */
  dateOfBirth: Scalars['Date'];
  /** driver License State */
  driverLicenseState: Scalars['String'];
  /** drivers License Card Number */
  driversLicenseCardNumber: Scalars['String'];
  /** driving License Number */
  drivingLicenseNumber: Scalars['String'];
  /** email Address */
  emailAddress: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  /** home City */
  homeCity: Scalars['String'];
  /** home Postal Code */
  homePostalCode: Scalars['String'];
  /** home State */
  homeState: Scalars['String'];
  /** home Street */
  homeStreet: Scalars['String'];
  /** industry */
  industry: Scalars['String'];
  /** industryCategory */
  industryCategory: Scalars['String'];
  /** Is Owner */
  isOwner: Scalars['Boolean'];
  /** Last name */
  lastName: Scalars['String'];
  /** Middle name */
  middleName?: InputMaybe<Scalars['String']>;
  /** mobile Number */
  mobileNumber: Scalars['String'];
  /** Sessions Parameters */
  sessionsParameters: SessionsParameters;
  /** trading Name */
  tradingName: Scalars['String'];
};

/** Successful response from create business account */
export type CreateBusinessAccountOutput = {
  __typename?: 'CreateBusinessAccountOutput';
  /** Correlation Id */
  correlationId?: Maybe<Scalars['ID']>;
};

export type CreateDrawdownOutput = {
  __typename?: 'CreateDrawdownOutput';
  applicationId: Scalars['String'];
};

export type CreateLineOfCreditInput = {
  /** Drawdown amount */
  amount: Scalars['Decimal'];
  /** Application id of product */
  applicationId: Scalars['String'];
  /** Correlation Id for the drawdown transaction */
  correlationId: Scalars['String'];
};

export type CreatePayeeV2ErrorOutput = {
  __typename?: 'CreatePayeeV2ErrorOutput';
  error: CreateBPayErrorStatusV2;
};

/** Combination of Payee & BankPayeeV2 & BpayPayeeV2. Validation is based on PaymentType, requires step up */
export type CreatePayeeV2Input = {
  /** Customer/Account ID. Shared for AU and NZ */
  account?: InputMaybe<Scalars['String']>;
  /** Bank payee: The name of the owner of the destination account. BPay payee: The nickname of the biller */
  accountName?: InputMaybe<Scalars['String']>;
  /** Additional validation is applied for BA users */
  bPayBillerCode?: InputMaybe<Scalars['String']>;
  /** Additional validation is applied for BA users */
  bPayReference?: InputMaybe<Scalars['String']>;
  /** Identifies the bank. NZ only */
  bank?: InputMaybe<Scalars['String']>;
  /** Branch of the bank. NZ only */
  branch?: InputMaybe<Scalars['String']>;
  /** BSB number in XXX-XXX format. AU only */
  bsb?: InputMaybe<Scalars['String']>;
  /** Country of a payee */
  countryCode: Country;
  paymentType: InputPaymentType;
  /** The step up token obtained from stepUpVerify endpoint */
  stepUpToken: Scalars['String'];
  /** This usually changes for the account type. i.e the same person may have a checking and savings account, and only the last 3 digits would be different. NZ only */
  suffix?: InputMaybe<Scalars['String']>;
};

export type CreatePayeeV2Output = CreatePayeeV2ErrorOutput | CreatePayeeV2SuccessOutput;

export type CreatePayeeV2SuccessOutput = {
  __typename?: 'CreatePayeeV2SuccessOutput';
  payee: Payee;
};

/** 3 letter code (ISO 4127) */
export enum CurrencyCode {
  /** United Arab Emirates Dirham */
  Aed = 'AED',
  /** Afghan Afghani */
  Afn = 'AFN',
  /** Albanian Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillean Guilder */
  Ang = 'ANG',
  /** Angolan Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Florin */
  Awg = 'AWG',
  /** Azerbaijani Manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark */
  Bam = 'BAM',
  /** Barbadian Dollar */
  Bbd = 'BBD',
  /** Bangladeshi Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Bahraini Dinar */
  Bhd = 'BHD',
  /** Burundian Franc */
  Bif = 'BIF',
  /** Bermudian Dollar */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Bolivian Boliviano */
  Bob = 'BOB',
  /** Bolivian Mvdol (funds code) */
  Bov = 'BOV',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Bhutanese Ngultrum */
  Btn = 'BTN',
  /** Botswana Pula */
  Bwp = 'BWP',
  /** Belarusian Ruble */
  Byn = 'BYN',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese Franc */
  Cdf = 'CDF',
  /** Swiss Franc */
  Chf = 'CHF',
  /** Chilean Peso */
  Clp = 'CLP',
  /** Chinese Yuan */
  Cny = 'CNY',
  /** Colombian Peso */
  Cop = 'COP',
  /** Costa Rican Colón */
  Crc = 'CRC',
  Cuc = 'CUC',
  /** Cuban Peso */
  Cup = 'CUP',
  /** Cape Verdean Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djiboutian Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Eritrean Nakfa */
  Ern = 'ERN',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fijian Dollar */
  Fjd = 'FJD',
  /** Falkland Islands Pound */
  Fkp = 'FKP',
  /** British Pound Sterling */
  Gbp = 'GBP',
  /** Georgian Lari */
  Gel = 'GEL',
  /** Ghanaian Cedi */
  Ghs = 'GHS',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Gambian Dalasi */
  Gmd = 'GMD',
  /** Guinean Franc */
  Gnf = 'GNF',
  /** Guatemalan Quetzal */
  Gtq = 'GTQ',
  /** Guyanaese Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Honduran Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Haitian Gourde */
  Htg = 'HTG',
  /** Hungarian Forint */
  Huf = 'HUF',
  /** Indonesian Rupiah */
  Idr = 'IDR',
  /** Israeli New Shekel */
  Ils = 'ILS',
  /** Indian Rupee */
  Inr = 'INR',
  /** Iraqi Dinar */
  Iqd = 'IQD',
  /** Iranian Rial */
  Irr = 'IRR',
  /** Icelandic Króna */
  Isk = 'ISK',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Jordanian Dinar */
  Jod = 'JOD',
  /** Japanese Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Kyrgystani Som */
  Kgs = 'KGS',
  /** Cambodian Riel */
  Khr = 'KHR',
  /** Comorian Franc */
  Kmf = 'KMF',
  /** North Korean Won */
  Kpw = 'KPW',
  /** South Korean Won */
  Krw = 'KRW',
  /** Kuwaiti Dinar */
  Kwd = 'KWD',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Kazakhstani Tenge */
  Kzt = 'KZT',
  /** Lao Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lankan Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Lesotho Loti */
  Lsl = 'LSL',
  /** Libyan Dinar */
  Lyd = 'LYD',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Macedonian Denar */
  Mkd = 'MKD',
  /** Myanma Kyat */
  Mmk = 'MMK',
  /** Mongolian Tögrög */
  Mnt = 'MNT',
  /** Macanese Pataca */
  Mop = 'MOP',
  /** Mauritanian Ouguiya */
  Mru = 'MRU',
  /** Mauritian Rupee */
  Mur = 'MUR',
  /** Maldivian Rufiyaa */
  Mvr = 'MVR',
  /** Malawian Kwacha */
  Mwk = 'MWK',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Mozambican Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Nigerian Naira */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Omani Rial */
  Omr = 'OMR',
  /** Panamanian Balboa */
  Pab = 'PAB',
  /** Peruvian Nuevo Sol */
  Pen = 'PEN',
  /** Papua New Guinean Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistani Rupee */
  Pkr = 'PKR',
  /** Polish Złoty */
  Pln = 'PLN',
  /** Paraguayan Guarani */
  Pyg = 'PYG',
  /** Qatari Riyal */
  Qar = 'QAR',
  /** Romanian Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwandan Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychellois Rupee */
  Scr = 'SCR',
  /** Sudanese Pound */
  Sdg = 'SDG',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helena Pound */
  Shp = 'SHP',
  /** Sierra Leonean Leone (new leone) */
  Sle = 'SLE',
  /** Sierra Leonean Leone (old leone) */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinamese Dollar */
  Srd = 'SRD',
  /** South Sudanese Pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe dobra */
  Stn = 'STN',
  /** Salvadoran colón */
  Svc = 'SVC',
  /** Syrian Pound */
  Syp = 'SYP',
  /** Eswatini Lilangeni */
  Szl = 'SZL',
  /** Thai Baht */
  Thb = 'THB',
  /** Tajikistani Somoni */
  Tjs = 'TJS',
  /** Turkmenistan Manat */
  Tmt = 'TMT',
  /** Tunisian Dinar */
  Tnd = 'TND',
  /** Tongan Pa'anga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** Tuvaluan Dollar */
  Tvd = 'TVD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia */
  Uah = 'UAH',
  /** Ugandan Shilling */
  Ugx = 'UGX',
  /** United States Dollar */
  Usd = 'USD',
  /** Uruguayan Peso */
  Uyu = 'UYU',
  /** Uzbekistan Som */
  Uzs = 'UZS',
  /** Venezuelan Digital Bolívar */
  Ved = 'VED',
  /** Venezuelan Bolívar */
  Ves = 'VES',
  /** Vietnamese Đồng */
  Vnd = 'VND',
  /** Vanuatu Vatu */
  Vuv = 'VUV',
  /** Samoan Tala */
  Wst = 'WST',
  /** Central African CFA Franc */
  Xaf = 'XAF',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** West African CFA Franc */
  Xof = 'XOF',
  /** CFP Franc */
  Xpf = 'XPF',
  /** Yemeni Rial */
  Yer = 'YER',
  /** South African Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmw = 'ZMW',
  /** Zimbabwean dollar */
  Zwl = 'ZWL',
}

export enum CxStage {
  /** The submitted application is in Bank statement step stage */
  BankStatementsStep = 'BANK_STATEMENTS_STEP',
  /** The submitted application is in Pre Quote stage */
  PreQuote = 'PRE_QUOTE',
  /** Qualification checks completed for a submitted Business Account application */
  QualifyCompleted = 'QUALIFY_COMPLETED',
  /** Qualification checks in progess for a submitted Business Account application */
  QualifyInProgress = 'QUALIFY_IN_PROGRESS',
  /** The submitted application is in Unknown stage */
  Unknown = 'UNKNOWN',
}

export type DeleteBankPayeeInput = {
  id: Scalars['ID'];
};

export type DeleteBankPayeeOutput = {
  __typename?: 'DeleteBankPayeeOutput';
  id: Scalars['ID'];
};

/** The input argument for deleteBillPaymentReference mutation */
export type DeleteBillPaymentReferenceInput = {
  /** Indicates the product id. */
  accountId: Scalars['String'];
  /** Indicates the payment id to be deleted. */
  referenceId: Scalars['String'];
  /** Indicates the verification code for the cancel request. */
  verificationCode?: InputMaybe<Scalars['String']>;
};

export type DeleteBpayBillerInput = {
  id: Scalars['ID'];
};

export type DeletePayeeInput = {
  payeeId: Scalars['String'];
};

export type DeletePayeeOutput = {
  __typename?: 'DeletePayeeOutput';
  payeeId: Scalars['String'];
};

/** Digital applications that require user action */
export type DigitalApplication = {
  __typename?: 'DigitalApplication';
  /** Returns the ID of an approved digital refinance application SBL */
  digitalRefiApprovedApplicationSblId?: Maybe<Scalars['String']>;
  /** Returns the ID of an LOC where there is an agreement that requires the user to approve */
  pendingSignatureLocId?: Maybe<Scalars['String']>;
  /** Returns the ID of an SBL where there is an agreement that requires the user to approve */
  pendingSignatureSblId?: Maybe<Scalars['String']>;
};

export type DigitalWallet = {
  __typename?: 'DigitalWallet';
  /** Reference identifier */
  reference: Scalars['String'];
  /** Wallet type */
  type: WalletType;
};

export type DigitalWalletDetails = {
  __typename?: 'DigitalWalletDetails';
  /** Primary account identifier */
  primaryAccountIdentifier?: Maybe<Scalars['String']>;
  /** An array of the device wallets */
  wallets: Array<DigitalWallet>;
};

export type DisconnectIntegrationInput = {
  integration: IntegrationProduct;
};

export type DisconnectIntegrationOutput = {
  __typename?: 'DisconnectIntegrationOutput';
  success: Scalars['Boolean'];
};

/** Line of credit drawdown details */
export type DrawdownDetails = {
  __typename?: 'DrawdownDetails';
  /** This is the same as the Line of credit id. */
  id: Scalars['ID'];
  /** Returns true if user is allowed to drawdown from this Line of credit. */
  isEnabled: Scalars['Boolean'];
  /**
   * Returns true if user should be shown the drawdown feature.
   *
   * Note: This does not necessarily mean they are able to drawdown.
   */
  isVisible: Scalars['Boolean'];
  /**
   * @deprecated
   *       This status has the same meaning as LineOfCreditStatus and has been
   *       migrated to LineOfCreditContractStatus to simplify and clarify LOC cases.
   *
   *       This variable is still live in the repositories all-in-one-web and prospa-mobile.
   *       Please do not remove this status without refactoring all-in-one-web and prospa-mobile.
   *
   */
  status: DrawdownStatus;
  /** The text within the banner on the drawdown page. */
  text: Scalars['String'];
};

/** The possible statuses to control a drawdown */
export enum DrawdownStatus {
  Active = 'ACTIVE',
  /** Customer may be going through hardship, and they can request that repayments are deferred ($0) for [X] weeks. */
  Deferred = 'DEFERRED',
  /** LOC application/contract is going through refinancing  and no locked state for LOCM. */
  Locked = 'LOCKED',
  /** The loan is suspended due to 2 missed repayments. */
  Suspended = 'SUSPENDED',
  /** Will be returned if LineStatusType isn't 'LineActive' or 'LineSuspended' */
  Unavaliable = 'UNAVALIABLE',
}

export type ExchangeAuthCodeOutput = {
  __typename?: 'ExchangeAuthCodeOutput';
  statusCode: Scalars['String'];
};

/** Error codes for contract sign url generation */
export enum GenerateContractSignUrlErrorCode {
  /** User entered date of birth is incorrect. */
  IncorrectDob = 'INCORRECT_DOB',
  /** Too many failed attempts. */
  Locked = 'LOCKED',
  /** Unable to find contract for user. */
  NotFound = 'NOT_FOUND',
  /** Unknown/unexpected error. */
  Unknown = 'UNKNOWN',
}

export type GenerateContractSignUrlErrorOutput = {
  __typename?: 'GenerateContractSignUrlErrorOutput';
  /** Error message to show to the user. */
  code: GenerateContractSignUrlErrorCode;
  /** Error message to show to the user. */
  message: Scalars['String'];
};

export type GenerateContractSignUrlInput = {
  /** Contract ID to generate for */
  contractId: Scalars['String'];
  /** Contract signing user's Date Of Birth */
  dateOfBirth: Scalars['Date'];
};

export type GenerateContractSignUrlOutput =
  | GenerateContractSignUrlErrorOutput
  | GenerateContractSignUrlSuccessOutput;

export type GenerateContractSignUrlSuccessOutput = {
  __typename?: 'GenerateContractSignUrlSuccessOutput';
  /** Contract signing url. This is a link that will redirect back to the online portal on completion. */
  url: Scalars['String'];
};

export type IPayee = {
  /** Bank payee: The name of the owner of the destination account. BPay payee: The nickname of the biller */
  accountName?: Maybe<Scalars['String']>;
  /** County of a payee */
  countryCode: Country;
  id: Scalars['ID'];
  paymentType: PaymentType;
};

export enum InputPaymentType {
  /** When used with countryCode: NZ, should specify `bank`, `branch` and `suffix` */
  BankTransfer = 'BANK_TRANSFER',
  /** Only avaliable for countryCode: AU, should specify `bpayBillerCode`, `bpayBillerReferenece` */
  Bpay = 'BPAY',
}

export enum InputProductType {
  /** Product type of business account */
  BusinessAccount = 'BusinessAccount',
  LineOfCreditMambu = 'LineOfCreditMambu',
}

/** Connections to external vendor accounting software */
export type Integration = {
  __typename?: 'Integration';
  /** Unique ID as retrieved from BankFeed schema */
  id: Scalars['ID'];
  /** Determines whether the integration is authenticated for the user to use */
  isAuthenticated: Scalars['Boolean'];
  /** Determines whether the integration is connected for the user to use */
  isConnected: Scalars['Boolean'];
  /** The id of a particular product which belongs to the user */
  productId?: Maybe<Scalars['String']>;
  /** The type of a particular product which belongs to the user */
  productType?: Maybe<ProspaProduct>;
  /** The last date and time as to when the accounting integration synced up */
  syncDateTime?: Maybe<Scalars['DateTimeISO']>;
  /** The type of error returned from a sync attempt */
  syncErrorType?: Maybe<Scalars['String']>;
  /** The name of the accounting integration product */
  type: IntegrationProduct;
};

/** The name of the accounting integration product */
export enum IntegrationProduct {
  Xero = 'Xero',
}

/** The error status of a particular product's connection to an integration. */
export enum IntegrationProductErrorStatus {
  SyncError = 'SYNC_ERROR',
}

/** Details relating to the connection of a product to an accounting integration */
export type IntegrationProductInfo = {
  __typename?: 'IntegrationProductInfo';
  /** The text displayed within the UI to communicate the current status of a particular product's connection to an integration. */
  displayStatus?: Maybe<Scalars['String']>;
  /** The text displayed within the UI to communicate extra details of a product which is connected to an integration. */
  displaySubtitle?: Maybe<Scalars['String']>;
  /** The error status of a particular product's connection to an integration. */
  errorStatus?: Maybe<IntegrationProductErrorStatus>;
  /** Unique ID as retrieved from BankFeed schema. */
  id?: Maybe<Scalars['ID']>;
  /** Determines whether the integration is enabled for a particular Prospa product to sync to. */
  isSynced: Scalars['Boolean'];
  /** The id of a particular product which belongs to the user. */
  productId?: Maybe<Scalars['String']>;
  /** The type of a particular product which belongs to the user. */
  productType: ProspaProduct;
  /** The last date and time as to when the accounting integration synced up. */
  syncDateTime?: Maybe<Scalars['DateTimeISO']>;
};

/** The auth status of an integration. */
export enum IntegrationStatus {
  AuthError = 'AUTH_ERROR',
  Connected = 'CONNECTED',
  NotConnected = 'NOT_CONNECTED',
  SyncError = 'SYNC_ERROR',
}

/** Connections to external vendor accounting software. */
export type IntegrationsV2 = {
  __typename?: 'IntegrationsV2';
  /** The text displayed within the UI to communicate the integration's current status. */
  displayStatus?: Maybe<Scalars['String']>;
  /** Details relating to the connection of all products to the current accounting integration. */
  products: Array<IntegrationProductInfo>;
  /** The auth status of an integration. */
  status: IntegrationStatus;
  /** The company who provided the integration product. */
  type: IntegrationProduct;
};

/** A Partner Lead list connection */
export type LeadConnection = {
  __typename?: 'LeadConnection';
  edges: Array<LeadEdge>;
  pageInfo: PageInfo;
};

/** A node of lead list */
export type LeadEdge = {
  __typename?: 'LeadEdge';
  cursor?: Maybe<Scalars['String']>;
  node: PartnerLead;
};

/** The status of partner lead application */
export enum LeadStatus {
  /** The lead application requires additional information */
  AdditionalInfoRequired = 'ADDITIONAL_INFO_REQUIRED',
  /** The lead application has been approved */
  Approved = 'APPROVED',
  /** The lead application is awaiting credit consent */
  AwaitingCreditConsent = 'AWAITING_CREDIT_CONSENT',
  /** The lead application is not submitted by the partner */
  AwaitingPartner = 'AWAITING_PARTNER',
  /** The lead is now closed */
  Closed = 'CLOSED',
  /** The lead application has been declined */
  Declined = 'DECLINED',
  /** The lead application is not progressing due to difficulty contacting the customer */
  DifficultyContacting = 'DIFFICULTY_CONTACTING',
  /** The lead application can be ignored */
  Ignore = 'IGNORE',
  /** The lead is in progress */
  InProgress = 'IN_PROGRESS',
  /** The lead is masked */
  Masked = 'MASKED',
  /** The lead is not progressing */
  NotProgressing = 'NOT_PROGRESSING',
  /** The lead is pending settlement */
  PendingSettlement = 'PENDING_SETTLEMENT',
  /** The lead is qualified for application */
  QualifiedForApp = 'QUALIFIED_FOR_APP',
  /** The lead application is ready for renewal */
  Renewal = 'RENEWAL',
  /** The lead is now settled */
  Settled = 'SETTLED',
  /** The lead is waiting on some action from the customer */
  WaitingOnCustomer = 'WAITING_ON_CUSTOMER',
  /** The lead application has been withdrawn */
  Withdrawn = 'WITHDRAWN',
}

/** A Prospa Line of Credit account */
export type LineOfCredit = {
  __typename?: 'LineOfCredit';
  /** Amount remaining that can be drawn down from. */
  availableAmount: Money;
  /**
   * Headline amount figure to display.
   *
   * This is intended to be displayed on the dashboard and product pages and should not be used in any calculations.
   */
  balanceAmount?: Maybe<Money>;
  /** Label to display for the balanceAmount field. */
  balanceTitle: Scalars['String'];
  businessName?: Maybe<Scalars['String']>;
  /** Calculated status based on the status and other factors */
  contractStatus: LineOfCreditContractStatus;
  /** User deferral details */
  deferralDetails?: Maybe<LineOfCreditDeferralDetails>;
  /**
   * Status to display user.
   *
   * Returns empty string if status is Active
   */
  displayStatus: Scalars['String'];
  drawdown: DrawdownDetails;
  /** Prospa line of credit product id. */
  id: Scalars['ID'];
  /** This field represent the total amount in arrears */
  inArrearsAmount?: Maybe<Money>;
  /**
   * Whether the loan benefits from a government scheme.
   * @deprecated government scheme is no longer supported
   */
  isGovtScheme: Scalars['Boolean'];
  /** If the user is in arrears */
  isInArrears: Scalars['Boolean'];
  /** LOC Product Type Information (CloudLending or Mambu) */
  lmsType: LmsType;
  /** Last four digits of the bank account linked to the loan for drawdown */
  maskedLinkedAccountNumber: Scalars['String'];
  /**
   * Missed payment date
   * @deprecated Use isInArrears and inArrearsAmount instead
   */
  missedPaymentDate?: Maybe<Scalars['DateTimeISO']>;
  /**
   * Missed payment information (CloudLending only)
   * @deprecated Use isInArrears and inArrearsAmount instead
   */
  missedPayments: Array<MissedPayment>;
  /** Outstanding amount when customer in on payment plan. */
  outstandingAmount?: Maybe<Money>;
  paymentLimits?: Maybe<LineOfCreditPayAnyoneDetails>;
  /** Amount drawn that is still being processed.  */
  pendingAmount: Money;
  /** When product was created */
  startDate?: Maybe<Scalars['DateTimeISO']>;
  /** Loan status. While this field is still valid, it would be preferable to use the contractStatus instead when handling a condition on a client. */
  status: LineOfCreditStatus;
  /** Total amount available to drawn from (facility limit). */
  totalAmount: Money;
  /** Amount used from facility. */
  usedAmount: Money;
};

/** Calculated status based on the LineOfCreditStatus and other factors */
export enum LineOfCreditContractStatus {
  /** Proxy */
  Active = 'ACTIVE',
  /** Calculated. Contract signed by every party and waiting for Prospa to action (one step before settlement) */
  Awaiting = 'AWAITING',
  /** Proxy */
  Closed = 'CLOSED',
  /** Proxy. When user declines signing DocuSign contract */
  Declined = 'DECLINED',
  /** Calculated. Customers who are in deferral (=$0 repayments for X weeks) due to hardship. Giving a grace period without repayments. Collections will determine the weeks needed for a deferral on a case by case basis */
  Deferred = 'DEFERRED',
  /** Proxy. Contract is withdrawn, and after a long period, it will shift to EXPIRED. Following a contract link tells a customer the contract has expired */
  Expired = 'EXPIRED',
  /** Calculated. Doesn't work for Mambu. Applied for refinance and current loan gets locked until other application is finalised */
  Locked = 'LOCKED',
  /** Proxy */
  NotActive = 'NOT_ACTIVE',
  /** Proxy. Product closed because term ended, but their are paying off their outstanding balance via a payment plan */
  PaymentPlan = 'PAYMENT_PLAN',
  /** Calculated. When we wait for a single director to sign on their agreement */
  PendingFirstSignature = 'PENDING_FIRST_SIGNATURE',
  /** Calculated. When we wait for another director/ shareholder to sign on their agreement */
  PendingSecondSignature = 'PENDING_SECOND_SIGNATURE',
  /** Proxy. When customers missed more than 2 repayments. This remains until a collections agent changes the status in Salesforce */
  Suspended = 'SUSPENDED',
  /** Calculated. When a user has Agreement Term End Date is within 28 days */
  SuspendedNearMaturity = 'SUSPENDED_NEAR_MATURITY',
  /** Proxy */
  WrittenOff = 'WRITTEN_OFF',
}

/** Information about a user's LOC deferrals */
export type LineOfCreditDeferralDetails = {
  __typename?: 'LineOfCreditDeferralDetails';
  /** The date that a user's deferral period ends */
  deferralPeriodEndDate?: Maybe<Scalars['String']>;
};

/** Information about a user's payAnyone limits */
export type LineOfCreditPayAnyoneDetails = {
  __typename?: 'LineOfCreditPayAnyoneDetails';
  /**
   * The amount a user has transferred today (Mambu only).
   *
   * Note: For CloudLending this amount will always be 0.
   *
   */
  dailyPaidAmount?: Maybe<Money>;
  /**
   * Daily threshold where MFA is required to make a transfer.
   *
   * CloudLending - This amount will decrease by the amount transferred that day.
   * Mambu - This amount will stay the same.
   *
   */
  mfaThreshold?: Maybe<Money>;
  /** The amount remaining a user can transfer that day. */
  paymentAvailable?: Maybe<Money>;
};

/** The possible states a LineOfCredit account can be in */
export enum LineOfCreditStatus {
  Closed = 'CLOSED',
  ContractDeclined = 'CONTRACT_DECLINED',
  /** Doesn't work for Mambu */
  ContractExpired = 'CONTRACT_EXPIRED',
  ContractPending = 'CONTRACT_PENDING',
  LineActive = 'LINE_ACTIVE',
  LineSuspended = 'LINE_SUSPENDED',
  /** Doesn't work at the moment, comes as LINE_SUSPENDED */
  LineSuspendedNearMaturity = 'LINE_SUSPENDED_NEAR_MATURITY',
  /** Couldn't replicate */
  NotActive = 'NOT_ACTIVE',
  PaymentPlan = 'PAYMENT_PLAN',
  /** Doesn't work at the moment, comes as CONTRACT_PENDING. Requires settlement officer to approve, and turn it to SETTLED */
  SettlementVerbalValidationPending = 'SETTLEMENT_VERBAL_VALIDATION_PENDING',
  /** Customer signed the contract, it was seen that their email is generic (not related to the business, perhaps gmail). As a risk mitigation, this status will trigger, and it will be sent to settlement. Once a sales agent approves it (KYC over the phone), it goes to settlement, and the status changes */
  VerbalValidationPending = 'VERBAL_VALIDATION_PENDING',
  WrittenOff = 'WRITTEN_OFF',
}

/** Bill linked transaction */
export type LinkedTransaction = {
  __typename?: 'LinkedTransaction';
  /**  Account id which used for the transaction */
  accountId?: Maybe<Scalars['String']>;
  /** Transaction amount */
  amount?: Maybe<Money>;
  /** Payment Gateway */
  paymentGateway?: Maybe<PaymentGateway>;
  /** Payment status (transactionStatus) */
  paymentStatus?: Maybe<BillTransactionStatus>;
  /** Bill Payment type (aka Transaction Type) */
  paymentType?: Maybe<BillTransactionType>;
  /** Reference ID (transactionId) */
  referenceId: Scalars['String'];
  /** Scheduled date (aka scheduledDateUtc) */
  scheduledDate?: Maybe<Scalars['DateTimeISO']>;
  /** Transaction date */
  transactionDate?: Maybe<Scalars['DateTimeISO']>;
};

/** The possible product types for LineOfCredit account */
export enum LmsType {
  CloudLending = 'CloudLending',
  Mambu = 'Mambu',
}

/** A Prospa Line of Credit account */
export type MissedPayment = {
  __typename?: 'MissedPayment';
  /** Amount of payment missed */
  amount: Money;
  /** Name of missed payment */
  name: Scalars['String'];
  /** Missed payment order */
  paymentAllocationOrder: Scalars['NonNegativeInt'];
  /** Type of payment missed e.g. interest */
  type: Scalars['String'];
};

/** A monetary value with a currency */
export type Money = {
  __typename?: 'Money';
  amount: Scalars['Decimal'];
  currency: CurrencyCode;
};

export type MoneyInput = {
  amount: Scalars['Decimal'];
  currency: CurrencyCode;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateBAPhysicalCard: ActivateBaPhysicalCardOutput;
  /** Cancel a scheduled payment */
  cancelScheduledPayment: CancelScheduledPaymentOutput;
  /** this creates a business account for an existing user who already has capital products */
  createBusinessAccount: CreateBusinessAccountOutput;
  createDrawdown: CreateDrawdownOutput;
  /** `payees.write` OAuth 2.0 scope is required */
  createPayeeV2: CreatePayeeV2Output;
  /** @deprecated Please use DeletePayee instead */
  deleteBankPayee: DeleteBankPayeeOutput;
  deleteBill: BillPayDeleteOutput;
  deleteBillPaymentReference: BillPayDeleteOutput;
  /** @deprecated Please use DeletePayee instead */
  deleteBpayBiller: DeleteBankPayeeOutput;
  deletePayee: DeletePayeeOutput;
  disconnectIntegration: DisconnectIntegrationOutput;
  exchangeAuthCode: ExchangeAuthCodeOutput;
  /** Generates a link for the contract to be signed. */
  generateContractSignUrl: GenerateContractSignUrlOutput;
  initialiseBillPay: Scalars['String'];
  payeeTransfer: PaymentOutput;
  proofOfTransfer: ProofOfTransferOutput;
  /** Register for a tap to pay account. A user must have a business account to register. */
  registerTapToPay: RegisterTapToPayOutput;
  requestMobileWebSso: RequestMobileWebSsoOutput;
  requestNewCard: RequestNewCardOutput;
  scheduledPayeeTransfer: PaymentOutput;
  scheduledTransfer: PaymentOutput;
  sendTapToPayReceiptEmail: SendTapToPayEmailReceiptOutput;
  setCardContactless: SetCardContactlessOutput;
  setCardFrozen: SetCardFrozenOutput;
  stepUpInit: StepUpInitOutput;
  stepUpVerify: StepUpVerifyOutput;
  transfer: PaymentOutput;
  updateBill: BillDetails;
  updateBillPaymentReference: BillDetails;
  updateCardPin: UpdateCardPinOutput;
  updateDataFeeds: UpdateDataFeedsOutput;
  /** Update the customer surcharge flag. */
  updateTapToPaySurchargeFlag: TapToPay;
  verifyBACardNumber: VerifyBaCardNumberOutput;
};

export type MutationActivateBaPhysicalCardArgs = {
  input: ActivateBaPhysicalCardInput;
};

export type MutationCancelScheduledPaymentArgs = {
  input: CancelScheduledPaymentInput;
};

export type MutationCreateBusinessAccountArgs = {
  input: CreateBusinessAccountInput;
};

export type MutationCreateDrawdownArgs = {
  input: CreateLineOfCreditInput;
};

export type MutationCreatePayeeV2Args = {
  input: CreatePayeeV2Input;
};

export type MutationDeleteBankPayeeArgs = {
  input: DeleteBankPayeeInput;
};

export type MutationDeleteBillArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBillPaymentReferenceArgs = {
  input: DeleteBillPaymentReferenceInput;
};

export type MutationDeleteBpayBillerArgs = {
  input: DeleteBpayBillerInput;
};

export type MutationDeletePayeeArgs = {
  input: DeletePayeeInput;
};

export type MutationDisconnectIntegrationArgs = {
  input: DisconnectIntegrationInput;
};

export type MutationExchangeAuthCodeArgs = {
  authorizationCode: Scalars['String'];
  integrationProduct: IntegrationProduct;
};

export type MutationGenerateContractSignUrlArgs = {
  input: GenerateContractSignUrlInput;
};

export type MutationPayeeTransferArgs = {
  input: PayeeTransferInput;
};

export type MutationProofOfTransferArgs = {
  input: ProofOfTransferInput;
};

export type MutationRegisterTapToPayArgs = {
  input: RegisterTapToPayInput;
};

export type MutationRequestNewCardArgs = {
  input: RequestNewCardInput;
};

export type MutationScheduledPayeeTransferArgs = {
  input: ScheduledPayeeTransferInput;
};

export type MutationScheduledTransferArgs = {
  input: ScheduledTransferInput;
};

export type MutationSendTapToPayReceiptEmailArgs = {
  input: SendTapToPayReceiptEmailInput;
};

export type MutationSetCardContactlessArgs = {
  input: SetCardContactlessInput;
};

export type MutationSetCardFrozenArgs = {
  input: SetCardFrozenInput;
};

export type MutationStepUpVerifyArgs = {
  input: StepUpVerifyInput;
};

export type MutationTransferArgs = {
  input: TransferInput;
};

export type MutationUpdateBillArgs = {
  billId: Scalars['ID'];
  input: UpdateBillInput;
};

export type MutationUpdateBillPaymentReferenceArgs = {
  billId: Scalars['ID'];
  input: UpdateBillPaymentReferenceInput;
};

export type MutationUpdateCardPinArgs = {
  input: UpdateCardPinInput;
};

export type MutationUpdateDataFeedsArgs = {
  input: UpdateDataFeedsInput;
};

export type MutationUpdateTapToPaySurchargeFlagArgs = {
  input: UpdateTapToPaySurchargeFlagInput;
};

export type MutationVerifyBaCardNumberArgs = {
  input: VerifyBaCardNumberInput;
};

/** Get the OEM tokens to provision a card in a wallet. */
export type OemTokens = {
  __typename?: 'OemTokens';
  /** Card expiry */
  cardExpiry: Scalars['String'];
  /** Card Id */
  cardId: Scalars['String'];
  /** Card secret */
  cardSecret: Scalars['String'];
};

/** Business Account overdraft details */
export type OverdraftDetail = {
  __typename?: 'OverdraftDetail';
  /** Annual interest rate of the overdraft */
  annualInterestRate: Scalars['Decimal'];
  /** Annual rate of the overdraft */
  annualServiceRate: Scalars['Decimal'];
  /** Daily interest rate of the overdraft */
  dailyInterestRate?: Maybe<Scalars['Decimal']>;
  /** Limit of the overdraft */
  overdraftLimit: Scalars['Decimal'];
  /** Term end date of the overdraft */
  overdraftTermEndDateUtc?: Maybe<Scalars['DateTimeISO']>;
};

/** A connection’s page info */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Partner Commission Information */
export type PartnerCommission = {
  __typename?: 'PartnerCommission';
  /** Commission earned in the current financial year. Note: NZ will have a different FY to AU */
  commissionEarnedCurrentFY: Money;
  commissionOwing: Money;
  /** Partner Id same as accountUniqueId */
  id: Scalars['ID'];
};

/** Partner Information */
export type PartnerInfo = {
  __typename?: 'PartnerInfo';
  /** Partner Commission Information */
  commission?: Maybe<PartnerCommission>;
  /** Partner Contact Id, same as contactUniqueId */
  contactId: Scalars['String'];
  /** Country Code information for the partner */
  countryCode: Country;
  /** Partner Id same as accountUniqueId */
  id: Scalars['ID'];
  /** Provides information if partner can submit application on behalf of customer */
  isApplicationEnabled: Scalars['Boolean'];
  /** Provides information if partner can use Prospa IQ */
  isProspaIQEnabled: Scalars['Boolean'];
  /** get leads by date filter */
  leads?: Maybe<LeadConnection>;
};

/** Partner Information */
export type PartnerInfoLeadsArgs = {
  after?: InputMaybe<Scalars['NonNegativeInt']>;
  dateFrom?: InputMaybe<Scalars['LocalDate']>;
  dateTo?: InputMaybe<Scalars['LocalDate']>;
  first: Scalars['PositiveInt'];
};

/** Partner Lead Details */
export type PartnerLead = {
  __typename?: 'PartnerLead';
  /** Amount requested in Lead Application (either customerFreshRequestedAmount or amountRequested as applicable) */
  amountRequested: Money;
  /** Business Name or companyName in Lead application */
  businessName?: Maybe<Scalars['String']>;
  /** Client First Name on Lead Application */
  clientFirstName?: Maybe<Scalars['String']>;
  /** Client Last Name on Lead Application */
  clientLastName?: Maybe<Scalars['String']>;
  /** Lead Creation Date */
  createdDate?: Maybe<Scalars['DateTimeISO']>;
  /** Lead Display Status */
  displayStatus?: Maybe<Scalars['String']>;
  /** Lead ID same as lead encryptedId */
  id: Scalars['ID'];
  /** Product Name in Lead Application, i.e., productNameAcronym */
  productName?: Maybe<Scalars['String']>;
  /** Lead Status */
  status?: Maybe<LeadStatus>;
};

export type Payee = BaPayee | BankPayeeV2 | BpayPayeeV2;

/** Possible payee payment types */
export enum PayeePaymentType {
  BankTransfer = 'BANK_TRANSFER',
  Bpay = 'BPAY',
}

/** A bank/bpay transfer request using a saved payee. */
export type PayeeTransferInput = {
  amount: MoneyInput;
  /** Correlation Id / only used for loc cl pay */
  correlationId?: InputMaybe<Scalars['String']>;
  /** Transaction description */
  description: Scalars['String'];
  /** Payee ID. Can be bank or bPay payee. */
  payeeId: Scalars['String'];
  /** LOC, LOCM application id or Business Account id */
  productId: Scalars['String'];
  /** Transaction reference */
  reference?: InputMaybe<Scalars['String']>;
  /** Verification code */
  verificationCode?: InputMaybe<Scalars['String']>;
};

/** Error response from payment transfer */
export type PaymentErrorOutput = {
  __typename?: 'PaymentErrorOutput';
  displayMessage: Scalars['String'];
  errorCode: TransferError;
};

/** Scheduled payment frequency type */
export enum PaymentFrequencyType {
  /** Fortnightly payment */
  Fortnightly = 'Fortnightly',
  /** Monthly payment */
  Monthly = 'Monthly',
  /** One-off payment */
  Once = 'Once',
  /** Quarterly payment, only used for Shaype BA */
  Quarterly = 'Quarterly',
  /** Weekly payment */
  Weekly = 'Weekly',
}

/** Payment Gateway used in bill pay */
export enum PaymentGateway {
  /** Shaype API - BA */
  Shaype = 'Shaype',
  /** Transactions API - LOC */
  TransactionsApi = 'TransactionsApi',
  /** Indicates unknown payment gateway */
  Unknown = 'Unknown',
}

export type PaymentOutput = PaymentErrorOutput | PaymentSuccessOutput;

/** Successful response from payment transfer */
export type PaymentSuccessOutput = {
  __typename?: 'PaymentSuccessOutput';
  /** LOC, LOCM application id or Business Account id */
  productId: Scalars['String'];
  /** Transaction or scheduled id (is not available for Cloud Lending) */
  transactionId?: Maybe<Scalars['ID']>;
};

export enum PaymentType {
  /** When used for countryCode: NZ, `bank`, `branch` and `suffix` will be specified */
  BankTransfer = 'BANK_TRANSFER',
  /** Only avaliable for countryCode: AU. Should specify `bpayBillerCode`, `bpayBillerReferenece` */
  Bpay = 'BPAY',
  /** Only avaliable for user who has business account. */
  BusinessAccount = 'BUSINESS_ACCOUNT',
}

/** A post from Prospa’s Blog */
export type Post = {
  __typename?: 'Post';
  /** Blog post ID */
  id: Scalars['ID'];
  /** Default large blog post image for web */
  image: Scalars['String'];
  /** Medium-size blog post image */
  imageMedium: Scalars['String'];
  /** Original blog post image */
  imageOriginal: Scalars['String'];
  /** Public link to the blog post */
  link: Scalars['String'];
  /** Blog post publication date (local time) */
  publishDate: Scalars['DateTimeISO'];
  /** Blog post time to read (in minutes) */
  timeToRead: Scalars['Float'];
  /** Blog post title */
  title: Scalars['String'];
};

/** Reasons why a user cannot apply for a product if needed for display */
export enum ProductOriginationRejectReason {
  /** LOC or SBL is in arrears */
  Arrears = 'ARREARS',
  /** Business account is blocked or frozen */
  BlockedOrFrozenBa = 'BLOCKED_OR_FROZEN_BA',
  /** LOC payments are deferred */
  DeferralLoc = 'DEFERRAL_LOC',
  /** LOC is suspended */
  SuspendedLoc = 'SUSPENDED_LOC',
  /** SBL is suspended */
  SuspendedSbl = 'SUSPENDED_SBL',
}

export type ProductOriginationStatus = {
  __typename?: 'ProductOriginationStatus';
  canApply: Scalars['Boolean'];
  id: Scalars['ID'];
  reason?: Maybe<ProductOriginationRejectReason>;
};

/** Products that are able to be originated and their origination statuses */
export type ProductOriginationStatuses = {
  __typename?: 'ProductOriginationStatuses';
  businessAccount: ProductOriginationStatus;
  businessLoanPlus: ProductOriginationStatus;
  deferralPeriodEndDate?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  lineOfCredit: ProductOriginationStatus;
  smallBusinessLoan: ProductOriginationStatus;
};

export type ProofOfTransferInput = {
  /** The ID of a LOCM customer, as this is for LOCM only. */
  applicationId: Scalars['String'];
  /** The transaction key associated with a transfer payment. */
  transactionKey: Scalars['String'];
};

export type ProofOfTransferOutput = {
  __typename?: 'ProofOfTransferOutput';
  /** The URL to download the proof of transfer. */
  url: Scalars['String'];
};

/** The type of a particular product which belongs to the user */
export enum ProspaProduct {
  BusinessAccount = 'BusinessAccount',
  LineOfCreditMambu = 'LineOfCreditMambu',
}

export enum QualificationDecisions {
  /** Qualified */
  Qualified = 'Qualified',
  /** Decision unknown or is not available */
  Unknown = 'Unknown',
  /** Unqualified */
  Unqualified = 'Unqualified',
}

export type Query = {
  __typename?: 'Query';
  addressSearch: Array<Address>;
  blog: Blog;
  bsb?: Maybe<Bsb>;
  user: User;
  /** hit transactions api to verify account number for NZ account */
  verifyAccountNumber: VerifyAccountNumberOutput;
};

export type QueryAddressSearchArgs = {
  countryCode?: Country;
  searchString: Scalars['String'];
};

export type QueryBlogArgs = {
  countryCode?: InputMaybe<Country>;
};

export type QueryBsbArgs = {
  bsbNumber: Scalars['String'];
};

export type QueryVerifyAccountNumberArgs = {
  accountDetails: VerifyAccountDetails;
  accountType: AccountType;
};

/** Input type for TTP registration */
export type RegisterTapToPayInput = {
  /** Business address of the user */
  businessAddress?: InputMaybe<Scalars['String']>;
  /** Business email of the user */
  businessEmail?: InputMaybe<Scalars['String']>;
  /** Business phone number of the user */
  businessPhoneNumber?: InputMaybe<Scalars['String']>;
  /** User has consented to terms & conditions and privacy policy */
  consent: Scalars['Boolean'];
};

/** Register response for tap to pay */
export type RegisterTapToPayOutput = {
  __typename?: 'RegisterTapToPayOutput';
  /** Registration request was successful */
  success: Scalars['Boolean'];
};

export type RepaymentCalculator = {
  __typename?: 'RepaymentCalculator';
  /** Repayment calculator ID (this is the same as the user id) */
  id: Scalars['ID'];
  /** Interest rate to use for existing customers using Line Of Credit calculator */
  lineOfCreditRate: Scalars['Decimal'];
  /** Interest rate to use for existing customers using Small Business Loan calculator */
  smallBusinessLoanRate: Scalars['Decimal'];
};

/** Response from session token fetch for mobile web sso */
export type RequestMobileWebSsoOutput = {
  __typename?: 'RequestMobileWebSsoOutput';
  sessionToken: Scalars['String'];
};

export type RequestNewCardInput = {
  address: AddressInputType;
  oldCardId: Scalars['String'];
  /** The step up token obtained from stepUpVerify endpoint */
  stepUpToken: Scalars['String'];
};

export type RequestNewCardOutput = {
  __typename?: 'RequestNewCardOutput';
  cardMeta: CardMeta;
};

/** Data from customer insights used to prepopulate BA origination form */
export type SalesforceContactData = {
  __typename?: 'SalesforceContactData';
  /** Australian Business Number */
  ABN: Scalars['String'];
  /** Legal entity type of the business e.g. Sole trader */
  businessStructure: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  dateOfBirth: Scalars['String'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  homeCity?: Maybe<Scalars['String']>;
  homeCountry?: Maybe<Scalars['String']>;
  homePostalCode?: Maybe<Scalars['String']>;
  homeState?: Maybe<Scalars['String']>;
  homeStreet?: Maybe<Scalars['String']>;
  /** Industry classification of the business */
  industry: Scalars['String'];
  /** Industry category of the business */
  industryCategory: Scalars['String'];
  /** Is a director or owner of the business */
  isOwner: Scalars['Boolean'];
  /** Is a single director of the business */
  isSingleDirector?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  /** Name of the business */
  tradingName: Scalars['String'];
};

/** A scheduled bank/bpay transfer request using a saved payee. */
export type ScheduledPayeeTransferInput = {
  amount: MoneyInput;
  /** Correlation Id / only used for loc cl pay */
  correlationId?: InputMaybe<Scalars['String']>;
  /** Transaction description */
  description: Scalars['String'];
  /** Scheduled payment end date */
  endDate?: InputMaybe<Scalars['String']>;
  /** Used by BA only */
  numberOfPayments?: InputMaybe<Scalars['PositiveInt']>;
  /** Payee ID. Can be bank or bPay payee. */
  payeeId: Scalars['String'];
  /** Scheduled payment frequency type */
  paymentFrequencyType: PaymentFrequencyType;
  /** LOC, LOCM application id or Business Account id */
  productId: Scalars['String'];
  /** Transaction reference */
  reference?: InputMaybe<Scalars['String']>;
  /** Scheduled payment start date */
  startDate: Scalars['String'];
  /** Verification code */
  verificationCode?: InputMaybe<Scalars['String']>;
};

/** A scheduled bank/bpay transfer request without using a saved payee. Step-up auth is always required. */
export type ScheduledTransferInput = {
  amount: MoneyInput;
  /** AU bank transfer payee details. */
  auPayee?: InputMaybe<BankTransferAuPayeeInput>;
  /** BPAY payee details. */
  bPayPayee?: InputMaybe<BpayTransferPayeeInput>;
  /** Correlation Id / only used for loc cl pay */
  correlationId?: InputMaybe<Scalars['String']>;
  /** Transaction description */
  description: Scalars['String'];
  /** Scheduled payment end date */
  endDate?: InputMaybe<Scalars['String']>;
  /** Used by BA only */
  numberOfPayments?: InputMaybe<Scalars['PositiveInt']>;
  /** NZ bank transfer payee details. */
  nzPayee?: InputMaybe<BankTransferNzPayeeInput>;
  /** Scheduled payment frequency type */
  paymentFrequencyType: PaymentFrequencyType;
  /** LOC, LOCM application id or Business Account id */
  productId: Scalars['String'];
  /** Transaction reference */
  reference?: InputMaybe<Scalars['String']>;
  /** Scheduled payment start date */
  startDate: Scalars['String'];
  /** Verification code */
  verificationCode: Scalars['String'];
};

/** Send email receipt response for tap to pay */
export type SendTapToPayEmailReceiptOutput = {
  __typename?: 'SendTapToPayEmailReceiptOutput';
  /** Send email receipt request was successful */
  success: Scalars['Boolean'];
};

/** Input type for sending tap to pay receipt email */
export type SendTapToPayReceiptEmailInput = {
  /** Transaction amount */
  amount?: InputMaybe<MoneyInput>;
  /** Last 4 digits of the card number */
  cardNumber: Scalars['String'];
  /** Card type */
  cardType: Scalars['String'];
  /** Email address of customer receiving receipt */
  customerEmail: Scalars['String'];
  /** Amount refunded back to the customer */
  refundedAmount?: InputMaybe<MoneyInput>;
  /** Transaction surcharge amount */
  surchargeAmount?: InputMaybe<MoneyInput>;
  /** Total transaction amount */
  totalAmount: MoneyInput;
  /** Transaction date and time */
  transactionDateTime: Scalars['String'];
  /** Transaction ID */
  transactionId: Scalars['String'];
  /** Transaction Status */
  transactionStatus: TransactionStatus;
};

/** Sessions Parameters */
export type SessionsParameters = {
  /** digitalLeadSource */
  digitalLeadSource: Scalars['String'];
  /** utmSource */
  utmSource: Scalars['String'];
};

export type SetCardContactlessInput = {
  id: Scalars['String'];
  value: Scalars['Boolean'];
};

export type SetCardContactlessOutput = {
  __typename?: 'SetCardContactlessOutput';
  cardMeta: CardMeta;
};

/** Request to update a card's frozen property */
export type SetCardFrozenInput = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};

/** The response of updating a card's frozen property */
export type SetCardFrozenOutput = {
  __typename?: 'SetCardFrozenOutput';
  cardMeta: CardMeta;
};

/** SBL */
export type SmallBusinessLoan = {
  __typename?: 'SmallBusinessLoan';
  /**
   * Headline amount figure to display.
   *
   * This is intended to be displayed on the dashboard and product pages and should not be used in any calculations.
   */
  balanceAmount: Money;
  /** Label to display for the balanceAmount field. */
  balanceTitle: Scalars['String'];
  /** Collections payment link (Upwire nanosite) */
  collectionsPaymentUrl?: Maybe<Scalars['String']>;
  /** @deprecated SBL Mambu contract implementation not supported */
  contract?: Maybe<SmallBusinessLoanContract>;
  /** Status generated by combining contract and loan status for SBL Cloud Lending conditional rendering on frontend */
  contractStatus?: Maybe<SmallBusinessLoanContractStatus>;
  /** Country Code information */
  countryCode: Country;
  /**
   * The number of days a customer is late with their payments. Note: CloudLending only
   * @deprecated Use isInArrears and inArrearsAmount instead
   */
  daysPastDue?: Maybe<Scalars['NonNegativeInt']>;
  /**
   * Status to show user on dashboard tile
   *
   * Returns empty string if status is Active
   */
  displayStatus: Scalars['String'];
  /** Display Loan Display Title */
  displayTitle: Scalars['String'];
  /**
   * When a customer has a missed repayment
   * @deprecated please use isInArrears instead
   */
  hasMissedPayment: Scalars['Boolean'];
  /** Propsa Small Business Loan product id */
  id: Scalars['ID'];
  /** This field represent the total amount in arrears */
  inArrearsAmount?: Maybe<Money>;
  /**
   * Whether the loan benefits from a government scheme (used for analytics purposes)
   * @deprecated government scheme is no longer supported
   */
  isGovtScheme: Scalars['Boolean'];
  /** If the user is in arrears */
  isInArrears: Scalars['Boolean'];
  /** Is user eligible for refinance */
  isRefinanceEligible: Scalars['Boolean'];
  /** The total loan amount */
  loanAmount: Money;
  /** The total outstanding loan amount */
  outstandingAmount: Money;
  /** When product was created */
  startDate?: Maybe<Scalars['Date']>;
  /** Small Business Loan Type. */
  type: SmallBusinessLoanType;
};

/** SBL Contract */
export type SmallBusinessLoanContract = {
  __typename?: 'SmallBusinessLoanContract';
  cancelled: Scalars['Boolean'];
  expired: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeclined: Scalars['Boolean'];
  isSignedByAll: Scalars['Boolean'];
  userSigned: Scalars['Boolean'];
};

/** Status generated by combining contract and loan status for SBL Cloud Lending conditional rendering on frontend */
export enum SmallBusinessLoanContractStatus {
  Active = 'ACTIVE',
  Awaiting = 'AWAITING',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  PendingSecondSignature = 'PENDING_SECOND_SIGNATURE',
  WrittenOff = 'WRITTEN_OFF',
}

/** The type of the Small business loan account */
export enum SmallBusinessLoanType {
  /** Sbl Loan Type is CloudLending */
  CloudLending = 'CLOUD_LENDING',
  /** Sbl Loan Type is Mambu */
  Mambu = 'MAMBU',
}

/** A period of time with a statement */
export type Statement = {
  __typename?: 'Statement';
  /** End date of the statement */
  endDate: Scalars['Date'];
  id: Scalars['String'];
  /** application ID for loans / "{bsb} {accountNo}" for BA */
  productId: Scalars['String'];
  /** Start date of the statement */
  startDate: Scalars['Date'];
  title: Scalars['String'];
};

export type StatementCustomDateRange = {
  __typename?: 'StatementCustomDateRange';
  /** Latest valid local date within the range */
  max: Scalars['LocalDate'];
  /** Earliest valid local date within the range */
  min: Scalars['LocalDate'];
};

/** Statement download methods */
export enum StatementDownloadMethod {
  /** Statement must be downloaded via a GET request (used by BA and Sbl/Loc Mambu products) */
  Default = 'DEFAULT',
  /** Statement must be downloaded via a V2 GET endpoint (used by Sbl/Loc Cloud Lending products */
  V2 = 'V2',
}

/** File type of the statement */
export enum StatementFileType {
  Csv = 'CSV',
  Pdf = 'PDF',
}

/** Source of the statement */
export enum StatementProductType {
  BusinessAccount = 'BUSINESS_ACCOUNT',
  LineOfCredit = 'LINE_OF_CREDIT',
  SmallBusinessLoan = 'SMALL_BUSINESS_LOAN',
  SmallBusinessLoanMambu = 'SMALL_BUSINESS_LOAN_MAMBU',
}

/** Type of statements */
export enum StatementType {
  /** Please use this instead of the customGenerated argument */
  Custom = 'Custom',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

/** A URL to a financial statement */
export type StatementUrl = {
  __typename?: 'StatementUrl';
  /** The method to use to download a statement */
  downloadMethod: StatementDownloadMethod;
  /** The filename and extension of the statement */
  filename: Scalars['String'];
  /** The URL of the financial statement */
  url: Scalars['String'];
};

/** A list of monthly and yearly transaction statements */
export type Statements = {
  __typename?: 'Statements';
  /** The amount of the product */
  amount: Money;
  /** Label to describe the amount */
  amountLabel: Scalars['String'];
  /** Valid date range for custom generated statement */
  customDateRange?: Maybe<StatementCustomDateRange>;
  /** Display status of product */
  displayStatus: Scalars['String'];
  /** Secondary information pertaining to the product, such as account number or application id */
  displaySubtitle: Scalars['String'];
  /** The type of product to display */
  displayTitle: Scalars['String'];
  /** ID that is a combination of productType and productId */
  id: Scalars['ID'];
  /** Is custom generated statement supported */
  isCustomGeneratedSupported: Scalars['Boolean'];
  monthly: Array<Statement>;
  /** ID of the product */
  productId: Scalars['ID'];
  /** Whether the statements list is from BA, LOC, or SBL */
  productType: StatementProductType;
  yearly: Array<Statement>;
};

/** Response from Step Up init call */
export type StepUpInitOutput = {
  __typename?: 'StepUpInitOutput';
  codeDeliveryType: Scalars['String'];
  expiresIn: Scalars['Float'];
};

/** Error status for step up verify */
export enum StepUpVerifyError {
  /** Invalid 2fa code */
  InvalidCode = 'INVALID_CODE',
}

/** Error response from step up verify call */
export type StepUpVerifyErrorOutput = {
  __typename?: 'StepUpVerifyErrorOutput';
  error: StepUpVerifyError;
};

export type StepUpVerifyInput = {
  code: Scalars['String'];
};

export type StepUpVerifyOutput = StepUpVerifyErrorOutput | StepUpVerifySuccessOutput;

/** Success response from step up verify call */
export type StepUpVerifySuccessOutput = {
  __typename?: 'StepUpVerifySuccessOutput';
  token: Scalars['String'];
  validUntil: Scalars['DateTimeISO'];
};

export type TapToPay = {
  __typename?: 'TapToPay';
  id: Scalars['ID'];
  isCustomerPayingSurcharge?: Maybe<Scalars['Boolean']>;
  status: TapToPayStatus;
  surchargePercentage?: Maybe<Scalars['Decimal']>;
  terminal?: Maybe<TapToPayTerminal>;
};

/** Common statuses for TTP application and account */
export enum TapToPayStatus {
  /** TTP account is disabled */
  Disabled = 'DISABLED',
  /** TTP account is enabled */
  Enabled = 'ENABLED',
  /** TTP application is rejected */
  Rejected = 'REJECTED',
  /** TTP application is submitted */
  Submitted = 'SUBMITTED',
}

export type TapToPayTerminal = {
  __typename?: 'TapToPayTerminal';
  authKey: Scalars['String'];
  id: Scalars['ID'];
};

export type TransactionDetails = BPayDetails | BankDetails;

/** The transaction status supported for tap to pay transaction email receipt */
export enum TransactionStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Refunded = 'REFUNDED',
}

/** Error status for the payee transfer */
export enum TransferError {
  BadInput = 'BAD_INPUT',
  CorrelationIdRequired = 'CORRELATION_ID_REQUIRED',
  /** Bank transfer declined due to fraudulent reasons */
  Fraud = 'FRAUD',
  /** Bank transfer failed due to insufficient funds */
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  /** Internal error processing bank transfer */
  InternalError = 'INTERNAL_ERROR',
  /** An invalid BSB was provided */
  InvalidBsb = 'INVALID_BSB',
  InvalidCurrency = 'INVALID_CURRENCY',
  /** The payee was not found for user */
  InvalidPayee = 'INVALID_PAYEE',
  /** Invalid biller code, reference, or amount. May also be returned if flagged as fraudulent by our fraud monitoring engine */
  InvalidPayment = 'INVALID_PAYMENT',
  /** Verification (step up auth) was required and invalid */
  InvalidVerificationCode = 'INVALID_VERIFICATION_CODE',
  /** Customer has reached their limit for transfers */
  LimitError = 'LIMIT_ERROR',
  /** Invalid Bpay biller code */
  RefusedBpayInvalidBillerCode = 'REFUSED_BPAY_INVALID_BILLER_CODE',
  /** Invalid Bpay Payment */
  RefusedBpayInvalidPayment = 'REFUSED_BPAY_INVALID_PAYMENT',
  /** Invalid Bpay Reference */
  RefusedBpayInvalidReference = 'REFUSED_BPAY_INVALID_REFERENCE',
  /** Error in Bpay payment: Rejected */
  RefusedBpayRejected = 'REFUSED_BPAY_REJECTED',
  /** Not applicable for bank transfers at this time */
  RefusedCustomerPreferences = 'REFUSED_CUSTOMER_PREFERENCES',
  /** Customer has exceeded their daily limit for BPAY payments */
  RefusedDailyBpayLimitBreached = 'REFUSED_DAILY_BPAY_LIMIT_BREACHED',
  /** Customer has exceeded their daily limit for transfers out */
  RefusedDailyTransfersOutLimitBreached = 'REFUSED_DAILY_TRANSFERS_OUT_LIMIT_BREACHED',
}

/** A bank/bpay transfer request without saved payee. Step-up auth is always required. */
export type TransferInput = {
  amount: MoneyInput;
  /** AU bank transfer payee details. */
  auPayee?: InputMaybe<BankTransferAuPayeeInput>;
  /** bPay biller payee details. */
  bPayPayee?: InputMaybe<BpayTransferPayeeInput>;
  /** Correlation Id / only used for loc cl pay */
  correlationId?: InputMaybe<Scalars['String']>;
  /** Transaction description */
  description: Scalars['String'];
  /** NZ bank transfer payee details. */
  nzPayee?: InputMaybe<BankTransferNzPayeeInput>;
  /** LOC, LOCM application id or Business Account id */
  productId: Scalars['String'];
  /** Transaction reference */
  reference?: InputMaybe<Scalars['String']>;
  /** Verification code */
  verificationCode: Scalars['String'];
};

/** UpcomingRepayment */
export type UpcomingRepayment = {
  __typename?: 'UpcomingRepayment';
  /** valid product id */
  id: Scalars['ID'];
  /** relevant product next repayment date */
  nextRepaymentDate: Scalars['DateTimeISO'];
  /** The total charge amount */
  totalRepaymentCharge: Money;
};

/** Source of the upcoming repayment calculation */
export enum UpcomingRepaymentProductType {
  LineOfCredit = 'LINE_OF_CREDIT',
}

/** The input argument for update bills mutation */
export type UpdateBillInput = {
  /** Indicates the amount */
  amount: Scalars['Float'];
  /** Indicates the issuerABN */
  billerABN: Scalars['String'];
  /** Indicates the issuerName */
  billerName: Scalars['String'];
  /** Indicates the invoiceNumber */
  description: Scalars['String'];
  dueDate: Scalars['String'];
  paymentMethod: BillPaymentMethodInput;
};

/** The input argument for update bills mutation */
export type UpdateBillPaymentReferenceInput = {
  /**  Account id which used for the transaction) */
  accountId: Scalars['String'];
  /** The payment amount */
  amount: Scalars['Float'];
  paymentGateway: PaymentGateway;
  paymentType: BillPaymentType;
  /** The payment id */
  referenceId: Scalars['String'];
  scheduledDate: Scalars['String'];
};

export type UpdateCardPinInput = {
  cardId: Scalars['String'];
  cardPin: Scalars['String'];
  /** The step up token obtained from stepUpVerify endpoint */
  stepUpToken: Scalars['String'];
};

export type UpdateCardPinOutput = {
  __typename?: 'UpdateCardPinOutput';
  success: Scalars['Boolean'];
};

export type UpdateDataFeedsInput = {
  /** The integration system e.g. Xero */
  integrationProduct: IntegrationProduct;
  /** The update data feeds inputs. */
  updateDataFeedsInputItems: Array<UpdateDataFeedsInputItem>;
};

export type UpdateDataFeedsInputItem = {
  /** enabled data feeds or not. */
  enabled: Scalars['Boolean'];
  /** The ID of a product. */
  id: Scalars['String'];
  /** The productType. */
  productType: InputProductType;
};

export type UpdateDataFeedsOutput = {
  __typename?: 'UpdateDataFeedsOutput';
  /** The URL to download the proof of transfer. */
  success: Scalars['Boolean'];
};

/** The input type for updating customer surcharge flag */
export type UpdateTapToPaySurchargeFlagInput = {
  /** Indicates if the customer is paying surcharge */
  isCustomerPayingSurcharge: Scalars['Boolean'];
};

/** A Prospa user */
export type User = {
  __typename?: 'User';
  /** Qualify Check result data for Business Account Origination request */
  baOriginationQualifyCheck?: Maybe<BusinessAccountOriginationQualifyCheck>;
  /** BillPay */
  billPay?: Maybe<BillPay>;
  /**
   * The users business account.
   *
   * AccessDeniedError for any invalid tokens or trying to access an account they do not have access to.
   *
   * Returns null when they have no business account
   */
  businessAccount?: Maybe<BusinessAccount>;
  /**
   * The Customer Data Platform ID.
   *
   * This unique identifier that would be used for identifying a customer for marketing purposes (with Segment and Braze)
   */
  cdpId?: Maybe<Scalars['String']>;
  /** Get contract details by contract id */
  contract?: Maybe<Contract>;
  /** List of contracts to sign (in pending or awaiting status) */
  contracts?: Maybe<Array<Contract>>;
  /** Returns product country code for a user, values can be AU or NZ */
  country: Country;
  /** Returns the ids of pending applications the user needs to sign or review. Used to display banners */
  digitalApplication: DigitalApplication;
  /** Email address (requires "email" scope) */
  email: Scalars['String'];
  familyName: Scalars['String'];
  /**
   * This comes straight from Identity, so there is no guarantee on our side
   * that it is the same as givenName + familyName
   */
  fullName: Scalars['String'];
  givenName: Scalars['String'];
  /** Prospa ID (sub value from identity) */
  id: Scalars['ID'];
  /**
   * Connections to external vendor accounting software
   * @deprecated Found to be unscalable with multiple providers and products. Use integrationsV2 instead.
   */
  integrations: Array<Integration>;
  /** Connections to external vendor accounting software */
  integrationsV2: IntegrationsV2;
  /** Indicates if the user is eligible to sign up for TapToPay feature */
  isTapToPayEligible: Scalars['Boolean'];
  /**
   * The list of user's Lines of Credit (LOC)
   *
   * Returns an empty array if user does not have LOCs or AccessDeniedError if invalid token provided.
   */
  linesOfCredit: Array<LineOfCredit>;
  /** OEM tokens to add card to digital wallet */
  oemTokens?: Maybe<OemTokens>;
  /** Returns partner related information if the user is a Partner of Prospa, else returns null */
  partner?: Maybe<PartnerInfo>;
  /**
   * The users payees.
   *
   * Return all the payees the user has.
   *
   * Consumer can pass in CountryCode and PaymentTypes to specific what payees they want.
   */
  payees?: Maybe<Array<Payee>>;
  /** Formatted as an international phone number whenever possible (requires "phone_number" scope) */
  phoneNumber: Scalars['String'];
  /** Tracks statuses of products that the user is able to apply for and if not able to, the reason for that */
  productOriginationStatuses: ProductOriginationStatuses;
  /** Repayment calculator interest rates to use. */
  repaymentCalculator: RepaymentCalculator;
  /** Data from customer insights used to prepopulate BA origination form */
  salesforceContactData?: Maybe<SalesforceContactData>;
  /**
   * The list of user's Small Business Loans (SBL)
   *
   * Returns an empty array if user does not have SBLs or AccessDeniedError if invalid token provided.
   */
  smallBusinessLoans: Array<SmallBusinessLoan>;
  statement: StatementUrl;
  /**
   * Statements for the user for all products
   *
   * Returns an empty array if user does not have any products with statements
   */
  statements: Array<Statements>;
  /** Get TapToPay account & application related details */
  tapToPay?: Maybe<TapToPay>;
  /**
   * UpcomingRepayment calculation for loc/locm
   *
   * Returns total repayment charge and next repayment date
   */
  upcomingRepayment: UpcomingRepayment;
};

/** A Prospa user */
export type UserBaOriginationQualifyCheckArgs = {
  correlationId: Scalars['ID'];
};

/** A Prospa user */
export type UserContractArgs = {
  contractId: Scalars['String'];
};

/** A Prospa user */
export type UserIntegrationsV2Args = {
  type: IntegrationProduct;
};

/** A Prospa user */
export type UserOemTokensArgs = {
  cardId: Scalars['ID'];
  stepUpToken: Scalars['String'];
};

/** A Prospa user */
export type UserPayeesArgs = {
  countryCode?: InputMaybe<Country>;
  paymentTypes?: InputMaybe<Array<PaymentType>>;
};

/** A Prospa user */
export type UserSmallBusinessLoansArgs = {
  contractStatusFilter?: InputMaybe<Array<SmallBusinessLoanContractStatus>>;
};

/** A Prospa user */
export type UserStatementArgs = {
  customGenerated?: InputMaybe<Scalars['Boolean']>;
  endDate: Scalars['Date'];
  fileType: StatementFileType;
  id?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Date'];
  statementType?: InputMaybe<StatementType>;
  type: StatementProductType;
};

/** A Prospa user */
export type UserStatementsArgs = {
  productId?: InputMaybe<Scalars['String']>;
  productType?: InputMaybe<StatementProductType>;
};

/** A Prospa user */
export type UserUpcomingRepaymentArgs = {
  additionalAmount: Scalars['Decimal'];
  id: Scalars['ID'];
  productType: UpcomingRepaymentProductType;
};

export type VerifyAccountDetails = {
  /** Customer/Account ID. Shared for AU and NZ (aka bankAccountNumber / account) */
  accountNumber: Scalars['String'];
  /** Identifies the bank. NZ only */
  bank?: InputMaybe<Scalars['String']>;
  /** Branch of the bank. NZ only */
  branch?: InputMaybe<Scalars['String']>;
  /** BSB number in XXX-XXX format. AU only (aka bankBsbNumber / bsbNumber) */
  bsb?: InputMaybe<Scalars['String']>;
  /** This usually changes for the account type. i.e the same person may have a checking and savings account, and only the last 3 digits would be different. NZ only */
  suffix?: InputMaybe<Scalars['String']>;
};

export type VerifyAccountNumberOutput = {
  __typename?: 'VerifyAccountNumberOutput';
  isValid: Scalars['Boolean'];
};

export type VerifyBaCardNumberInput = {
  /** The business account id */
  accountId: Scalars['String'];
  /** The card id of the card the last four digits relate to */
  cardId: Scalars['String'];
  /** The last 4 digits of the card number to verify */
  lastFourDigits: Scalars['String'];
  /** The step up token obtained from stepUpVerify endpoint */
  stepUpToken: Scalars['String'];
};

export type VerifyBaCardNumberOutput = {
  __typename?: 'VerifyBACardNumberOutput';
  success: Scalars['Boolean'];
};

/** Shaype digital wallet type */
export enum WalletType {
  /** Apple Pay wallet */
  AppleWallet = 'APPLE_WALLET',
  /** Google Pay wallet */
  GooglePay = 'GOOGLE_PAY',
  /** SamsungPay Pay wallet */
  SamsungPay = 'SAMSUNG_PAY',
}

export type BillFragmentFragment = {
  __typename?: 'BillDetails';
  id: string;
  status: BillStatus;
  billerABN?: string | null;
  billerName?: string | null;
  dueDate?: any | null;
  uploadDate?: any | null;
  fileUrl: string;
  fileName?: string | null;
  description?: string | null;
  billSource?: {
    __typename?: 'BillSource';
    inputMethod: BillInputMethod;
    receiverAddress?: string | null;
    senderEmailAddress?: string | null;
  } | null;
  amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
  paymentMethods?: {
    __typename?: 'BillPaymentMethods';
    paymentTypes?: Array<BillPaymentType> | null;
    bpayPayment?: {
      __typename?: 'BillBPayPayment';
      billerCode?: string | null;
      reference?: string | null;
    } | null;
    bankTransferPayment?: {
      __typename?: 'BillBankTransferPayment';
      bsb?: string | null;
      accountName?: string | null;
      accountNumber?: string | null;
      reference?: string | null;
    } | null;
  } | null;
  linkedTransactions?: Array<{
    __typename?: 'LinkedTransaction';
    accountId?: string | null;
    paymentType?: BillTransactionType | null;
    referenceId: string;
    paymentStatus?: BillTransactionStatus | null;
    scheduledDate?: any | null;
    paymentGateway?: PaymentGateway | null;
    transactionDate?: any | null;
    amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
  }> | null;
};

export type BillTransactionFragmentFragment = {
  __typename?: 'LinkedTransaction';
  accountId?: string | null;
  paymentType?: BillTransactionType | null;
  referenceId: string;
  paymentStatus?: BillTransactionStatus | null;
  scheduledDate?: any | null;
  paymentGateway?: PaymentGateway | null;
  transactionDate?: any | null;
  amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
};

export type BpayPayeeV2FragmentFragment = {
  __typename?: 'BpayPayeeV2';
  id: string;
  accountName?: string | null;
  countryCode: Country;
  paymentType: PaymentType;
  billerName?: string | null;
  bPayBillerCode: string;
  bPayReference: string;
};

export type BankPayeeV2FragmentFragment = {
  __typename?: 'BankPayeeV2';
  id: string;
  account: string;
  accountName?: string | null;
  bank?: string | null;
  branch?: string | null;
  bsb?: string | null;
  suffix?: string | null;
  countryCode: Country;
  paymentType: PaymentType;
  financialInstitution?: string | null;
};

export type BaPayeeFragmentFragment = {
  __typename?: 'BAPayee';
  id: string;
  account: string;
  accountName?: string | null;
  bsb?: string | null;
  countryCode: Country;
  paymentType: PaymentType;
};

export type SchedulePayeeBankDetailsFragment = {
  __typename?: 'BankDetails';
  accountNumber?: string | null;
  bsb?: string | null;
  name: string;
};

export type SchedulePayeeBPayDetailsFragment = {
  __typename?: 'BPayDetails';
  billerCode: string;
  reference: string;
  nickname: string;
};

export type ScheduledPaymentActivityFragment = {
  __typename?: 'BusinessAccountScheduledTransaction';
  cancelOnFailure?: boolean | null;
  description: string;
  endDate?: any | null;
  frequency?: BusinessAccountScheduledPaymentFrequency | null;
  id: string;
  numberOfPayments?: any | null;
  reference?: string | null;
  startDate: any;
  status?: BusinessAccountScheduledPaymentStatus | null;
  type: BusinessAccountSchedulePaymentType;
  amount: { __typename?: 'Money'; amount: any };
  details:
    | { __typename?: 'BPayDetails'; billerCode: string; reference: string; nickname: string }
    | {
        __typename?: 'BankDetails';
        accountNumber?: string | null;
        bsb?: string | null;
        name: string;
      };
};

export type ActivateBaPhysicalCardMutationVariables = Exact<{
  input: ActivateBaPhysicalCardInput;
}>;

export type ActivateBaPhysicalCardMutation = {
  __typename?: 'Mutation';
  activateBAPhysicalCard: { __typename?: 'ActivateBAPhysicalCardOutput'; success: boolean };
};

export type CancelScheduledBillPaymentMutationVariables = Exact<{
  input: DeleteBillPaymentReferenceInput;
}>;

export type CancelScheduledBillPaymentMutation = {
  __typename?: 'Mutation';
  deleteBillPaymentReference: { __typename: 'BillPayDeleteOutput'; success: boolean };
};

export type CancelScheduledPaymentMutationVariables = Exact<{
  input: CancelScheduledPaymentInput;
}>;

export type CancelScheduledPaymentMutation = {
  __typename?: 'Mutation';
  cancelScheduledPayment: { __typename?: 'CancelScheduledPaymentOutput'; success: boolean };
};

export type ConfirmBillDetailMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateBillInput;
}>;

export type ConfirmBillDetailMutation = {
  __typename?: 'Mutation';
  updateBill: {
    __typename?: 'BillDetails';
    id: string;
    status: BillStatus;
    billerABN?: string | null;
    billerName?: string | null;
    dueDate?: any | null;
    uploadDate?: any | null;
    fileUrl: string;
    fileName?: string | null;
    description?: string | null;
    billSource?: {
      __typename?: 'BillSource';
      inputMethod: BillInputMethod;
      receiverAddress?: string | null;
      senderEmailAddress?: string | null;
    } | null;
    amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
    paymentMethods?: {
      __typename?: 'BillPaymentMethods';
      paymentTypes?: Array<BillPaymentType> | null;
      bpayPayment?: {
        __typename?: 'BillBPayPayment';
        billerCode?: string | null;
        reference?: string | null;
      } | null;
      bankTransferPayment?: {
        __typename?: 'BillBankTransferPayment';
        bsb?: string | null;
        accountName?: string | null;
        accountNumber?: string | null;
        reference?: string | null;
      } | null;
    } | null;
    linkedTransactions?: Array<{
      __typename?: 'LinkedTransaction';
      accountId?: string | null;
      paymentType?: BillTransactionType | null;
      referenceId: string;
      paymentStatus?: BillTransactionStatus | null;
      scheduledDate?: any | null;
      paymentGateway?: PaymentGateway | null;
      transactionDate?: any | null;
      amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
    }> | null;
  };
};

export type CreateDrawdownMutationVariables = Exact<{
  input: CreateLineOfCreditInput;
}>;

export type CreateDrawdownMutation = {
  __typename?: 'Mutation';
  createDrawdown: { __typename?: 'CreateDrawdownOutput'; applicationId: string };
};

export type BankPayeeFragmentFragment = {
  __typename?: 'BankPayeeV2';
  id: string;
  accountName?: string | null;
  paymentType: PaymentType;
  countryCode: Country;
  account: string;
  financialInstitution?: string | null;
  bsb?: string | null;
  bank?: string | null;
  branch?: string | null;
  suffix?: string | null;
};

export type BPayPayeeFragmentFragment = {
  __typename?: 'BpayPayeeV2';
  id: string;
  accountName?: string | null;
  paymentType: PaymentType;
  countryCode: Country;
  bPayBillerCode: string;
  bPayReference: string;
  billerName?: string | null;
};

export type CreatePayeeV2MutationVariables = Exact<{
  input: CreatePayeeV2Input;
}>;

export type CreatePayeeV2Mutation = {
  __typename?: 'Mutation';
  createPayeeV2:
    | { __typename?: 'CreatePayeeV2ErrorOutput'; error: CreateBPayErrorStatusV2 }
    | {
        __typename?: 'CreatePayeeV2SuccessOutput';
        payee:
          | { __typename: 'BAPayee' }
          | {
              __typename: 'BankPayeeV2';
              id: string;
              accountName?: string | null;
              paymentType: PaymentType;
              countryCode: Country;
              account: string;
              financialInstitution?: string | null;
              bsb?: string | null;
              bank?: string | null;
              branch?: string | null;
              suffix?: string | null;
            }
          | {
              __typename: 'BpayPayeeV2';
              id: string;
              accountName?: string | null;
              paymentType: PaymentType;
              countryCode: Country;
              bPayBillerCode: string;
              bPayReference: string;
              billerName?: string | null;
            };
      };
};

export type DeleteBillMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBillMutation = {
  __typename?: 'Mutation';
  deleteBill: { __typename: 'BillPayDeleteOutput'; success: boolean };
};

export type DeletePayeeV2MutationVariables = Exact<{
  input: DeletePayeeInput;
}>;

export type DeletePayeeV2Mutation = {
  __typename?: 'Mutation';
  deletePayee: { __typename?: 'DeletePayeeOutput'; payeeId: string };
};

export type DisconnectIntegrationMutationVariables = Exact<{
  input: DisconnectIntegrationInput;
}>;

export type DisconnectIntegrationMutation = {
  __typename?: 'Mutation';
  disconnectIntegration: { __typename?: 'DisconnectIntegrationOutput'; success: boolean };
};

export type ExchangeAuthCodeMutationVariables = Exact<{
  authorizationCode: Scalars['String'];
  integrationProduct: IntegrationProduct;
}>;

export type ExchangeAuthCodeMutation = {
  __typename?: 'Mutation';
  exchangeAuthCode: { __typename?: 'ExchangeAuthCodeOutput'; statusCode: string };
};

export type GenerateContractSignUrlMutationVariables = Exact<{
  input: GenerateContractSignUrlInput;
}>;

export type GenerateContractSignUrlMutation = {
  __typename?: 'Mutation';
  generateContractSignUrl:
    | {
        __typename?: 'GenerateContractSignUrlErrorOutput';
        code: GenerateContractSignUrlErrorCode;
        message: string;
      }
    | { __typename?: 'GenerateContractSignUrlSuccessOutput'; url: string };
};

export type GetProofOfTransferMutationVariables = Exact<{
  input: ProofOfTransferInput;
}>;

export type GetProofOfTransferMutation = {
  __typename?: 'Mutation';
  proofOfTransfer: { __typename?: 'ProofOfTransferOutput'; url: string };
};

export type InitialiseBillPayMutationVariables = Exact<{ [key: string]: never }>;

export type InitialiseBillPayMutation = { __typename?: 'Mutation'; initialiseBillPay: string };

export type RequestNewCardMutationVariables = Exact<{
  input: RequestNewCardInput;
}>;

export type RequestNewCardMutation = {
  __typename?: 'Mutation';
  requestNewCard: {
    __typename?: 'RequestNewCardOutput';
    cardMeta: { __typename?: 'CardMeta'; id: string };
  };
};

export type ScheduledTransferMutationVariables = Exact<{
  input: ScheduledTransferInput;
}>;

export type ScheduledTransferMutation = {
  __typename?: 'Mutation';
  scheduledTransfer:
    | { __typename: 'PaymentErrorOutput'; errorCode: TransferError; displayMessage: string }
    | { __typename: 'PaymentSuccessOutput'; productId: string; transactionId?: string | null };
};

export type SetCardContactlessMutationVariables = Exact<{
  input: SetCardContactlessInput;
}>;

export type SetCardContactlessMutation = {
  __typename?: 'Mutation';
  setCardContactless: {
    __typename?: 'SetCardContactlessOutput';
    cardMeta: { __typename?: 'CardMeta'; id: string; contactless: boolean };
  };
};

export type SetCardFrozenMutationVariables = Exact<{
  input: SetCardFrozenInput;
}>;

export type SetCardFrozenMutation = {
  __typename?: 'Mutation';
  setCardFrozen: {
    __typename?: 'SetCardFrozenOutput';
    cardMeta: { __typename?: 'CardMeta'; id: string; frozen: boolean };
  };
};

export type StepUpInitMutationVariables = Exact<{ [key: string]: never }>;

export type StepUpInitMutation = {
  __typename?: 'Mutation';
  stepUpInit: { __typename?: 'StepUpInitOutput'; codeDeliveryType: string; expiresIn: number };
};

export type StepUpVerifyMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type StepUpVerifyMutation = {
  __typename?: 'Mutation';
  stepUpVerify:
    | { __typename?: 'StepUpVerifyErrorOutput'; error: StepUpVerifyError }
    | { __typename?: 'StepUpVerifySuccessOutput'; token: string; validUntil: any };
};

export type TransferMutationVariables = Exact<{
  input: TransferInput;
}>;

export type TransferMutation = {
  __typename?: 'Mutation';
  transfer:
    | { __typename: 'PaymentErrorOutput'; errorCode: TransferError; displayMessage: string }
    | { __typename: 'PaymentSuccessOutput'; productId: string; transactionId?: string | null };
};

export type UpdateBillPaymentReferenceMutationVariables = Exact<{
  billId: Scalars['ID'];
  input: UpdateBillPaymentReferenceInput;
}>;

export type UpdateBillPaymentReferenceMutation = {
  __typename?: 'Mutation';
  updateBillPaymentReference: {
    __typename?: 'BillDetails';
    id: string;
    status: BillStatus;
    linkedTransactions?: Array<{
      __typename?: 'LinkedTransaction';
      accountId?: string | null;
      referenceId: string;
      paymentType?: BillTransactionType | null;
      scheduledDate?: any | null;
      paymentGateway?: PaymentGateway | null;
      amount?: { __typename?: 'Money'; amount: any } | null;
    }> | null;
  };
};

export type UpdateCardPinMutationVariables = Exact<{
  input: UpdateCardPinInput;
}>;

export type UpdateCardPinMutation = {
  __typename?: 'Mutation';
  updateCardPin: { __typename?: 'UpdateCardPinOutput'; success: boolean };
};

export type UpdateDataFeedsMutationVariables = Exact<{
  input: UpdateDataFeedsInput;
}>;

export type UpdateDataFeedsMutation = {
  __typename?: 'Mutation';
  updateDataFeeds: { __typename?: 'UpdateDataFeedsOutput'; success: boolean };
};

export type VerifyBaCardNumberMutationVariables = Exact<{
  input: VerifyBaCardNumberInput;
}>;

export type VerifyBaCardNumberMutation = {
  __typename?: 'Mutation';
  verifyBACardNumber: { __typename?: 'VerifyBACardNumberOutput'; success: boolean };
};

export type GetAccountsListDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsListDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      businessAccountDetailId: string;
      balance: {
        __typename?: 'BusinessAccountBalance';
        available: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
        total: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      };
      overdraft: { __typename?: 'OverdraftDetail'; overdraftLimit: any };
    } | null;
    smallBusinessLoans: Array<{
      __typename?: 'SmallBusinessLoan';
      id: string;
      type: SmallBusinessLoanType;
      displayStatus: string;
      contractStatus?: SmallBusinessLoanContractStatus | null;
      hasMissedPayment: boolean;
      isInArrears: boolean;
      daysPastDue?: any | null;
      collectionsPaymentUrl?: string | null;
      isRefinanceEligible: boolean;
      loanAmount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      outstandingAmount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      inArrearsAmount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
    }>;
    linesOfCredit: Array<{
      __typename?: 'LineOfCredit';
      id: string;
      status: LineOfCreditStatus;
      displayStatus: string;
      contractStatus: LineOfCreditContractStatus;
      isInArrears: boolean;
      missedPaymentDate?: any | null;
      inArrearsAmount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
      totalAmount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      availableAmount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      pendingAmount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      missedPayments: Array<{
        __typename?: 'MissedPayment';
        type: string;
        amount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      }>;
      outstandingAmount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
    }>;
    contracts?: Array<{
      __typename?: 'Contract';
      id: string;
      status: ContractStatus;
      productName: string;
      expiryDateTime?: any | null;
    }> | null;
  };
};

export type GetAppContextDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppContextDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    cdpId?: string | null;
    email: string;
    country: Country;
    givenName: string;
    familyName: string;
    phoneNumber: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      businessAccountDetailId: string;
      abn?: string | null;
      status: BusinessAccountStatus;
      accountName: string;
      accountNumber: string;
      branchNumber: string;
      currency: CurrencyCode;
      homeAddress?: {
        __typename?: 'Address';
        line1: string;
        townOrCity: string;
        postcode: string;
        administrativeRegion: string;
        country: string;
      } | null;
      overdraft: {
        __typename?: 'OverdraftDetail';
        annualServiceRate: any;
        annualInterestRate: any;
        dailyInterestRate?: any | null;
        overdraftTermEndDateUtc?: any | null;
      };
    } | null;
    smallBusinessLoans: Array<{ __typename?: 'SmallBusinessLoan'; id: string }>;
    linesOfCredit: Array<{ __typename?: 'LineOfCredit'; id: string }>;
    contracts?: Array<{ __typename?: 'Contract'; id: string }> | null;
  };
};

export type GetBaPendingTransactionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBaPendingTransactionsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      pendingTransactions: Array<{
        __typename?: 'BusinessAccountTransaction';
        id: string;
        displayName?: string | null;
        transactionTime: any;
        description?: string | null;
        isPending: boolean;
        reference?: string | null;
        amount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      }>;
    } | null;
  };
};

export type GetBaSettledTransactionsQueryVariables = Exact<{
  first: Scalars['PositiveInt'];
  after?: InputMaybe<Scalars['NonNegativeInt']>;
}>;

export type GetBaSettledTransactionsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      transactions: {
        __typename?: 'BusinessAccountTransactionConnection';
        edges: Array<{
          __typename?: 'BusinessAccountTransactionEdge';
          cursor?: string | null;
          node: {
            __typename?: 'BusinessAccountTransactionListItem';
            id: string;
            description?: string | null;
            transactionTime: any;
            displayName?: string | null;
            isPending: boolean;
            initiatedBy?: string | null;
            reference?: string | null;
            amount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
          };
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          endCursor?: string | null;
          startCursor?: string | null;
          hasPrevPage: boolean;
          hasNextPage: boolean;
        };
      };
    } | null;
  };
};

export type GetBillDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBillDetailsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    billPay?: {
      __typename?: 'BillPay';
      id: string;
      bill?: {
        __typename?: 'BillDetails';
        id: string;
        status: BillStatus;
        billerABN?: string | null;
        billerName?: string | null;
        dueDate?: any | null;
        uploadDate?: any | null;
        fileUrl: string;
        fileName?: string | null;
        description?: string | null;
        billSource?: {
          __typename?: 'BillSource';
          inputMethod: BillInputMethod;
          receiverAddress?: string | null;
          senderEmailAddress?: string | null;
        } | null;
        amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
        paymentMethods?: {
          __typename?: 'BillPaymentMethods';
          paymentTypes?: Array<BillPaymentType> | null;
          bpayPayment?: {
            __typename?: 'BillBPayPayment';
            billerCode?: string | null;
            reference?: string | null;
          } | null;
          bankTransferPayment?: {
            __typename?: 'BillBankTransferPayment';
            bsb?: string | null;
            accountName?: string | null;
            accountNumber?: string | null;
            reference?: string | null;
          } | null;
        } | null;
        linkedTransactions?: Array<{
          __typename?: 'LinkedTransaction';
          accountId?: string | null;
          paymentType?: BillTransactionType | null;
          referenceId: string;
          paymentStatus?: BillTransactionStatus | null;
          scheduledDate?: any | null;
          paymentGateway?: PaymentGateway | null;
          transactionDate?: any | null;
          amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
        }> | null;
      } | null;
    } | null;
  };
};

export type GetBillsQueryVariables = Exact<{
  status: Array<BillStatus> | BillStatus;
}>;

export type GetBillsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    billPay?: {
      __typename?: 'BillPay';
      id: string;
      dueNextMonthAmount?: { __typename?: 'Money'; amount: any } | null;
      dueThisMonthAmount?: { __typename?: 'Money'; amount: any } | null;
      bills?: Array<{
        __typename?: 'BillDetails';
        id: string;
        status: BillStatus;
        uploadDate?: any | null;
        dueDate?: any | null;
        billerName?: string | null;
        billSource?: { __typename?: 'BillSource'; senderEmailAddress?: string | null } | null;
        amount?: { __typename?: 'Money'; amount: any } | null;
        linkedTransactions?: Array<{
          __typename?: 'LinkedTransaction';
          accountId?: string | null;
          paymentType?: BillTransactionType | null;
          referenceId: string;
          paymentStatus?: BillTransactionStatus | null;
          scheduledDate?: any | null;
          paymentGateway?: PaymentGateway | null;
          transactionDate?: any | null;
          amount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
        }> | null;
      }> | null;
    } | null;
  };
};

export type GetBillsEmailQueryVariables = Exact<{ [key: string]: never }>;

export type GetBillsEmailQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    billPay?: { __typename?: 'BillPay'; id: string; uploadEmailAddress?: string | null } | null;
  };
};

export type BsbLookupQueryVariables = Exact<{
  bsbNumber: Scalars['String'];
}>;

export type BsbLookupQuery = {
  __typename?: 'Query';
  bsb?: { __typename?: 'Bsb'; id: string; financialInstitutionCode: string; city: string } | null;
};

export type GetBusinessAccountDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBusinessAccountDetailsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      accountNumber: string;
      branchNumber: string;
      currency: CurrencyCode;
      balance: {
        __typename?: 'BusinessAccountBalance';
        total: { __typename?: 'Money'; amount: any };
        available: { __typename?: 'Money'; amount: any };
      };
    } | null;
  };
};

export type GetBusinessAccountPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetBusinessAccountPageDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      accountNumber: string;
      branchNumber: string;
      status: BusinessAccountStatus;
      allowOverdraft: boolean;
      overdraft: {
        __typename?: 'OverdraftDetail';
        overdraftLimit: any;
        dailyInterestRate?: any | null;
        annualInterestRate: any;
        overdraftTermEndDateUtc?: any | null;
      };
      balance: {
        __typename?: 'BusinessAccountBalance';
        available: { __typename?: 'Money'; amount: any };
        total: { __typename?: 'Money'; amount: any };
      };
    } | null;
  };
};

export type GetBusinessAccountScheduledPaymentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBusinessAccountScheduledPaymentsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      scheduledTransactions?: Array<{
        __typename?: 'BusinessAccountScheduledTransaction';
        cancelOnFailure?: boolean | null;
        description: string;
        endDate?: any | null;
        frequency?: BusinessAccountScheduledPaymentFrequency | null;
        id: string;
        numberOfPayments?: any | null;
        reference?: string | null;
        startDate: any;
        status?: BusinessAccountScheduledPaymentStatus | null;
        type: BusinessAccountSchedulePaymentType;
        amount: { __typename?: 'Money'; amount: any };
        details:
          | { __typename?: 'BPayDetails'; billerCode: string; reference: string; nickname: string }
          | {
              __typename?: 'BankDetails';
              accountNumber?: string | null;
              bsb?: string | null;
              name: string;
            };
      }> | null;
    } | null;
  };
};

export type GetCardsMetaQueryVariables = Exact<{ [key: string]: never }>;

export type GetCardsMetaQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      cards?: Array<{
        __typename?: 'Card';
        id: string;
        frozen: boolean;
        contactless: boolean;
        status: CardStatus;
        image?: string | null;
        digitalWalletDetails?: {
          __typename?: 'DigitalWalletDetails';
          primaryAccountIdentifier?: string | null;
          wallets: Array<{ __typename?: 'DigitalWallet'; type: WalletType; reference: string }>;
        } | null;
      }> | null;
    } | null;
  };
};

export type GetContractQueryVariables = Exact<{
  contractId: Scalars['String'];
}>;

export type GetContractQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    contract?: { __typename?: 'Contract'; id: string; status: ContractStatus } | null;
  };
};

export type GetDrawdownDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDrawdownDetailsQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    linesOfCredit: Array<{
      __typename?: 'LineOfCredit';
      id: string;
      status: LineOfCreditStatus;
      contractStatus: LineOfCreditContractStatus;
      maskedLinkedAccountNumber: string;
      availableAmount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      drawdown: { __typename?: 'DrawdownDetails'; status: DrawdownStatus; text: string };
    }>;
  };
};

export type GetIdentityQueryVariables = Exact<{ [key: string]: never }>;

export type GetIdentityQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; id: string; email: string; phoneNumber: string; givenName: string };
};

export type GetIntegrationsDataV2QueryVariables = Exact<{
  type: IntegrationProduct;
}>;

export type GetIntegrationsDataV2Query = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    integrationsV2: {
      __typename?: 'IntegrationsV2';
      type: IntegrationProduct;
      status: IntegrationStatus;
      displayStatus?: string | null;
      products: Array<{
        __typename?: 'IntegrationProductInfo';
        id?: string | null;
        productId?: string | null;
        productType: ProspaProduct;
        syncDateTime?: any | null;
        isSynced: boolean;
        errorStatus?: IntegrationProductErrorStatus | null;
        displayStatus?: string | null;
        displaySubtitle?: string | null;
      }>;
    };
  };
};

export type GetLoansStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoansStatusesQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    smallBusinessLoans: Array<{
      __typename?: 'SmallBusinessLoan';
      id: string;
      type: SmallBusinessLoanType;
      displayStatus: string;
    }>;
    linesOfCredit: Array<{ __typename?: 'LineOfCredit'; id: string; status: LineOfCreditStatus }>;
  };
};

export type GetNavDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetNavDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    linesOfCredit: Array<{
      __typename?: 'LineOfCredit';
      id: string;
      status: LineOfCreditStatus;
      displayStatus: string;
    }>;
  };
};

export type GetPayAnyoneDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetPayAnyoneDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    linesOfCredit: Array<{
      __typename?: 'LineOfCredit';
      id: string;
      status: LineOfCreditStatus;
      maskedLinkedAccountNumber: string;
      availableAmount: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
      drawdown: { __typename?: 'DrawdownDetails'; status: DrawdownStatus; text: string };
      paymentLimits?: {
        __typename?: 'LineOfCreditPayAnyoneDetails';
        mfaThreshold?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
        paymentAvailable?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
        dailyPaidAmount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
      } | null;
    }>;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      id: string;
      status: BusinessAccountStatus;
      branchNumber: string;
      accountNumber: string;
      balance: {
        __typename?: 'BusinessAccountBalance';
        available: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
        total: { __typename?: 'Money'; amount: any };
      };
    } | null;
  };
};

export type GetProductCvpPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductCvpPageDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    productOriginationStatuses: {
      __typename?: 'ProductOriginationStatuses';
      id: string;
      deferralPeriodEndDate?: any | null;
      businessAccount: {
        __typename?: 'ProductOriginationStatus';
        id: string;
        canApply: boolean;
        reason?: ProductOriginationRejectReason | null;
      };
      lineOfCredit: {
        __typename?: 'ProductOriginationStatus';
        id: string;
        canApply: boolean;
        reason?: ProductOriginationRejectReason | null;
      };
      smallBusinessLoan: {
        __typename?: 'ProductOriginationStatus';
        id: string;
        canApply: boolean;
        reason?: ProductOriginationRejectReason | null;
      };
      businessLoanPlus: {
        __typename?: 'ProductOriginationStatus';
        id: string;
        canApply: boolean;
        reason?: ProductOriginationRejectReason | null;
      };
    };
  };
};

export type GetRepaymentCalculatorDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetRepaymentCalculatorDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    repaymentCalculator: {
      __typename?: 'RepaymentCalculator';
      id: string;
      smallBusinessLoanRate: any;
      lineOfCreditRate: any;
    };
  };
};

export type GetSmallBusinessLoansDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetSmallBusinessLoansDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    smallBusinessLoans: Array<{
      __typename?: 'SmallBusinessLoan';
      id: string;
      type: SmallBusinessLoanType;
      displayStatus: string;
      countryCode: Country;
      isRefinanceEligible: boolean;
      hasMissedPayment: boolean;
      isInArrears: boolean;
      daysPastDue?: any | null;
      collectionsPaymentUrl?: string | null;
      inArrearsAmount?: { __typename?: 'Money'; amount: any; currency: CurrencyCode } | null;
    }>;
  };
};

export type GetStatementQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  fileType: StatementFileType;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
  type: StatementProductType;
  statementType?: InputMaybe<StatementType>;
}>;

export type GetStatementQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    statement: { __typename?: 'StatementUrl'; url: string; filename: string };
  };
};

export type GetStatementsByProductQueryVariables = Exact<{
  productType?: InputMaybe<StatementProductType>;
  productId?: InputMaybe<Scalars['String']>;
}>;

export type GetStatementsByProductQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    statements: Array<{
      __typename?: 'Statements';
      id: string;
      monthly: Array<{
        __typename?: 'Statement';
        id: string;
        title: string;
        productId: string;
        startDate: any;
        endDate: any;
      }>;
      yearly: Array<{
        __typename?: 'Statement';
        id: string;
        title: string;
        productId: string;
        startDate: any;
        endDate: any;
      }>;
      customDateRange?: { __typename?: 'StatementCustomDateRange'; min: any; max: any } | null;
    }>;
  };
};

export type GetStatementsSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatementsSummaryQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    businessAccount?: {
      __typename?: 'BusinessAccount';
      balance: {
        __typename?: 'BusinessAccountBalance';
        total: { __typename?: 'Money'; amount: any };
      };
    } | null;
    statements: Array<{
      __typename?: 'Statements';
      id: string;
      productId: string;
      productType: StatementProductType;
      displayTitle: string;
      displaySubtitle: string;
      displayStatus: string;
      amountLabel: string;
      amount: { __typename?: 'Money'; amount: any };
    }>;
  };
};

export type GetUpcomingRepaymentQueryVariables = Exact<{
  id: Scalars['ID'];
  additionalAmount: Scalars['Decimal'];
  productType: UpcomingRepaymentProductType;
}>;

export type GetUpcomingRepaymentQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    upcomingRepayment: {
      __typename?: 'UpcomingRepayment';
      id: string;
      nextRepaymentDate: any;
      totalRepaymentCharge: { __typename?: 'Money'; amount: any; currency: CurrencyCode };
    };
  };
};

export type GetUserCdpIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserCdpIdQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; id: string; cdpId?: string | null };
};

export type GetUserPayeesV2QueryVariables = Exact<{
  paymentTypes?: InputMaybe<Array<PaymentType> | PaymentType>;
  countryCode?: InputMaybe<Country>;
}>;

export type GetUserPayeesV2Query = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    payees?: Array<
      | {
          __typename?: 'BAPayee';
          id: string;
          account: string;
          accountName?: string | null;
          bsb?: string | null;
          countryCode: Country;
          paymentType: PaymentType;
        }
      | {
          __typename?: 'BankPayeeV2';
          id: string;
          account: string;
          accountName?: string | null;
          bank?: string | null;
          branch?: string | null;
          bsb?: string | null;
          suffix?: string | null;
          countryCode: Country;
          paymentType: PaymentType;
          financialInstitution?: string | null;
        }
      | {
          __typename?: 'BpayPayeeV2';
          id: string;
          accountName?: string | null;
          countryCode: Country;
          paymentType: PaymentType;
          billerName?: string | null;
          bPayBillerCode: string;
          bPayReference: string;
        }
    > | null;
  };
};

export type VerifyNzAccountNumberQueryVariables = Exact<{
  accountDetails: VerifyAccountDetails;
  accountType: AccountType;
}>;

export type VerifyNzAccountNumberQuery = {
  __typename?: 'Query';
  verifyAccountNumber: { __typename?: 'VerifyAccountNumberOutput'; isValid: boolean };
};

export const BillTransactionFragmentFragmentDoc = gql`
  fragment BillTransactionFragment on LinkedTransaction {
    amount {
      amount
      currency
    }
    accountId
    paymentType
    referenceId
    paymentStatus
    scheduledDate
    paymentGateway
    transactionDate
  }
`;
export const BillFragmentFragmentDoc = gql`
  fragment BillFragment on BillDetails {
    id
    status
    billerABN
    billSource {
      inputMethod
      receiverAddress
      senderEmailAddress
    }
    amount {
      amount
      currency
    }
    billerName
    dueDate
    uploadDate
    fileUrl
    fileName
    description
    paymentMethods {
      paymentTypes
      bpayPayment {
        billerCode
        reference
      }
      bankTransferPayment {
        bsb
        accountName
        accountNumber
        reference
      }
    }
    linkedTransactions {
      ...BillTransactionFragment
    }
  }
  ${BillTransactionFragmentFragmentDoc}
`;
export const BpayPayeeV2FragmentFragmentDoc = gql`
  fragment BpayPayeeV2Fragment on BpayPayeeV2 {
    id
    accountName
    countryCode
    paymentType
    billerName
    bPayBillerCode
    bPayReference
  }
`;
export const BankPayeeV2FragmentFragmentDoc = gql`
  fragment BankPayeeV2Fragment on BankPayeeV2 {
    id
    account
    accountName
    bank
    branch
    bsb
    suffix
    countryCode
    paymentType
    financialInstitution
  }
`;
export const BaPayeeFragmentFragmentDoc = gql`
  fragment BAPayeeFragment on BAPayee {
    id
    account
    accountName
    bsb
    countryCode
    paymentType
  }
`;
export const SchedulePayeeBankDetailsFragmentDoc = gql`
  fragment SchedulePayeeBankDetails on BankDetails {
    accountNumber
    bsb
    name
  }
`;
export const SchedulePayeeBPayDetailsFragmentDoc = gql`
  fragment SchedulePayeeBPayDetails on BPayDetails {
    billerCode
    reference
    nickname
  }
`;
export const ScheduledPaymentActivityFragmentDoc = gql`
  fragment ScheduledPaymentActivity on BusinessAccountScheduledTransaction {
    amount {
      amount
    }
    cancelOnFailure
    description
    endDate
    frequency
    id
    numberOfPayments
    details {
      ...SchedulePayeeBankDetails
      ...SchedulePayeeBPayDetails
    }
    reference
    startDate
    status
    type
  }
  ${SchedulePayeeBankDetailsFragmentDoc}
  ${SchedulePayeeBPayDetailsFragmentDoc}
`;
export const BankPayeeFragmentFragmentDoc = gql`
  fragment BankPayeeFragment on BankPayeeV2 {
    id
    accountName
    paymentType
    countryCode
    account
    financialInstitution
    bsb
    bank
    branch
    suffix
  }
`;
export const BPayPayeeFragmentFragmentDoc = gql`
  fragment BPayPayeeFragment on BpayPayeeV2 {
    id
    accountName
    paymentType
    countryCode
    bPayBillerCode
    bPayReference
    billerName
  }
`;
export const ActivateBaPhysicalCardDocument = gql`
  mutation activateBAPhysicalCard($input: ActivateBAPhysicalCardInput!) {
    activateBAPhysicalCard(input: $input) {
      success
    }
  }
`;
export type ActivateBaPhysicalCardMutationFn = Apollo.MutationFunction<
  ActivateBaPhysicalCardMutation,
  ActivateBaPhysicalCardMutationVariables
>;

/**
 * __useActivateBaPhysicalCardMutation__
 *
 * To run a mutation, you first call `useActivateBaPhysicalCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBaPhysicalCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBaPhysicalCardMutation, { data, loading, error }] = useActivateBaPhysicalCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateBaPhysicalCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateBaPhysicalCardMutation,
    ActivateBaPhysicalCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateBaPhysicalCardMutation,
    ActivateBaPhysicalCardMutationVariables
  >(ActivateBaPhysicalCardDocument, options);
}
export type ActivateBaPhysicalCardMutationHookResult = ReturnType<
  typeof useActivateBaPhysicalCardMutation
>;
export type ActivateBaPhysicalCardMutationResult =
  Apollo.MutationResult<ActivateBaPhysicalCardMutation>;
export type ActivateBaPhysicalCardMutationOptions = Apollo.BaseMutationOptions<
  ActivateBaPhysicalCardMutation,
  ActivateBaPhysicalCardMutationVariables
>;
export const CancelScheduledBillPaymentDocument = gql`
  mutation cancelScheduledBillPayment($input: DeleteBillPaymentReferenceInput!) {
    deleteBillPaymentReference(input: $input) {
      success
      __typename
    }
  }
`;
export type CancelScheduledBillPaymentMutationFn = Apollo.MutationFunction<
  CancelScheduledBillPaymentMutation,
  CancelScheduledBillPaymentMutationVariables
>;

/**
 * __useCancelScheduledBillPaymentMutation__
 *
 * To run a mutation, you first call `useCancelScheduledBillPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledBillPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledBillPaymentMutation, { data, loading, error }] = useCancelScheduledBillPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelScheduledBillPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduledBillPaymentMutation,
    CancelScheduledBillPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelScheduledBillPaymentMutation,
    CancelScheduledBillPaymentMutationVariables
  >(CancelScheduledBillPaymentDocument, options);
}
export type CancelScheduledBillPaymentMutationHookResult = ReturnType<
  typeof useCancelScheduledBillPaymentMutation
>;
export type CancelScheduledBillPaymentMutationResult =
  Apollo.MutationResult<CancelScheduledBillPaymentMutation>;
export type CancelScheduledBillPaymentMutationOptions = Apollo.BaseMutationOptions<
  CancelScheduledBillPaymentMutation,
  CancelScheduledBillPaymentMutationVariables
>;
export const CancelScheduledPaymentDocument = gql`
  mutation cancelScheduledPayment($input: CancelScheduledPaymentInput!) {
    cancelScheduledPayment(input: $input) {
      success
    }
  }
`;
export type CancelScheduledPaymentMutationFn = Apollo.MutationFunction<
  CancelScheduledPaymentMutation,
  CancelScheduledPaymentMutationVariables
>;

/**
 * __useCancelScheduledPaymentMutation__
 *
 * To run a mutation, you first call `useCancelScheduledPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduledPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduledPaymentMutation, { data, loading, error }] = useCancelScheduledPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelScheduledPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduledPaymentMutation,
    CancelScheduledPaymentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelScheduledPaymentMutation,
    CancelScheduledPaymentMutationVariables
  >(CancelScheduledPaymentDocument, options);
}
export type CancelScheduledPaymentMutationHookResult = ReturnType<
  typeof useCancelScheduledPaymentMutation
>;
export type CancelScheduledPaymentMutationResult =
  Apollo.MutationResult<CancelScheduledPaymentMutation>;
export type CancelScheduledPaymentMutationOptions = Apollo.BaseMutationOptions<
  CancelScheduledPaymentMutation,
  CancelScheduledPaymentMutationVariables
>;
export const ConfirmBillDetailDocument = gql`
  mutation confirmBillDetail($id: ID!, $input: UpdateBillInput!) {
    updateBill(billId: $id, input: $input) {
      ...BillFragment
    }
  }
  ${BillFragmentFragmentDoc}
`;
export type ConfirmBillDetailMutationFn = Apollo.MutationFunction<
  ConfirmBillDetailMutation,
  ConfirmBillDetailMutationVariables
>;

/**
 * __useConfirmBillDetailMutation__
 *
 * To run a mutation, you first call `useConfirmBillDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBillDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBillDetailMutation, { data, loading, error }] = useConfirmBillDetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmBillDetailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmBillDetailMutation,
    ConfirmBillDetailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmBillDetailMutation, ConfirmBillDetailMutationVariables>(
    ConfirmBillDetailDocument,
    options,
  );
}
export type ConfirmBillDetailMutationHookResult = ReturnType<typeof useConfirmBillDetailMutation>;
export type ConfirmBillDetailMutationResult = Apollo.MutationResult<ConfirmBillDetailMutation>;
export type ConfirmBillDetailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmBillDetailMutation,
  ConfirmBillDetailMutationVariables
>;
export const CreateDrawdownDocument = gql`
  mutation createDrawdown($input: CreateLineOfCreditInput!) {
    createDrawdown(input: $input) {
      applicationId
    }
  }
`;
export type CreateDrawdownMutationFn = Apollo.MutationFunction<
  CreateDrawdownMutation,
  CreateDrawdownMutationVariables
>;

/**
 * __useCreateDrawdownMutation__
 *
 * To run a mutation, you first call `useCreateDrawdownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDrawdownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDrawdownMutation, { data, loading, error }] = useCreateDrawdownMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDrawdownMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDrawdownMutation, CreateDrawdownMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDrawdownMutation, CreateDrawdownMutationVariables>(
    CreateDrawdownDocument,
    options,
  );
}
export type CreateDrawdownMutationHookResult = ReturnType<typeof useCreateDrawdownMutation>;
export type CreateDrawdownMutationResult = Apollo.MutationResult<CreateDrawdownMutation>;
export type CreateDrawdownMutationOptions = Apollo.BaseMutationOptions<
  CreateDrawdownMutation,
  CreateDrawdownMutationVariables
>;
export const CreatePayeeV2Document = gql`
  mutation createPayeeV2($input: CreatePayeeV2Input!) {
    createPayeeV2(input: $input) {
      ... on CreatePayeeV2SuccessOutput {
        payee {
          __typename
          ...BPayPayeeFragment
          ...BankPayeeFragment
        }
      }
      ... on CreatePayeeV2ErrorOutput {
        error
      }
    }
  }
  ${BPayPayeeFragmentFragmentDoc}
  ${BankPayeeFragmentFragmentDoc}
`;
export type CreatePayeeV2MutationFn = Apollo.MutationFunction<
  CreatePayeeV2Mutation,
  CreatePayeeV2MutationVariables
>;

/**
 * __useCreatePayeeV2Mutation__
 *
 * To run a mutation, you first call `useCreatePayeeV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePayeeV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPayeeV2Mutation, { data, loading, error }] = useCreatePayeeV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePayeeV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePayeeV2Mutation, CreatePayeeV2MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePayeeV2Mutation, CreatePayeeV2MutationVariables>(
    CreatePayeeV2Document,
    options,
  );
}
export type CreatePayeeV2MutationHookResult = ReturnType<typeof useCreatePayeeV2Mutation>;
export type CreatePayeeV2MutationResult = Apollo.MutationResult<CreatePayeeV2Mutation>;
export type CreatePayeeV2MutationOptions = Apollo.BaseMutationOptions<
  CreatePayeeV2Mutation,
  CreatePayeeV2MutationVariables
>;
export const DeleteBillDocument = gql`
  mutation deleteBill($id: ID!) {
    deleteBill(id: $id) {
      success
      __typename
    }
  }
`;
export type DeleteBillMutationFn = Apollo.MutationFunction<
  DeleteBillMutation,
  DeleteBillMutationVariables
>;

/**
 * __useDeleteBillMutation__
 *
 * To run a mutation, you first call `useDeleteBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillMutation, { data, loading, error }] = useDeleteBillMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBillMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBillMutation, DeleteBillMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBillMutation, DeleteBillMutationVariables>(
    DeleteBillDocument,
    options,
  );
}
export type DeleteBillMutationHookResult = ReturnType<typeof useDeleteBillMutation>;
export type DeleteBillMutationResult = Apollo.MutationResult<DeleteBillMutation>;
export type DeleteBillMutationOptions = Apollo.BaseMutationOptions<
  DeleteBillMutation,
  DeleteBillMutationVariables
>;
export const DeletePayeeV2Document = gql`
  mutation deletePayeeV2($input: DeletePayeeInput!) {
    deletePayee(input: $input) {
      payeeId
    }
  }
`;
export type DeletePayeeV2MutationFn = Apollo.MutationFunction<
  DeletePayeeV2Mutation,
  DeletePayeeV2MutationVariables
>;

/**
 * __useDeletePayeeV2Mutation__
 *
 * To run a mutation, you first call `useDeletePayeeV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayeeV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayeeV2Mutation, { data, loading, error }] = useDeletePayeeV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePayeeV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePayeeV2Mutation, DeletePayeeV2MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePayeeV2Mutation, DeletePayeeV2MutationVariables>(
    DeletePayeeV2Document,
    options,
  );
}
export type DeletePayeeV2MutationHookResult = ReturnType<typeof useDeletePayeeV2Mutation>;
export type DeletePayeeV2MutationResult = Apollo.MutationResult<DeletePayeeV2Mutation>;
export type DeletePayeeV2MutationOptions = Apollo.BaseMutationOptions<
  DeletePayeeV2Mutation,
  DeletePayeeV2MutationVariables
>;
export const DisconnectIntegrationDocument = gql`
  mutation disconnectIntegration($input: DisconnectIntegrationInput!) {
    disconnectIntegration(input: $input) {
      success
    }
  }
`;
export type DisconnectIntegrationMutationFn = Apollo.MutationFunction<
  DisconnectIntegrationMutation,
  DisconnectIntegrationMutationVariables
>;

/**
 * __useDisconnectIntegrationMutation__
 *
 * To run a mutation, you first call `useDisconnectIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectIntegrationMutation, { data, loading, error }] = useDisconnectIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisconnectIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectIntegrationMutation,
    DisconnectIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisconnectIntegrationMutation, DisconnectIntegrationMutationVariables>(
    DisconnectIntegrationDocument,
    options,
  );
}
export type DisconnectIntegrationMutationHookResult = ReturnType<
  typeof useDisconnectIntegrationMutation
>;
export type DisconnectIntegrationMutationResult =
  Apollo.MutationResult<DisconnectIntegrationMutation>;
export type DisconnectIntegrationMutationOptions = Apollo.BaseMutationOptions<
  DisconnectIntegrationMutation,
  DisconnectIntegrationMutationVariables
>;
export const ExchangeAuthCodeDocument = gql`
  mutation exchangeAuthCode($authorizationCode: String!, $integrationProduct: IntegrationProduct!) {
    exchangeAuthCode(
      authorizationCode: $authorizationCode
      integrationProduct: $integrationProduct
    ) {
      statusCode
    }
  }
`;
export type ExchangeAuthCodeMutationFn = Apollo.MutationFunction<
  ExchangeAuthCodeMutation,
  ExchangeAuthCodeMutationVariables
>;

/**
 * __useExchangeAuthCodeMutation__
 *
 * To run a mutation, you first call `useExchangeAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeAuthCodeMutation, { data, loading, error }] = useExchangeAuthCodeMutation({
 *   variables: {
 *      authorizationCode: // value for 'authorizationCode'
 *      integrationProduct: // value for 'integrationProduct'
 *   },
 * });
 */
export function useExchangeAuthCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExchangeAuthCodeMutation,
    ExchangeAuthCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExchangeAuthCodeMutation, ExchangeAuthCodeMutationVariables>(
    ExchangeAuthCodeDocument,
    options,
  );
}
export type ExchangeAuthCodeMutationHookResult = ReturnType<typeof useExchangeAuthCodeMutation>;
export type ExchangeAuthCodeMutationResult = Apollo.MutationResult<ExchangeAuthCodeMutation>;
export type ExchangeAuthCodeMutationOptions = Apollo.BaseMutationOptions<
  ExchangeAuthCodeMutation,
  ExchangeAuthCodeMutationVariables
>;
export const GenerateContractSignUrlDocument = gql`
  mutation generateContractSignUrl($input: GenerateContractSignUrlInput!) {
    generateContractSignUrl(input: $input) {
      ... on GenerateContractSignUrlSuccessOutput {
        url
      }
      ... on GenerateContractSignUrlErrorOutput {
        code
        message
      }
    }
  }
`;
export type GenerateContractSignUrlMutationFn = Apollo.MutationFunction<
  GenerateContractSignUrlMutation,
  GenerateContractSignUrlMutationVariables
>;

/**
 * __useGenerateContractSignUrlMutation__
 *
 * To run a mutation, you first call `useGenerateContractSignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateContractSignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateContractSignUrlMutation, { data, loading, error }] = useGenerateContractSignUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateContractSignUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateContractSignUrlMutation,
    GenerateContractSignUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateContractSignUrlMutation,
    GenerateContractSignUrlMutationVariables
  >(GenerateContractSignUrlDocument, options);
}
export type GenerateContractSignUrlMutationHookResult = ReturnType<
  typeof useGenerateContractSignUrlMutation
>;
export type GenerateContractSignUrlMutationResult =
  Apollo.MutationResult<GenerateContractSignUrlMutation>;
export type GenerateContractSignUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateContractSignUrlMutation,
  GenerateContractSignUrlMutationVariables
>;
export const GetProofOfTransferDocument = gql`
  mutation getProofOfTransfer($input: ProofOfTransferInput!) {
    proofOfTransfer(input: $input) {
      url
    }
  }
`;
export type GetProofOfTransferMutationFn = Apollo.MutationFunction<
  GetProofOfTransferMutation,
  GetProofOfTransferMutationVariables
>;

/**
 * __useGetProofOfTransferMutation__
 *
 * To run a mutation, you first call `useGetProofOfTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetProofOfTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getProofOfTransferMutation, { data, loading, error }] = useGetProofOfTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProofOfTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetProofOfTransferMutation,
    GetProofOfTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetProofOfTransferMutation, GetProofOfTransferMutationVariables>(
    GetProofOfTransferDocument,
    options,
  );
}
export type GetProofOfTransferMutationHookResult = ReturnType<typeof useGetProofOfTransferMutation>;
export type GetProofOfTransferMutationResult = Apollo.MutationResult<GetProofOfTransferMutation>;
export type GetProofOfTransferMutationOptions = Apollo.BaseMutationOptions<
  GetProofOfTransferMutation,
  GetProofOfTransferMutationVariables
>;
export const InitialiseBillPayDocument = gql`
  mutation initialiseBillPay {
    initialiseBillPay
  }
`;
export type InitialiseBillPayMutationFn = Apollo.MutationFunction<
  InitialiseBillPayMutation,
  InitialiseBillPayMutationVariables
>;

/**
 * __useInitialiseBillPayMutation__
 *
 * To run a mutation, you first call `useInitialiseBillPayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialiseBillPayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialiseBillPayMutation, { data, loading, error }] = useInitialiseBillPayMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitialiseBillPayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitialiseBillPayMutation,
    InitialiseBillPayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InitialiseBillPayMutation, InitialiseBillPayMutationVariables>(
    InitialiseBillPayDocument,
    options,
  );
}
export type InitialiseBillPayMutationHookResult = ReturnType<typeof useInitialiseBillPayMutation>;
export type InitialiseBillPayMutationResult = Apollo.MutationResult<InitialiseBillPayMutation>;
export type InitialiseBillPayMutationOptions = Apollo.BaseMutationOptions<
  InitialiseBillPayMutation,
  InitialiseBillPayMutationVariables
>;
export const RequestNewCardDocument = gql`
  mutation requestNewCard($input: RequestNewCardInput!) {
    requestNewCard(input: $input) {
      ... on RequestNewCardOutput {
        cardMeta {
          id
        }
      }
    }
  }
`;
export type RequestNewCardMutationFn = Apollo.MutationFunction<
  RequestNewCardMutation,
  RequestNewCardMutationVariables
>;

/**
 * __useRequestNewCardMutation__
 *
 * To run a mutation, you first call `useRequestNewCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestNewCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestNewCardMutation, { data, loading, error }] = useRequestNewCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestNewCardMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestNewCardMutation, RequestNewCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestNewCardMutation, RequestNewCardMutationVariables>(
    RequestNewCardDocument,
    options,
  );
}
export type RequestNewCardMutationHookResult = ReturnType<typeof useRequestNewCardMutation>;
export type RequestNewCardMutationResult = Apollo.MutationResult<RequestNewCardMutation>;
export type RequestNewCardMutationOptions = Apollo.BaseMutationOptions<
  RequestNewCardMutation,
  RequestNewCardMutationVariables
>;
export const ScheduledTransferDocument = gql`
  mutation scheduledTransfer($input: ScheduledTransferInput!) {
    scheduledTransfer(input: $input) {
      ... on PaymentSuccessOutput {
        __typename
        productId
        transactionId
      }
      ... on PaymentErrorOutput {
        __typename
        errorCode
        displayMessage
      }
    }
  }
`;
export type ScheduledTransferMutationFn = Apollo.MutationFunction<
  ScheduledTransferMutation,
  ScheduledTransferMutationVariables
>;

/**
 * __useScheduledTransferMutation__
 *
 * To run a mutation, you first call `useScheduledTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduledTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduledTransferMutation, { data, loading, error }] = useScheduledTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduledTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduledTransferMutation,
    ScheduledTransferMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ScheduledTransferMutation, ScheduledTransferMutationVariables>(
    ScheduledTransferDocument,
    options,
  );
}
export type ScheduledTransferMutationHookResult = ReturnType<typeof useScheduledTransferMutation>;
export type ScheduledTransferMutationResult = Apollo.MutationResult<ScheduledTransferMutation>;
export type ScheduledTransferMutationOptions = Apollo.BaseMutationOptions<
  ScheduledTransferMutation,
  ScheduledTransferMutationVariables
>;
export const SetCardContactlessDocument = gql`
  mutation setCardContactless($input: SetCardContactlessInput!) {
    setCardContactless(input: $input) {
      cardMeta {
        id
        contactless
      }
    }
  }
`;
export type SetCardContactlessMutationFn = Apollo.MutationFunction<
  SetCardContactlessMutation,
  SetCardContactlessMutationVariables
>;

/**
 * __useSetCardContactlessMutation__
 *
 * To run a mutation, you first call `useSetCardContactlessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCardContactlessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCardContactlessMutation, { data, loading, error }] = useSetCardContactlessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCardContactlessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCardContactlessMutation,
    SetCardContactlessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCardContactlessMutation, SetCardContactlessMutationVariables>(
    SetCardContactlessDocument,
    options,
  );
}
export type SetCardContactlessMutationHookResult = ReturnType<typeof useSetCardContactlessMutation>;
export type SetCardContactlessMutationResult = Apollo.MutationResult<SetCardContactlessMutation>;
export type SetCardContactlessMutationOptions = Apollo.BaseMutationOptions<
  SetCardContactlessMutation,
  SetCardContactlessMutationVariables
>;
export const SetCardFrozenDocument = gql`
  mutation setCardFrozen($input: SetCardFrozenInput!) {
    setCardFrozen(input: $input) {
      ... on SetCardFrozenOutput {
        cardMeta {
          id
          frozen
        }
      }
    }
  }
`;
export type SetCardFrozenMutationFn = Apollo.MutationFunction<
  SetCardFrozenMutation,
  SetCardFrozenMutationVariables
>;

/**
 * __useSetCardFrozenMutation__
 *
 * To run a mutation, you first call `useSetCardFrozenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCardFrozenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCardFrozenMutation, { data, loading, error }] = useSetCardFrozenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCardFrozenMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCardFrozenMutation, SetCardFrozenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCardFrozenMutation, SetCardFrozenMutationVariables>(
    SetCardFrozenDocument,
    options,
  );
}
export type SetCardFrozenMutationHookResult = ReturnType<typeof useSetCardFrozenMutation>;
export type SetCardFrozenMutationResult = Apollo.MutationResult<SetCardFrozenMutation>;
export type SetCardFrozenMutationOptions = Apollo.BaseMutationOptions<
  SetCardFrozenMutation,
  SetCardFrozenMutationVariables
>;
export const StepUpInitDocument = gql`
  mutation stepUpInit {
    stepUpInit {
      codeDeliveryType
      expiresIn
    }
  }
`;
export type StepUpInitMutationFn = Apollo.MutationFunction<
  StepUpInitMutation,
  StepUpInitMutationVariables
>;

/**
 * __useStepUpInitMutation__
 *
 * To run a mutation, you first call `useStepUpInitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStepUpInitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stepUpInitMutation, { data, loading, error }] = useStepUpInitMutation({
 *   variables: {
 *   },
 * });
 */
export function useStepUpInitMutation(
  baseOptions?: Apollo.MutationHookOptions<StepUpInitMutation, StepUpInitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StepUpInitMutation, StepUpInitMutationVariables>(
    StepUpInitDocument,
    options,
  );
}
export type StepUpInitMutationHookResult = ReturnType<typeof useStepUpInitMutation>;
export type StepUpInitMutationResult = Apollo.MutationResult<StepUpInitMutation>;
export type StepUpInitMutationOptions = Apollo.BaseMutationOptions<
  StepUpInitMutation,
  StepUpInitMutationVariables
>;
export const StepUpVerifyDocument = gql`
  mutation stepUpVerify($code: String!) {
    stepUpVerify(input: { code: $code }) {
      ... on StepUpVerifySuccessOutput {
        token
        validUntil
      }
      ... on StepUpVerifyErrorOutput {
        error
      }
    }
  }
`;
export type StepUpVerifyMutationFn = Apollo.MutationFunction<
  StepUpVerifyMutation,
  StepUpVerifyMutationVariables
>;

/**
 * __useStepUpVerifyMutation__
 *
 * To run a mutation, you first call `useStepUpVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStepUpVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stepUpVerifyMutation, { data, loading, error }] = useStepUpVerifyMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useStepUpVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<StepUpVerifyMutation, StepUpVerifyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StepUpVerifyMutation, StepUpVerifyMutationVariables>(
    StepUpVerifyDocument,
    options,
  );
}
export type StepUpVerifyMutationHookResult = ReturnType<typeof useStepUpVerifyMutation>;
export type StepUpVerifyMutationResult = Apollo.MutationResult<StepUpVerifyMutation>;
export type StepUpVerifyMutationOptions = Apollo.BaseMutationOptions<
  StepUpVerifyMutation,
  StepUpVerifyMutationVariables
>;
export const TransferDocument = gql`
  mutation transfer($input: TransferInput!) {
    transfer(input: $input) {
      ... on PaymentSuccessOutput {
        __typename
        productId
        transactionId
      }
      ... on PaymentErrorOutput {
        __typename
        errorCode
        displayMessage
      }
    }
  }
`;
export type TransferMutationFn = Apollo.MutationFunction<
  TransferMutation,
  TransferMutationVariables
>;

/**
 * __useTransferMutation__
 *
 * To run a mutation, you first call `useTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferMutation, { data, loading, error }] = useTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferMutation(
  baseOptions?: Apollo.MutationHookOptions<TransferMutation, TransferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TransferMutation, TransferMutationVariables>(TransferDocument, options);
}
export type TransferMutationHookResult = ReturnType<typeof useTransferMutation>;
export type TransferMutationResult = Apollo.MutationResult<TransferMutation>;
export type TransferMutationOptions = Apollo.BaseMutationOptions<
  TransferMutation,
  TransferMutationVariables
>;
export const UpdateBillPaymentReferenceDocument = gql`
  mutation updateBillPaymentReference($billId: ID!, $input: UpdateBillPaymentReferenceInput!) {
    updateBillPaymentReference(billId: $billId, input: $input) {
      id
      status
      linkedTransactions {
        accountId
        referenceId
        amount {
          amount
        }
        paymentType
        scheduledDate
        paymentGateway
      }
    }
  }
`;
export type UpdateBillPaymentReferenceMutationFn = Apollo.MutationFunction<
  UpdateBillPaymentReferenceMutation,
  UpdateBillPaymentReferenceMutationVariables
>;

/**
 * __useUpdateBillPaymentReferenceMutation__
 *
 * To run a mutation, you first call `useUpdateBillPaymentReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillPaymentReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillPaymentReferenceMutation, { data, loading, error }] = useUpdateBillPaymentReferenceMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBillPaymentReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBillPaymentReferenceMutation,
    UpdateBillPaymentReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBillPaymentReferenceMutation,
    UpdateBillPaymentReferenceMutationVariables
  >(UpdateBillPaymentReferenceDocument, options);
}
export type UpdateBillPaymentReferenceMutationHookResult = ReturnType<
  typeof useUpdateBillPaymentReferenceMutation
>;
export type UpdateBillPaymentReferenceMutationResult =
  Apollo.MutationResult<UpdateBillPaymentReferenceMutation>;
export type UpdateBillPaymentReferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateBillPaymentReferenceMutation,
  UpdateBillPaymentReferenceMutationVariables
>;
export const UpdateCardPinDocument = gql`
  mutation updateCardPin($input: UpdateCardPinInput!) {
    updateCardPin(input: $input) {
      ... on UpdateCardPinOutput {
        success
      }
    }
  }
`;
export type UpdateCardPinMutationFn = Apollo.MutationFunction<
  UpdateCardPinMutation,
  UpdateCardPinMutationVariables
>;

/**
 * __useUpdateCardPinMutation__
 *
 * To run a mutation, you first call `useUpdateCardPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardPinMutation, { data, loading, error }] = useUpdateCardPinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardPinMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCardPinMutation, UpdateCardPinMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardPinMutation, UpdateCardPinMutationVariables>(
    UpdateCardPinDocument,
    options,
  );
}
export type UpdateCardPinMutationHookResult = ReturnType<typeof useUpdateCardPinMutation>;
export type UpdateCardPinMutationResult = Apollo.MutationResult<UpdateCardPinMutation>;
export type UpdateCardPinMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardPinMutation,
  UpdateCardPinMutationVariables
>;
export const UpdateDataFeedsDocument = gql`
  mutation updateDataFeeds($input: UpdateDataFeedsInput!) {
    updateDataFeeds(input: $input) {
      success
    }
  }
`;
export type UpdateDataFeedsMutationFn = Apollo.MutationFunction<
  UpdateDataFeedsMutation,
  UpdateDataFeedsMutationVariables
>;

/**
 * __useUpdateDataFeedsMutation__
 *
 * To run a mutation, you first call `useUpdateDataFeedsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataFeedsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataFeedsMutation, { data, loading, error }] = useUpdateDataFeedsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataFeedsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDataFeedsMutation,
    UpdateDataFeedsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDataFeedsMutation, UpdateDataFeedsMutationVariables>(
    UpdateDataFeedsDocument,
    options,
  );
}
export type UpdateDataFeedsMutationHookResult = ReturnType<typeof useUpdateDataFeedsMutation>;
export type UpdateDataFeedsMutationResult = Apollo.MutationResult<UpdateDataFeedsMutation>;
export type UpdateDataFeedsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDataFeedsMutation,
  UpdateDataFeedsMutationVariables
>;
export const VerifyBaCardNumberDocument = gql`
  mutation verifyBACardNumber($input: VerifyBACardNumberInput!) {
    verifyBACardNumber(input: $input) {
      success
    }
  }
`;
export type VerifyBaCardNumberMutationFn = Apollo.MutationFunction<
  VerifyBaCardNumberMutation,
  VerifyBaCardNumberMutationVariables
>;

/**
 * __useVerifyBaCardNumberMutation__
 *
 * To run a mutation, you first call `useVerifyBaCardNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyBaCardNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyBaCardNumberMutation, { data, loading, error }] = useVerifyBaCardNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyBaCardNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyBaCardNumberMutation,
    VerifyBaCardNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyBaCardNumberMutation, VerifyBaCardNumberMutationVariables>(
    VerifyBaCardNumberDocument,
    options,
  );
}
export type VerifyBaCardNumberMutationHookResult = ReturnType<typeof useVerifyBaCardNumberMutation>;
export type VerifyBaCardNumberMutationResult = Apollo.MutationResult<VerifyBaCardNumberMutation>;
export type VerifyBaCardNumberMutationOptions = Apollo.BaseMutationOptions<
  VerifyBaCardNumberMutation,
  VerifyBaCardNumberMutationVariables
>;
export const GetAccountsListDataDocument = gql`
  query getAccountsListData {
    user {
      id
      businessAccount {
        id
        businessAccountDetailId
        balance {
          available {
            amount
            currency
          }
          total {
            amount
            currency
          }
        }
        overdraft {
          overdraftLimit
        }
      }
      smallBusinessLoans {
        id
        type
        displayStatus
        contractStatus
        loanAmount {
          amount
          currency
        }
        outstandingAmount {
          amount
          currency
        }
        hasMissedPayment
        isInArrears
        inArrearsAmount {
          amount
          currency
        }
        daysPastDue
        collectionsPaymentUrl
        isRefinanceEligible
      }
      linesOfCredit {
        id
        status
        displayStatus
        contractStatus
        isInArrears
        inArrearsAmount {
          amount
          currency
        }
        totalAmount {
          amount
          currency
        }
        availableAmount {
          amount
          currency
        }
        pendingAmount {
          amount
          currency
        }
        missedPaymentDate
        missedPayments {
          type
          amount {
            amount
            currency
          }
        }
        outstandingAmount {
          amount
          currency
        }
      }
      contracts {
        id
        status
        productName
        expiryDateTime
      }
    }
  }
`;

/**
 * __useGetAccountsListDataQuery__
 *
 * To run a query within a React component, call `useGetAccountsListDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsListDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsListDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsListDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountsListDataQuery,
    GetAccountsListDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountsListDataQuery, GetAccountsListDataQueryVariables>(
    GetAccountsListDataDocument,
    options,
  );
}
export function useGetAccountsListDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountsListDataQuery,
    GetAccountsListDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountsListDataQuery, GetAccountsListDataQueryVariables>(
    GetAccountsListDataDocument,
    options,
  );
}
export type GetAccountsListDataQueryHookResult = ReturnType<typeof useGetAccountsListDataQuery>;
export type GetAccountsListDataLazyQueryHookResult = ReturnType<
  typeof useGetAccountsListDataLazyQuery
>;
export type GetAccountsListDataQueryResult = Apollo.QueryResult<
  GetAccountsListDataQuery,
  GetAccountsListDataQueryVariables
>;
export const GetAppContextDataDocument = gql`
  query getAppContextData {
    user {
      id
      cdpId
      email
      country
      givenName
      familyName
      phoneNumber
      businessAccount {
        id
        businessAccountDetailId
        abn
        status
        accountName
        accountNumber
        branchNumber
        currency
        homeAddress {
          line1
          townOrCity
          postcode
          administrativeRegion
          country
        }
        overdraft {
          annualServiceRate
          annualInterestRate
          dailyInterestRate
          overdraftTermEndDateUtc
        }
      }
      smallBusinessLoans {
        id
      }
      linesOfCredit {
        id
      }
      contracts {
        id
      }
    }
  }
`;

/**
 * __useGetAppContextDataQuery__
 *
 * To run a query within a React component, call `useGetAppContextDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppContextDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppContextDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppContextDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppContextDataQuery, GetAppContextDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppContextDataQuery, GetAppContextDataQueryVariables>(
    GetAppContextDataDocument,
    options,
  );
}
export function useGetAppContextDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppContextDataQuery,
    GetAppContextDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppContextDataQuery, GetAppContextDataQueryVariables>(
    GetAppContextDataDocument,
    options,
  );
}
export type GetAppContextDataQueryHookResult = ReturnType<typeof useGetAppContextDataQuery>;
export type GetAppContextDataLazyQueryHookResult = ReturnType<typeof useGetAppContextDataLazyQuery>;
export type GetAppContextDataQueryResult = Apollo.QueryResult<
  GetAppContextDataQuery,
  GetAppContextDataQueryVariables
>;
export const GetBaPendingTransactionsDocument = gql`
  query getBAPendingTransactions {
    user {
      id
      businessAccount {
        id
        pendingTransactions {
          id
          amount {
            amount
            currency
          }
          displayName
          transactionTime
          description
          isPending
          reference
        }
      }
    }
  }
`;

/**
 * __useGetBaPendingTransactionsQuery__
 *
 * To run a query within a React component, call `useGetBaPendingTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaPendingTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaPendingTransactionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaPendingTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaPendingTransactionsQuery,
    GetBaPendingTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaPendingTransactionsQuery, GetBaPendingTransactionsQueryVariables>(
    GetBaPendingTransactionsDocument,
    options,
  );
}
export function useGetBaPendingTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaPendingTransactionsQuery,
    GetBaPendingTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaPendingTransactionsQuery, GetBaPendingTransactionsQueryVariables>(
    GetBaPendingTransactionsDocument,
    options,
  );
}
export type GetBaPendingTransactionsQueryHookResult = ReturnType<
  typeof useGetBaPendingTransactionsQuery
>;
export type GetBaPendingTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetBaPendingTransactionsLazyQuery
>;
export type GetBaPendingTransactionsQueryResult = Apollo.QueryResult<
  GetBaPendingTransactionsQuery,
  GetBaPendingTransactionsQueryVariables
>;
export const GetBaSettledTransactionsDocument = gql`
  query getBASettledTransactions($first: PositiveInt!, $after: NonNegativeInt) {
    user {
      id
      businessAccount {
        id
        transactions(first: $first, after: $after) {
          edges {
            node {
              id
              amount {
                amount
                currency
              }
              description
              transactionTime
              displayName
              isPending
              initiatedBy
              reference
            }
            cursor
          }
          pageInfo {
            endCursor
            startCursor
            hasPrevPage
            hasNextPage
          }
        }
      }
    }
  }
`;

/**
 * __useGetBaSettledTransactionsQuery__
 *
 * To run a query within a React component, call `useGetBaSettledTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaSettledTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaSettledTransactionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetBaSettledTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBaSettledTransactionsQuery,
    GetBaSettledTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaSettledTransactionsQuery, GetBaSettledTransactionsQueryVariables>(
    GetBaSettledTransactionsDocument,
    options,
  );
}
export function useGetBaSettledTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaSettledTransactionsQuery,
    GetBaSettledTransactionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaSettledTransactionsQuery, GetBaSettledTransactionsQueryVariables>(
    GetBaSettledTransactionsDocument,
    options,
  );
}
export type GetBaSettledTransactionsQueryHookResult = ReturnType<
  typeof useGetBaSettledTransactionsQuery
>;
export type GetBaSettledTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetBaSettledTransactionsLazyQuery
>;
export type GetBaSettledTransactionsQueryResult = Apollo.QueryResult<
  GetBaSettledTransactionsQuery,
  GetBaSettledTransactionsQueryVariables
>;
export const GetBillDetailsDocument = gql`
  query getBillDetails($id: ID!) {
    user {
      id
      billPay {
        id
        bill(id: $id) {
          ...BillFragment
        }
      }
    }
  }
  ${BillFragmentFragmentDoc}
`;

/**
 * __useGetBillDetailsQuery__
 *
 * To run a query within a React component, call `useGetBillDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBillDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBillDetailsQuery, GetBillDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBillDetailsQuery, GetBillDetailsQueryVariables>(
    GetBillDetailsDocument,
    options,
  );
}
export function useGetBillDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBillDetailsQuery, GetBillDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBillDetailsQuery, GetBillDetailsQueryVariables>(
    GetBillDetailsDocument,
    options,
  );
}
export type GetBillDetailsQueryHookResult = ReturnType<typeof useGetBillDetailsQuery>;
export type GetBillDetailsLazyQueryHookResult = ReturnType<typeof useGetBillDetailsLazyQuery>;
export type GetBillDetailsQueryResult = Apollo.QueryResult<
  GetBillDetailsQuery,
  GetBillDetailsQueryVariables
>;
export const GetBillsDocument = gql`
  query getBills($status: [BillStatus!]!) {
    user {
      id
      billPay {
        id
        dueNextMonthAmount {
          amount
        }
        dueThisMonthAmount {
          amount
        }
        bills(status: $status) {
          id
          status
          uploadDate
          dueDate
          billerName
          billSource {
            senderEmailAddress
          }
          amount {
            amount
          }
          linkedTransactions {
            ...BillTransactionFragment
          }
        }
      }
    }
  }
  ${BillTransactionFragmentFragmentDoc}
`;

/**
 * __useGetBillsQuery__
 *
 * To run a query within a React component, call `useGetBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetBillsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBillsQuery, GetBillsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBillsQuery, GetBillsQueryVariables>(GetBillsDocument, options);
}
export function useGetBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBillsQuery, GetBillsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBillsQuery, GetBillsQueryVariables>(GetBillsDocument, options);
}
export type GetBillsQueryHookResult = ReturnType<typeof useGetBillsQuery>;
export type GetBillsLazyQueryHookResult = ReturnType<typeof useGetBillsLazyQuery>;
export type GetBillsQueryResult = Apollo.QueryResult<GetBillsQuery, GetBillsQueryVariables>;
export const GetBillsEmailDocument = gql`
  query getBillsEmail {
    user {
      id
      billPay {
        id
        uploadEmailAddress
      }
    }
  }
`;

/**
 * __useGetBillsEmailQuery__
 *
 * To run a query within a React component, call `useGetBillsEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillsEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillsEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillsEmailQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBillsEmailQuery, GetBillsEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBillsEmailQuery, GetBillsEmailQueryVariables>(
    GetBillsEmailDocument,
    options,
  );
}
export function useGetBillsEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBillsEmailQuery, GetBillsEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBillsEmailQuery, GetBillsEmailQueryVariables>(
    GetBillsEmailDocument,
    options,
  );
}
export type GetBillsEmailQueryHookResult = ReturnType<typeof useGetBillsEmailQuery>;
export type GetBillsEmailLazyQueryHookResult = ReturnType<typeof useGetBillsEmailLazyQuery>;
export type GetBillsEmailQueryResult = Apollo.QueryResult<
  GetBillsEmailQuery,
  GetBillsEmailQueryVariables
>;
export const BsbLookupDocument = gql`
  query bsbLookup($bsbNumber: String!) {
    bsb(bsbNumber: $bsbNumber) {
      id
      financialInstitutionCode
      city
    }
  }
`;

/**
 * __useBsbLookupQuery__
 *
 * To run a query within a React component, call `useBsbLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBsbLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBsbLookupQuery({
 *   variables: {
 *      bsbNumber: // value for 'bsbNumber'
 *   },
 * });
 */
export function useBsbLookupQuery(
  baseOptions: Apollo.QueryHookOptions<BsbLookupQuery, BsbLookupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BsbLookupQuery, BsbLookupQueryVariables>(BsbLookupDocument, options);
}
export function useBsbLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BsbLookupQuery, BsbLookupQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BsbLookupQuery, BsbLookupQueryVariables>(BsbLookupDocument, options);
}
export type BsbLookupQueryHookResult = ReturnType<typeof useBsbLookupQuery>;
export type BsbLookupLazyQueryHookResult = ReturnType<typeof useBsbLookupLazyQuery>;
export type BsbLookupQueryResult = Apollo.QueryResult<BsbLookupQuery, BsbLookupQueryVariables>;
export const GetBusinessAccountDetailsDocument = gql`
  query getBusinessAccountDetails {
    user {
      id
      businessAccount {
        id
        accountNumber
        branchNumber
        currency
        balance {
          total {
            amount
          }
          available {
            amount
          }
        }
      }
    }
  }
`;

/**
 * __useGetBusinessAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetBusinessAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessAccountDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBusinessAccountDetailsQuery,
    GetBusinessAccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessAccountDetailsQuery, GetBusinessAccountDetailsQueryVariables>(
    GetBusinessAccountDetailsDocument,
    options,
  );
}
export function useGetBusinessAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessAccountDetailsQuery,
    GetBusinessAccountDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBusinessAccountDetailsQuery,
    GetBusinessAccountDetailsQueryVariables
  >(GetBusinessAccountDetailsDocument, options);
}
export type GetBusinessAccountDetailsQueryHookResult = ReturnType<
  typeof useGetBusinessAccountDetailsQuery
>;
export type GetBusinessAccountDetailsLazyQueryHookResult = ReturnType<
  typeof useGetBusinessAccountDetailsLazyQuery
>;
export type GetBusinessAccountDetailsQueryResult = Apollo.QueryResult<
  GetBusinessAccountDetailsQuery,
  GetBusinessAccountDetailsQueryVariables
>;
export const GetBusinessAccountPageDataDocument = gql`
  query getBusinessAccountPageData {
    user {
      id
      businessAccount {
        id
        accountNumber
        branchNumber
        status
        allowOverdraft
        overdraft {
          overdraftLimit
          dailyInterestRate
          annualInterestRate
          overdraftTermEndDateUtc
        }
        balance {
          available {
            amount
          }
          total {
            amount
          }
        }
      }
    }
  }
`;

/**
 * __useGetBusinessAccountPageDataQuery__
 *
 * To run a query within a React component, call `useGetBusinessAccountPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAccountPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAccountPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessAccountPageDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBusinessAccountPageDataQuery,
    GetBusinessAccountPageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessAccountPageDataQuery, GetBusinessAccountPageDataQueryVariables>(
    GetBusinessAccountPageDataDocument,
    options,
  );
}
export function useGetBusinessAccountPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessAccountPageDataQuery,
    GetBusinessAccountPageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBusinessAccountPageDataQuery,
    GetBusinessAccountPageDataQueryVariables
  >(GetBusinessAccountPageDataDocument, options);
}
export type GetBusinessAccountPageDataQueryHookResult = ReturnType<
  typeof useGetBusinessAccountPageDataQuery
>;
export type GetBusinessAccountPageDataLazyQueryHookResult = ReturnType<
  typeof useGetBusinessAccountPageDataLazyQuery
>;
export type GetBusinessAccountPageDataQueryResult = Apollo.QueryResult<
  GetBusinessAccountPageDataQuery,
  GetBusinessAccountPageDataQueryVariables
>;
export const GetBusinessAccountScheduledPaymentsDocument = gql`
  query getBusinessAccountScheduledPayments {
    user {
      id
      businessAccount {
        id
        scheduledTransactions {
          ...ScheduledPaymentActivity
        }
      }
    }
  }
  ${ScheduledPaymentActivityFragmentDoc}
`;

/**
 * __useGetBusinessAccountScheduledPaymentsQuery__
 *
 * To run a query within a React component, call `useGetBusinessAccountScheduledPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessAccountScheduledPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessAccountScheduledPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusinessAccountScheduledPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBusinessAccountScheduledPaymentsQuery,
    GetBusinessAccountScheduledPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBusinessAccountScheduledPaymentsQuery,
    GetBusinessAccountScheduledPaymentsQueryVariables
  >(GetBusinessAccountScheduledPaymentsDocument, options);
}
export function useGetBusinessAccountScheduledPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessAccountScheduledPaymentsQuery,
    GetBusinessAccountScheduledPaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBusinessAccountScheduledPaymentsQuery,
    GetBusinessAccountScheduledPaymentsQueryVariables
  >(GetBusinessAccountScheduledPaymentsDocument, options);
}
export type GetBusinessAccountScheduledPaymentsQueryHookResult = ReturnType<
  typeof useGetBusinessAccountScheduledPaymentsQuery
>;
export type GetBusinessAccountScheduledPaymentsLazyQueryHookResult = ReturnType<
  typeof useGetBusinessAccountScheduledPaymentsLazyQuery
>;
export type GetBusinessAccountScheduledPaymentsQueryResult = Apollo.QueryResult<
  GetBusinessAccountScheduledPaymentsQuery,
  GetBusinessAccountScheduledPaymentsQueryVariables
>;
export const GetCardsMetaDocument = gql`
  query getCardsMeta {
    user {
      id
      businessAccount {
        id
        cards {
          id
          frozen
          contactless
          status
          image
          digitalWalletDetails {
            primaryAccountIdentifier
            wallets {
              type
              reference
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCardsMetaQuery__
 *
 * To run a query within a React component, call `useGetCardsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCardsMetaQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCardsMetaQuery, GetCardsMetaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCardsMetaQuery, GetCardsMetaQueryVariables>(
    GetCardsMetaDocument,
    options,
  );
}
export function useGetCardsMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCardsMetaQuery, GetCardsMetaQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCardsMetaQuery, GetCardsMetaQueryVariables>(
    GetCardsMetaDocument,
    options,
  );
}
export type GetCardsMetaQueryHookResult = ReturnType<typeof useGetCardsMetaQuery>;
export type GetCardsMetaLazyQueryHookResult = ReturnType<typeof useGetCardsMetaLazyQuery>;
export type GetCardsMetaQueryResult = Apollo.QueryResult<
  GetCardsMetaQuery,
  GetCardsMetaQueryVariables
>;
export const GetContractDocument = gql`
  query getContract($contractId: String!) {
    user {
      id
      contract(contractId: $contractId) {
        id
        status
      }
    }
  }
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useGetContractQuery(
  baseOptions: Apollo.QueryHookOptions<GetContractQuery, GetContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, options);
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(
    GetContractDocument,
    options,
  );
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = Apollo.QueryResult<
  GetContractQuery,
  GetContractQueryVariables
>;
export const GetDrawdownDetailsDocument = gql`
  query getDrawdownDetails {
    user {
      id
      linesOfCredit {
        id
        status
        contractStatus
        availableAmount {
          amount
          currency
        }
        maskedLinkedAccountNumber
        drawdown {
          status
          text
        }
      }
    }
  }
`;

/**
 * __useGetDrawdownDetailsQuery__
 *
 * To run a query within a React component, call `useGetDrawdownDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrawdownDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrawdownDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDrawdownDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDrawdownDetailsQuery, GetDrawdownDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDrawdownDetailsQuery, GetDrawdownDetailsQueryVariables>(
    GetDrawdownDetailsDocument,
    options,
  );
}
export function useGetDrawdownDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDrawdownDetailsQuery,
    GetDrawdownDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDrawdownDetailsQuery, GetDrawdownDetailsQueryVariables>(
    GetDrawdownDetailsDocument,
    options,
  );
}
export type GetDrawdownDetailsQueryHookResult = ReturnType<typeof useGetDrawdownDetailsQuery>;
export type GetDrawdownDetailsLazyQueryHookResult = ReturnType<
  typeof useGetDrawdownDetailsLazyQuery
>;
export type GetDrawdownDetailsQueryResult = Apollo.QueryResult<
  GetDrawdownDetailsQuery,
  GetDrawdownDetailsQueryVariables
>;
export const GetIdentityDocument = gql`
  query GetIdentity {
    user {
      id
      email
      phoneNumber
      givenName
    }
  }
`;

/**
 * __useGetIdentityQuery__
 *
 * To run a query within a React component, call `useGetIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdentityQuery(
  baseOptions?: Apollo.QueryHookOptions<GetIdentityQuery, GetIdentityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIdentityQuery, GetIdentityQueryVariables>(GetIdentityDocument, options);
}
export function useGetIdentityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIdentityQuery, GetIdentityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIdentityQuery, GetIdentityQueryVariables>(
    GetIdentityDocument,
    options,
  );
}
export type GetIdentityQueryHookResult = ReturnType<typeof useGetIdentityQuery>;
export type GetIdentityLazyQueryHookResult = ReturnType<typeof useGetIdentityLazyQuery>;
export type GetIdentityQueryResult = Apollo.QueryResult<
  GetIdentityQuery,
  GetIdentityQueryVariables
>;
export const GetIntegrationsDataV2Document = gql`
  query getIntegrationsDataV2($type: IntegrationProduct!) {
    user {
      id
      integrationsV2(type: $type) {
        type
        status
        displayStatus
        products {
          id
          productId
          productType
          syncDateTime
          isSynced
          errorStatus
          displayStatus
          displaySubtitle
        }
      }
    }
  }
`;

/**
 * __useGetIntegrationsDataV2Query__
 *
 * To run a query within a React component, call `useGetIntegrationsDataV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationsDataV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationsDataV2Query({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetIntegrationsDataV2Query(
  baseOptions: Apollo.QueryHookOptions<
    GetIntegrationsDataV2Query,
    GetIntegrationsDataV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIntegrationsDataV2Query, GetIntegrationsDataV2QueryVariables>(
    GetIntegrationsDataV2Document,
    options,
  );
}
export function useGetIntegrationsDataV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationsDataV2Query,
    GetIntegrationsDataV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIntegrationsDataV2Query, GetIntegrationsDataV2QueryVariables>(
    GetIntegrationsDataV2Document,
    options,
  );
}
export type GetIntegrationsDataV2QueryHookResult = ReturnType<typeof useGetIntegrationsDataV2Query>;
export type GetIntegrationsDataV2LazyQueryHookResult = ReturnType<
  typeof useGetIntegrationsDataV2LazyQuery
>;
export type GetIntegrationsDataV2QueryResult = Apollo.QueryResult<
  GetIntegrationsDataV2Query,
  GetIntegrationsDataV2QueryVariables
>;
export const GetLoansStatusesDocument = gql`
  query getLoansStatuses {
    user {
      id
      smallBusinessLoans {
        id
        type
        displayStatus
      }
      linesOfCredit {
        id
        status
      }
    }
  }
`;

/**
 * __useGetLoansStatusesQuery__
 *
 * To run a query within a React component, call `useGetLoansStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoansStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLoansStatusesQuery, GetLoansStatusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoansStatusesQuery, GetLoansStatusesQueryVariables>(
    GetLoansStatusesDocument,
    options,
  );
}
export function useGetLoansStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLoansStatusesQuery, GetLoansStatusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoansStatusesQuery, GetLoansStatusesQueryVariables>(
    GetLoansStatusesDocument,
    options,
  );
}
export type GetLoansStatusesQueryHookResult = ReturnType<typeof useGetLoansStatusesQuery>;
export type GetLoansStatusesLazyQueryHookResult = ReturnType<typeof useGetLoansStatusesLazyQuery>;
export type GetLoansStatusesQueryResult = Apollo.QueryResult<
  GetLoansStatusesQuery,
  GetLoansStatusesQueryVariables
>;
export const GetNavDataDocument = gql`
  query getNavData {
    user {
      id
      linesOfCredit {
        id
        status
        displayStatus
      }
    }
  }
`;

/**
 * __useGetNavDataQuery__
 *
 * To run a query within a React component, call `useGetNavDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNavDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNavDataQuery, GetNavDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNavDataQuery, GetNavDataQueryVariables>(GetNavDataDocument, options);
}
export function useGetNavDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNavDataQuery, GetNavDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNavDataQuery, GetNavDataQueryVariables>(
    GetNavDataDocument,
    options,
  );
}
export type GetNavDataQueryHookResult = ReturnType<typeof useGetNavDataQuery>;
export type GetNavDataLazyQueryHookResult = ReturnType<typeof useGetNavDataLazyQuery>;
export type GetNavDataQueryResult = Apollo.QueryResult<GetNavDataQuery, GetNavDataQueryVariables>;
export const GetPayAnyoneDataDocument = gql`
  query getPayAnyoneData {
    user {
      id
      linesOfCredit {
        id
        status
        availableAmount {
          amount
          currency
        }
        drawdown {
          status
          text
        }
        maskedLinkedAccountNumber
        paymentLimits {
          mfaThreshold {
            amount
            currency
          }
          paymentAvailable {
            amount
            currency
          }
          dailyPaidAmount {
            amount
            currency
          }
        }
      }
      businessAccount {
        id
        status
        branchNumber
        accountNumber
        balance {
          available {
            amount
            currency
          }
          total {
            amount
          }
        }
      }
    }
  }
`;

/**
 * __useGetPayAnyoneDataQuery__
 *
 * To run a query within a React component, call `useGetPayAnyoneDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayAnyoneDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayAnyoneDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPayAnyoneDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPayAnyoneDataQuery, GetPayAnyoneDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPayAnyoneDataQuery, GetPayAnyoneDataQueryVariables>(
    GetPayAnyoneDataDocument,
    options,
  );
}
export function useGetPayAnyoneDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPayAnyoneDataQuery, GetPayAnyoneDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPayAnyoneDataQuery, GetPayAnyoneDataQueryVariables>(
    GetPayAnyoneDataDocument,
    options,
  );
}
export type GetPayAnyoneDataQueryHookResult = ReturnType<typeof useGetPayAnyoneDataQuery>;
export type GetPayAnyoneDataLazyQueryHookResult = ReturnType<typeof useGetPayAnyoneDataLazyQuery>;
export type GetPayAnyoneDataQueryResult = Apollo.QueryResult<
  GetPayAnyoneDataQuery,
  GetPayAnyoneDataQueryVariables
>;
export const GetProductCvpPageDataDocument = gql`
  query getProductCvpPageData {
    user {
      id
      productOriginationStatuses {
        id
        deferralPeriodEndDate
        businessAccount {
          id
          canApply
          reason
        }
        lineOfCredit {
          id
          canApply
          reason
        }
        smallBusinessLoan {
          id
          canApply
          reason
        }
        businessLoanPlus {
          id
          canApply
          reason
        }
      }
    }
  }
`;

/**
 * __useGetProductCvpPageDataQuery__
 *
 * To run a query within a React component, call `useGetProductCvpPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductCvpPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductCvpPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductCvpPageDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductCvpPageDataQuery,
    GetProductCvpPageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductCvpPageDataQuery, GetProductCvpPageDataQueryVariables>(
    GetProductCvpPageDataDocument,
    options,
  );
}
export function useGetProductCvpPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductCvpPageDataQuery,
    GetProductCvpPageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductCvpPageDataQuery, GetProductCvpPageDataQueryVariables>(
    GetProductCvpPageDataDocument,
    options,
  );
}
export type GetProductCvpPageDataQueryHookResult = ReturnType<typeof useGetProductCvpPageDataQuery>;
export type GetProductCvpPageDataLazyQueryHookResult = ReturnType<
  typeof useGetProductCvpPageDataLazyQuery
>;
export type GetProductCvpPageDataQueryResult = Apollo.QueryResult<
  GetProductCvpPageDataQuery,
  GetProductCvpPageDataQueryVariables
>;
export const GetRepaymentCalculatorDataDocument = gql`
  query getRepaymentCalculatorData {
    user {
      id
      repaymentCalculator {
        id
        smallBusinessLoanRate
        lineOfCreditRate
      }
    }
  }
`;

/**
 * __useGetRepaymentCalculatorDataQuery__
 *
 * To run a query within a React component, call `useGetRepaymentCalculatorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepaymentCalculatorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepaymentCalculatorDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRepaymentCalculatorDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRepaymentCalculatorDataQuery,
    GetRepaymentCalculatorDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRepaymentCalculatorDataQuery, GetRepaymentCalculatorDataQueryVariables>(
    GetRepaymentCalculatorDataDocument,
    options,
  );
}
export function useGetRepaymentCalculatorDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRepaymentCalculatorDataQuery,
    GetRepaymentCalculatorDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRepaymentCalculatorDataQuery,
    GetRepaymentCalculatorDataQueryVariables
  >(GetRepaymentCalculatorDataDocument, options);
}
export type GetRepaymentCalculatorDataQueryHookResult = ReturnType<
  typeof useGetRepaymentCalculatorDataQuery
>;
export type GetRepaymentCalculatorDataLazyQueryHookResult = ReturnType<
  typeof useGetRepaymentCalculatorDataLazyQuery
>;
export type GetRepaymentCalculatorDataQueryResult = Apollo.QueryResult<
  GetRepaymentCalculatorDataQuery,
  GetRepaymentCalculatorDataQueryVariables
>;
export const GetSmallBusinessLoansDataDocument = gql`
  query getSmallBusinessLoansData {
    user {
      id
      smallBusinessLoans {
        id
        type
        displayStatus
        countryCode
        isRefinanceEligible
        hasMissedPayment
        isInArrears
        inArrearsAmount {
          amount
          currency
        }
        daysPastDue
        collectionsPaymentUrl
      }
    }
  }
`;

/**
 * __useGetSmallBusinessLoansDataQuery__
 *
 * To run a query within a React component, call `useGetSmallBusinessLoansDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmallBusinessLoansDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmallBusinessLoansDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSmallBusinessLoansDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSmallBusinessLoansDataQuery,
    GetSmallBusinessLoansDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSmallBusinessLoansDataQuery, GetSmallBusinessLoansDataQueryVariables>(
    GetSmallBusinessLoansDataDocument,
    options,
  );
}
export function useGetSmallBusinessLoansDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSmallBusinessLoansDataQuery,
    GetSmallBusinessLoansDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSmallBusinessLoansDataQuery,
    GetSmallBusinessLoansDataQueryVariables
  >(GetSmallBusinessLoansDataDocument, options);
}
export type GetSmallBusinessLoansDataQueryHookResult = ReturnType<
  typeof useGetSmallBusinessLoansDataQuery
>;
export type GetSmallBusinessLoansDataLazyQueryHookResult = ReturnType<
  typeof useGetSmallBusinessLoansDataLazyQuery
>;
export type GetSmallBusinessLoansDataQueryResult = Apollo.QueryResult<
  GetSmallBusinessLoansDataQuery,
  GetSmallBusinessLoansDataQueryVariables
>;
export const GetStatementDocument = gql`
  query getStatement(
    $id: ID
    $fileType: StatementFileType!
    $endDate: Date!
    $startDate: Date!
    $type: StatementProductType!
    $statementType: StatementType
  ) {
    user {
      id
      statement(
        id: $id
        fileType: $fileType
        endDate: $endDate
        startDate: $startDate
        type: $type
        statementType: $statementType
      ) {
        url
        filename
      }
    }
  }
`;

/**
 * __useGetStatementQuery__
 *
 * To run a query within a React component, call `useGetStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fileType: // value for 'fileType'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      type: // value for 'type'
 *      statementType: // value for 'statementType'
 *   },
 * });
 */
export function useGetStatementQuery(
  baseOptions: Apollo.QueryHookOptions<GetStatementQuery, GetStatementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatementQuery, GetStatementQueryVariables>(
    GetStatementDocument,
    options,
  );
}
export function useGetStatementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatementQuery, GetStatementQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatementQuery, GetStatementQueryVariables>(
    GetStatementDocument,
    options,
  );
}
export type GetStatementQueryHookResult = ReturnType<typeof useGetStatementQuery>;
export type GetStatementLazyQueryHookResult = ReturnType<typeof useGetStatementLazyQuery>;
export type GetStatementQueryResult = Apollo.QueryResult<
  GetStatementQuery,
  GetStatementQueryVariables
>;
export const GetStatementsByProductDocument = gql`
  query getStatementsByProduct($productType: StatementProductType, $productId: String) {
    user {
      id
      statements(productType: $productType, productId: $productId) {
        id
        monthly {
          id
          title
          productId
          startDate
          endDate
        }
        yearly {
          id
          title
          productId
          startDate
          endDate
        }
        customDateRange {
          min
          max
        }
      }
    }
  }
`;

/**
 * __useGetStatementsByProductQuery__
 *
 * To run a query within a React component, call `useGetStatementsByProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementsByProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementsByProductQuery({
 *   variables: {
 *      productType: // value for 'productType'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetStatementsByProductQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStatementsByProductQuery,
    GetStatementsByProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatementsByProductQuery, GetStatementsByProductQueryVariables>(
    GetStatementsByProductDocument,
    options,
  );
}
export function useGetStatementsByProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatementsByProductQuery,
    GetStatementsByProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatementsByProductQuery, GetStatementsByProductQueryVariables>(
    GetStatementsByProductDocument,
    options,
  );
}
export type GetStatementsByProductQueryHookResult = ReturnType<
  typeof useGetStatementsByProductQuery
>;
export type GetStatementsByProductLazyQueryHookResult = ReturnType<
  typeof useGetStatementsByProductLazyQuery
>;
export type GetStatementsByProductQueryResult = Apollo.QueryResult<
  GetStatementsByProductQuery,
  GetStatementsByProductQueryVariables
>;
export const GetStatementsSummaryDocument = gql`
  query getStatementsSummary {
    user {
      id
      businessAccount {
        balance {
          total {
            amount
          }
        }
      }
      statements {
        id
        productId
        productType
        displayTitle
        displaySubtitle
        displayStatus
        amount {
          amount
        }
        amountLabel
      }
    }
  }
`;

/**
 * __useGetStatementsSummaryQuery__
 *
 * To run a query within a React component, call `useGetStatementsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatementsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatementsSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatementsSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStatementsSummaryQuery,
    GetStatementsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatementsSummaryQuery, GetStatementsSummaryQueryVariables>(
    GetStatementsSummaryDocument,
    options,
  );
}
export function useGetStatementsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatementsSummaryQuery,
    GetStatementsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatementsSummaryQuery, GetStatementsSummaryQueryVariables>(
    GetStatementsSummaryDocument,
    options,
  );
}
export type GetStatementsSummaryQueryHookResult = ReturnType<typeof useGetStatementsSummaryQuery>;
export type GetStatementsSummaryLazyQueryHookResult = ReturnType<
  typeof useGetStatementsSummaryLazyQuery
>;
export type GetStatementsSummaryQueryResult = Apollo.QueryResult<
  GetStatementsSummaryQuery,
  GetStatementsSummaryQueryVariables
>;
export const GetUpcomingRepaymentDocument = gql`
  query getUpcomingRepayment(
    $id: ID!
    $additionalAmount: Decimal!
    $productType: UpcomingRepaymentProductType!
  ) {
    user {
      id
      upcomingRepayment(id: $id, additionalAmount: $additionalAmount, productType: $productType) {
        id
        totalRepaymentCharge {
          amount
          currency
        }
        nextRepaymentDate
      }
    }
  }
`;

/**
 * __useGetUpcomingRepaymentQuery__
 *
 * To run a query within a React component, call `useGetUpcomingRepaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingRepaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingRepaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      additionalAmount: // value for 'additionalAmount'
 *      productType: // value for 'productType'
 *   },
 * });
 */
export function useGetUpcomingRepaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpcomingRepaymentQuery,
    GetUpcomingRepaymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUpcomingRepaymentQuery, GetUpcomingRepaymentQueryVariables>(
    GetUpcomingRepaymentDocument,
    options,
  );
}
export function useGetUpcomingRepaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingRepaymentQuery,
    GetUpcomingRepaymentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUpcomingRepaymentQuery, GetUpcomingRepaymentQueryVariables>(
    GetUpcomingRepaymentDocument,
    options,
  );
}
export type GetUpcomingRepaymentQueryHookResult = ReturnType<typeof useGetUpcomingRepaymentQuery>;
export type GetUpcomingRepaymentLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingRepaymentLazyQuery
>;
export type GetUpcomingRepaymentQueryResult = Apollo.QueryResult<
  GetUpcomingRepaymentQuery,
  GetUpcomingRepaymentQueryVariables
>;
export const GetUserCdpIdDocument = gql`
  query getUserCdpId {
    user {
      id
      cdpId
    }
  }
`;

/**
 * __useGetUserCdpIdQuery__
 *
 * To run a query within a React component, call `useGetUserCdpIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCdpIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCdpIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserCdpIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserCdpIdQuery, GetUserCdpIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserCdpIdQuery, GetUserCdpIdQueryVariables>(
    GetUserCdpIdDocument,
    options,
  );
}
export function useGetUserCdpIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserCdpIdQuery, GetUserCdpIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserCdpIdQuery, GetUserCdpIdQueryVariables>(
    GetUserCdpIdDocument,
    options,
  );
}
export type GetUserCdpIdQueryHookResult = ReturnType<typeof useGetUserCdpIdQuery>;
export type GetUserCdpIdLazyQueryHookResult = ReturnType<typeof useGetUserCdpIdLazyQuery>;
export type GetUserCdpIdQueryResult = Apollo.QueryResult<
  GetUserCdpIdQuery,
  GetUserCdpIdQueryVariables
>;
export const GetUserPayeesV2Document = gql`
  query getUserPayeesV2($paymentTypes: [PaymentType!], $countryCode: Country) {
    user {
      id
      payees(countryCode: $countryCode, paymentTypes: $paymentTypes) {
        ...BAPayeeFragment
        ...BankPayeeV2Fragment
        ...BpayPayeeV2Fragment
      }
    }
  }
  ${BaPayeeFragmentFragmentDoc}
  ${BankPayeeV2FragmentFragmentDoc}
  ${BpayPayeeV2FragmentFragmentDoc}
`;

/**
 * __useGetUserPayeesV2Query__
 *
 * To run a query within a React component, call `useGetUserPayeesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPayeesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPayeesV2Query({
 *   variables: {
 *      paymentTypes: // value for 'paymentTypes'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetUserPayeesV2Query(
  baseOptions?: Apollo.QueryHookOptions<GetUserPayeesV2Query, GetUserPayeesV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPayeesV2Query, GetUserPayeesV2QueryVariables>(
    GetUserPayeesV2Document,
    options,
  );
}
export function useGetUserPayeesV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserPayeesV2Query, GetUserPayeesV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserPayeesV2Query, GetUserPayeesV2QueryVariables>(
    GetUserPayeesV2Document,
    options,
  );
}
export type GetUserPayeesV2QueryHookResult = ReturnType<typeof useGetUserPayeesV2Query>;
export type GetUserPayeesV2LazyQueryHookResult = ReturnType<typeof useGetUserPayeesV2LazyQuery>;
export type GetUserPayeesV2QueryResult = Apollo.QueryResult<
  GetUserPayeesV2Query,
  GetUserPayeesV2QueryVariables
>;
export const VerifyNzAccountNumberDocument = gql`
  query verifyNZAccountNumber($accountDetails: VerifyAccountDetails!, $accountType: AccountType!) {
    verifyAccountNumber(accountDetails: $accountDetails, accountType: $accountType) {
      isValid
    }
  }
`;

/**
 * __useVerifyNzAccountNumberQuery__
 *
 * To run a query within a React component, call `useVerifyNzAccountNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyNzAccountNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyNzAccountNumberQuery({
 *   variables: {
 *      accountDetails: // value for 'accountDetails'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useVerifyNzAccountNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    VerifyNzAccountNumberQuery,
    VerifyNzAccountNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyNzAccountNumberQuery, VerifyNzAccountNumberQueryVariables>(
    VerifyNzAccountNumberDocument,
    options,
  );
}
export function useVerifyNzAccountNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyNzAccountNumberQuery,
    VerifyNzAccountNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerifyNzAccountNumberQuery, VerifyNzAccountNumberQueryVariables>(
    VerifyNzAccountNumberDocument,
    options,
  );
}
export type VerifyNzAccountNumberQueryHookResult = ReturnType<typeof useVerifyNzAccountNumberQuery>;
export type VerifyNzAccountNumberLazyQueryHookResult = ReturnType<
  typeof useVerifyNzAccountNumberLazyQuery
>;
export type VerifyNzAccountNumberQueryResult = Apollo.QueryResult<
  VerifyNzAccountNumberQuery,
  VerifyNzAccountNumberQueryVariables
>;
