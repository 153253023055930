import { type ApolloCache, type MutationTuple } from '@apollo/client';
import { formatDistanceStrict } from 'date-fns';
import numeral from 'numeral';
import { type PartialDeep } from 'type-fest';

import {
  BusinessAccountSchedulePaymentType,
  BusinessAccountScheduledPaymentFrequency,
  BusinessAccountScheduledPaymentStatus,
  DrawdownStatus,
  type GetPayAnyoneDataQuery,
  type GetProofOfTransferMutation,
  type GetProofOfTransferMutationVariables,
  type LineOfCredit,
  LineOfCreditStatus,
} from '@generated-fg';
import {
  type Charge,
  type CreditLine,
  type GetLocPaymentsAndTransactionsQuery,
  LineStatusType,
  PaymentFrequencyType,
} from '@generated-mg';

import {
  TransactionDisbursalDetails,
  TransactionRepaymentDetails,
  TransactionTimeAndProofDetails,
} from '@components';
import {
  type LoanDetailsProps,
  type RepaymentProgressProps,
  type ScheduledPayments,
  type TAccruedDetail,
  type TransactionRecord,
  TransactionSign,
} from '@components/types';

import {
  LOAN_DETAILS,
  LineActivityType,
  NUMBER_OF_WEEKS_PER_YEAR,
  locContractStatusesCanPayAndDrawdown,
} from '@constants';
import { type AccountItem, ProductTypes } from '@models';
import {
  currencyFormatter,
  formatDateInDayMonthYear,
  getAccountItem,
  isPayAnyoneAccountOptionDisabled,
} from '@utils';

import { type ClientCacheType } from '../apolloClient';

const LOCM_PREFIXES = ['LCNZ', 'LCAU'];
export const FEMG_LATE_PAY_FEE = 'Late Payment Fee';
export const ACCRUED_INTEREST = 'Accrued interest*';

export function isLOCMambu(id: string): boolean {
  if (!id) return false;

  for (const prefix of LOCM_PREFIXES) {
    if (id.search(prefix) !== -1) return true;
  }

  return false;
}

export function isNZLOCMambu(id: string): boolean {
  if (!id) return false;

  if (id.search(LOCM_PREFIXES[0]) !== -1) return true;

  return false;
}
export function isAULOCMambu(id: string): boolean {
  if (!id) return false;

  if (id.search(LOCM_PREFIXES[1]) !== -1) return true;

  return false;
}

export const totalCharges = (charges?: Partial<Charge>[]) => {
  if (charges) {
    return charges.filter(item => item.value).reduce((sum, current) => sum + current.value, 0);
  }

  return 0;
};

const getWeeklySubscriptionPercentage = (subscriptionPercentage: number): string => {
  return `${numeral(subscriptionPercentage / NUMBER_OF_WEEKS_PER_YEAR).format('0.000')}%`;
};

const getWeeklyServiceFeeDescription = (subscriptionPercentage: number): string => {
  return `Based on ${getWeeklySubscriptionPercentage(
    subscriptionPercentage,
  )} of your facility limit`;
};

export const locDetailsFormatter = ({
  upcomingPayment,
  totalUpcomingRepaymentAmount,
  total,
  startDate,
  endDate,
  subscriptionFeePercentage,
  id,
  endOfTermDetails,
  status,
}: PartialDeep<CreditLine>): LoanDetailsProps['loanDetails'] => {
  const isMambu = isLOCMambu(id);
  const hasUpcomingPayment = !!upcomingPayment && upcomingPayment.length > 0;

  const upcomingPaymentSection = isMambu
    ? [
        {
          label: LOAN_DETAILS.UPCOMING_REPAYMENT,
          value: currencyFormatter(totalUpcomingRepaymentAmount),
          comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
        },
      ]
    : [
        {
          label: LOAN_DETAILS.UPCOMING_REPAYMENT,
          value: currencyFormatter(totalUpcomingRepaymentAmount),
          comment: LOAN_DETAILS.INCLUDES_ALL_FEES,
          subsection: hasUpcomingPayment
            ? {
                title: LOAN_DETAILS.REPAYMENT_BREAKDOWN,
                items: [
                  upcomingPayment
                    .filter(item => !!item.value)
                    .map(item => ({
                      label: item.name,
                      value: currencyFormatter(item.value),
                      comment:
                        item.name === LOAN_DETAILS.WEEKLY_SERVICE_FEE
                          ? getWeeklyServiceFeeDescription(subscriptionFeePercentage)
                          : null,
                    })),
                ],
              }
            : null,
        },
      ];

  const { paymentPlanDetails, payoutDetails } = endOfTermDetails;

  const hasPaymentPlanDetails =
    status === LineStatusType.PaymentPlan && paymentPlanDetails?.numberOfWeeks;

  const maturityDate = isMambu
    ? paymentPlanDetails?.maturityDate
    : payoutDetails?.payoutAmountDueDate;

  const paymentPlanSection = hasPaymentPlanDetails
    ? [
        {
          label: LOAN_DETAILS.PAYMENT_PLAN,
          value: `${paymentPlanDetails?.numberOfWeeks} week${
            paymentPlanDetails?.numberOfWeeks > 1 ? 's' : ''
          } ${maturityDate ? `until ${formatDateInDayMonthYear(maturityDate)}` : ''} `,
        },
      ]
    : [];

  const locBasicDetailsSection = [
    {
      label: LOAN_DETAILS.FACILITY_LIMIT,
      value: total ? currencyFormatter(total) : null,
    },
    {
      label: LOAN_DETAILS.START_DATE,
      value: startDate ? formatDateInDayMonthYear(startDate) : null,
    },
    {
      label: LOAN_DETAILS.INITIAL_TERM,
      value:
        startDate && endDate
          ? formatDistanceStrict(new Date(endDate), new Date(startDate), {
              unit: 'month',
              roundingMethod: 'round',
            })
          : null,
    },
  ];
  return [[...paymentPlanSection, ...upcomingPaymentSection, ...locBasicDetailsSection]];
};

export const LOC_REPAYMENT_PROGRESS_BAR_LABELS = {
  BALANCE: 'Balance',
  AVAILABLE: 'Available',
  REPAID: 'Repaid',
  OUTSTANDING: 'Outstanding',
};

export const locRepaymentProgressFormatter = ({
  creditLine,
  getLocDetailsLoading,
}: {
  creditLine: Pick<
    CreditLine,
    | 'id'
    | 'available'
    | 'endOfTermDetails'
    | 'status'
    | 'total'
    | 'used'
    | 'outstandingBalance'
    | 'pendingAmount'
    | 'totalUpcomingRepaymentAmount'
  >;
  getLocDetailsLoading: boolean;
}): RepaymentProgressProps => {
  const repaymentLoading = getLocDetailsLoading;
  const {
    pendingAmount,
    available,
    status,
    total,
    used,
    endOfTermDetails = {},
    outstandingBalance = 0,
  } = creditLine || {};
  if (repaymentLoading) {
    return {
      rightLabel: null,
      leftLabel: null,
      percentage: 0,
      repaymentLoading,
    };
  }
  const isMambu = isLOCMambu(creditLine.id);
  switch (status) {
    case LineStatusType.PaymentPlan: {
      const { paymentPlanDetails, payoutDetails } = endOfTermDetails;
      const totalOutstandingBalance = isMambu ? outstandingBalance : payoutDetails?.payoutAmount;
      // NOTES: we need to reconsider the repaid amount for cloud lending locs
      const totalRepaidAmount = isMambu ? paymentPlanDetails?.repaidAmount : 0;
      // NOTES: after add the repaid amount for cloud lending locs, we can align the display for both mambu and cloud lending locs
      if (isMambu) {
        return {
          rightLabel: {
            title: LOC_REPAYMENT_PROGRESS_BAR_LABELS.OUTSTANDING,
            value: currencyFormatter(totalOutstandingBalance),
          },
          leftLabel: {
            title: LOC_REPAYMENT_PROGRESS_BAR_LABELS.REPAID,
            value: currencyFormatter(totalRepaidAmount),
          },
          leftSubLabel: null,
          percentage: (totalRepaidAmount / totalOutstandingBalance) * 100,
          repaymentLoading,
        };
      }

      return {
        rightLabel: null,
        leftLabel: {
          title: LOC_REPAYMENT_PROGRESS_BAR_LABELS.OUTSTANDING,
          value: currencyFormatter(totalOutstandingBalance),
        },
        leftSubLabel: pendingAmount > 0 ? `Pending: ${currencyFormatter(pendingAmount)}` : null,
        percentage: null,
        repaymentLoading,
      };
    }
    case LineStatusType.WrittenOff:
      return {
        rightLabel: null,
        leftLabel: {
          title: LOC_REPAYMENT_PROGRESS_BAR_LABELS.OUTSTANDING,
          value: currencyFormatter(outstandingBalance),
        },
        percentage: null,
        repaymentLoading,
      };

    default:
      return {
        rightLabel:
          available !== total
            ? {
                title: LOC_REPAYMENT_PROGRESS_BAR_LABELS.BALANCE,
                value: currencyFormatter(-(used || 0)),
              }
            : null,
        leftLabel: {
          title: LOC_REPAYMENT_PROGRESS_BAR_LABELS.AVAILABLE,
          value: currencyFormatter(available),
        },
        percentage: (available / total) * 100,
        repaymentLoading,
      };
  }
};

// - only mambu has this sign
const isCredit = (lineActivityType: string) =>
  [
    LineActivityType.FundsRequestSuccess,
    LineActivityType.FundsRequestPending,
    LineActivityType.InternalTransferCredit,
  ].includes(lineActivityType as LineActivityType);

// + only mambu has this sign
const isDebit = (lineActivityType: string) =>
  [
    LineActivityType.ScheduledPaymentSuccess,
    LineActivityType.ScheduledPaymentPending,
    LineActivityType.LoanPaymentSuccess,
    LineActivityType.LoanPaymentPending,
    LineActivityType.InternalTransferDebit,
  ].includes(lineActivityType as LineActivityType);

export const getLocMambuTransactionSign = (lineActivityType: string) => {
  switch (!!lineActivityType) {
    case isCredit(lineActivityType):
      return TransactionSign.Negative;
    case isDebit(lineActivityType):
      return TransactionSign.Positive;
    default:
      return TransactionSign.Neutral;
  }
};

export const mapScheduledPaymentFrequency = (
  paymentFrequencyType: PaymentFrequencyType,
): BusinessAccountScheduledPaymentFrequency => {
  switch (paymentFrequencyType) {
    case PaymentFrequencyType.Fortnightly:
      return BusinessAccountScheduledPaymentFrequency.Fortnightly;
    case PaymentFrequencyType.Monthly:
      return BusinessAccountScheduledPaymentFrequency.Monthly;
    case PaymentFrequencyType.Weekly:
      return BusinessAccountScheduledPaymentFrequency.Weekly;
    default:
      return null;
  }
};
export const mapScheduledPaymentType = (frequencyType: PaymentFrequencyType) => {
  return frequencyType === PaymentFrequencyType.Once || frequencyType === PaymentFrequencyType.Now
    ? BusinessAccountSchedulePaymentType.OneTime
    : BusinessAccountSchedulePaymentType.Recurring;
};

export const mapLocGqlTransactions = (
  locId: string,
  transactionData: GetLocPaymentsAndTransactionsQuery['creditLine']['transactions']['itemList'],
  getProofOfTransfer: MutationTuple<
    GetProofOfTransferMutation,
    GetProofOfTransferMutationVariables
  >[0],
  getProofOfTransferLoading: boolean,
): TransactionRecord[] => {
  return transactionData
    ?.filter(item => item?.data)
    .map(({ id, data: lineActivity }) => {
      const {
        amount,
        description,
        timestamp,
        title,
        lineActivityType,
        subComponents,
        transactionKey,
      } = lineActivity;

      const showProof =
        // Proof of transfer is only available for Mambu NZ Loc now.
        isNZLOCMambu(locId) &&
        (lineActivity.lineActivityType === LineActivityType.FundsRequestPending ||
          lineActivity.lineActivityType === LineActivityType.FundsRequestSuccess);

      const getProof = async () => {
        const res = await getProofOfTransfer({
          variables: { input: { transactionKey, applicationId: locId } },
        });
        return { url: res.data?.proofOfTransfer?.url };
      };

      const additionalDetails = (
        <li key={`${lineActivity.id}-additionalDetails`}>
          <TransactionRepaymentDetails
            lineActivityId={lineActivity.id}
            subComponents={subComponents}
            locId={locId}
          />
          <TransactionDisbursalDetails lineActivity={lineActivity} locId={locId} />
          <TransactionTimeAndProofDetails
            timestamp={timestamp}
            getProofOfTransfer={showProof && getProof}
            getProofOfTransferLoading={showProof && getProofOfTransferLoading}
          />
        </li>
      );

      return {
        id,
        amount: amount,
        description: description,
        date: timestamp,
        displayName: title,
        pending: false,
        dateGroup: timestamp,
        isFailure: false,
        sign: isLOCMambu(locId)
          ? getLocMambuTransactionSign(lineActivityType)
          : TransactionSign.Neutral,
        additionalDetails,
      };
    });
};

export const mapLocGqlScheduledPayments = (
  paymentsData: GetLocPaymentsAndTransactionsQuery['creditLine']['payments']['itemList'],
  billReferenceIdsMap: Map<string, string>,
): ScheduledPayments => {
  return paymentsData
    ?.filter(item => item?.data)
    .map(({ id, data: payment }) => {
      const { amount, description, title, startDate, endDate, frequencyType, bankAccount } =
        payment;

      return {
        id,
        startDate,
        endDate,
        amount: {
          amount,
        },
        description: description,
        type: mapScheduledPaymentType(frequencyType),
        frequency: mapScheduledPaymentFrequency(frequencyType),
        status: BusinessAccountScheduledPaymentStatus.Active,
        details: {
          name: title,
          bsb: bankAccount?.bSB || '',
          accountNumber: bankAccount?.bankAccountNumber || '',
        },
        billId: billReferenceIdsMap.get(id),
      };
    })
    .reverse();
};

export const getAccountAccruedDetail = (currentPayment?: Charge[]): TAccruedDetail[] => {
  const accruedDetail: TAccruedDetail[] =
    currentPayment?.map(({ name, value }: Charge): TAccruedDetail => {
      return {
        label: name === FEMG_LATE_PAY_FEE ? ACCRUED_INTEREST : name,
        value,
      };
    }) || [];

  return accruedDetail;
};

// NOTE: copy from the frontend gateway
// apps/gateway-api/src/utils/Loans.ts (line 349 getDrawdownStatus)
export const isLineOfCreditDeferred = ({
  status,
  canDrawdown,
}: Pick<CreditLine, 'status' | 'canDrawdown'>): boolean => {
  return status === LineStatusType.LineActive && !canDrawdown;
};

export const isLineOfCreditLocked = ({
  status,
  canDrawdown,
  isLockedForRefinance,
}: Pick<CreditLine, 'status' | 'canDrawdown' | 'isLockedForRefinance'>): boolean => {
  return status === LineStatusType.LineActive && canDrawdown && isLockedForRefinance;
};

export const isLoCActive = (loc: GetPayAnyoneDataQuery['user']['linesOfCredit'][number]) => {
  return loc?.drawdown.status === DrawdownStatus.Active;
};

export const isLOCValid = (loc: GetPayAnyoneDataQuery['user']['linesOfCredit'][number]) => {
  return (
    loc?.status === LineOfCreditStatus.LineActive ||
    loc?.status === LineOfCreditStatus.WrittenOff ||
    loc?.status === LineOfCreditStatus.LineSuspended ||
    loc?.drawdown?.status === DrawdownStatus.Locked ||
    loc?.drawdown?.status === DrawdownStatus.Deferred
  );
};

export const evictLocRelatedCache = ({
  productId,
  cache,
}: {
  productId: string;
  cache: ApolloCache<ClientCacheType | object>;
}): void => {
  cache.evict({
    id: `LineOfCredit:${productId}`,
  });
  cache.evict({
    id: `CreditLine:${productId}`,
  });
};

export const locToLocAccountItem = (loc: PartialDeep<LineOfCredit>): AccountItem => {
  const locProductType: ProductTypes = isLOCMambu(loc?.id) ? ProductTypes.LOCM : ProductTypes.LOC;
  return getAccountItem(locProductType, loc, account => isPayAnyoneAccountOptionDisabled(account));
};

// As per https://prospa.atlassian.net/wiki/x/XQDytg.
// Note that the an LoC which is end of term returns a contract status as active.
export const canLocPayAndDrawdown = (loc: PartialDeep<LineOfCredit>): boolean => {
  return loc?.contractStatus && locContractStatusesCanPayAndDrawdown.has(loc?.contractStatus);
};
