import { DLS_TYPES, PRODUCT_MODALS_ALERT_TEXT } from '@constants';
import { externalRedirect, formatDateInDayMonthYear } from '@utils';

import { ProductOriginationRejectReason } from '../../../generated/fe-gateway';
import environments from '../../environments';
import { ProductCVPType } from '../../models';

const { businessAccountOnboarding, cxApplicationWeb } = environments;

type OpenProductUrl = (
  countryCode: string,
  token: string,
  dls: (typeof DLS_TYPES)[keyof typeof DLS_TYPES],
  product: ProductCVPType | ProductCVPType,
  cdpId?: string,
) => Window;

type BAOnboardingParams = {
  flow: string;
  dls: string;
  cdpId?: string;
};

type CXApplicationParams = {
  flow: string;
  dls: string;
  token: string;
  product: ProductCVPType;
  cdpId?: string;
};

export const buildBAOnboardingProductUrl = (dls: string, cdpId?: string) => {
  const params: BAOnboardingParams = { flow: 'aio', dls, ...(cdpId && { cdpId }) };
  const searchParams = new URLSearchParams(params);
  return `${businessAccountOnboarding.baseUrl}/welcome?${searchParams.toString()}`;
};

export const buildCxApplicationProductUrl = (
  countryCode: string,
  token: string,
  dls: string,
  product: ProductCVPType,
  cdpId?: string,
) => {
  const params: CXApplicationParams = {
    token,
    flow: 'aio',
    dls,
    product,
    ...(cdpId && { cdpId }),
  };
  const searchParams = new URLSearchParams(params);
  return `${cxApplicationWeb[countryCode].baseUrl}/qualification?${searchParams.toString()}`;
};

export const openProductUrl: OpenProductUrl = (countryCode, token, dls, product, cdpId) => {
  const url =
    product === ProductCVPType.BA
      ? buildBAOnboardingProductUrl(dls, cdpId)
      : buildCxApplicationProductUrl(countryCode, token, dls, product, cdpId);

  return externalRedirect({ openInNewTab: false, url });
};

export const getAlertText = ({
  deferralPeriodEndDate,
  reason,
}: {
  deferralPeriodEndDate: string;
  reason: ProductOriginationRejectReason;
}) => {
  switch (reason) {
    case ProductOriginationRejectReason.BlockedOrFrozenBa:
      return PRODUCT_MODALS_ALERT_TEXT.BA_BLOCKED_OR_FROZEN;
    case ProductOriginationRejectReason.SuspendedLoc:
      return PRODUCT_MODALS_ALERT_TEXT.LOC_SUSPENDED;
    case ProductOriginationRejectReason.SuspendedSbl:
      return PRODUCT_MODALS_ALERT_TEXT.SBL_SUSPENDED;
    case ProductOriginationRejectReason.DeferralLoc:
      return deferralPeriodEndDate
        ? `${PRODUCT_MODALS_ALERT_TEXT.LOC_DEFERRED} on ${formatDateInDayMonthYear(
            deferralPeriodEndDate,
          )}.`
        : `${PRODUCT_MODALS_ALERT_TEXT.LOC_DEFERRED}.`;
    case ProductOriginationRejectReason.Arrears:
      return PRODUCT_MODALS_ALERT_TEXT.CAPITALPRODUCTS_IN_ARREARS;
    default:
      return '';
  }
};
