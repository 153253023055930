import { useContext } from 'react';
import { useAuth } from 'react-oidc-context';

import { type DLS_TYPES } from '@constants';
import { type ProductCVPType } from '@models';

import { openProductUrl } from '../containers/Product/utils';
import { AppContext } from '../contexts';

export const useOpenProductUrl = (dls: (typeof DLS_TYPES)[keyof typeof DLS_TYPES]) => {
  const {
    signInInfo: { countryCode },
    cdpId,
  } = useContext(AppContext);

  const {
    user: { id_token: token },
  } = useAuth();

  return (productCVPType: ProductCVPType) => {
    openProductUrl(countryCode, token, dls, productCVPType, cdpId);
  };
};
