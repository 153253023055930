import { useCallback, useContext, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

import type { AlertProps } from '@all-in-one-web/common-ui';
import { Info } from '@prospa/icons';
import { Bolt, Calendar, CrossCircle, DollarCycle, Lock, Tune } from '@prospa/salt-icons';
import { LOC as LOCIcon, SBL as SBLIcon, SBLPlus as SBLPlusIcon } from '@prospa/salt-illustrations';

import { ProductOriginationRejectReason, useGetProductCvpPageDataQuery } from '@generated-fg';

import { DLS_TYPES, PRODUCT_MODALS } from '@constants';
import { ProductCVPType } from '@models';
import { trackBAActionEvent } from '@utils';

import { AppContext } from '../../contexts';
import styles from './Product.module.scss';
import { ProductCVPPageData } from './types';
import { getAlertText, openProductUrl } from './utils';

export const useProductCVPPageData = (): ProductCVPPageData => {
  const {
    signInInfo: { countryCode },
    cdpId,
  } = useContext(AppContext);

  const {
    data: { user: { productOriginationStatuses } = {} } = {},
    loading: productsPageCVPDataLoading,
  } = useGetProductCvpPageDataQuery();

  const {
    user: { id_token: token },
  } = useAuth();

  const getAlertProps = useCallback(
    (
      reason: ProductOriginationRejectReason,
      canApply?: boolean,
    ): AlertProps | (AlertProps & { show: boolean }) => {
      const baseProps: AlertProps = {
        alertType: 'Info',
        icon: <Info width={24} height={24} />,
        children: getAlertText({
          deferralPeriodEndDate: productOriginationStatuses?.deferralPeriodEndDate,
          reason,
        }),
        className: styles.ProductModalAlert,
      };

      return typeof canApply !== 'undefined' ? { ...baseProps, show: !canApply } : baseProps;
    },
    [productOriginationStatuses],
  );

  const productCVPPageProps = useMemo(() => {
    return [
      {
        icon: <LOCIcon />,
        title: PRODUCT_MODALS.LOC.TITLE,
        subTitle: PRODUCT_MODALS.LOC.SUBTITLE,
        items: [
          {
            icon: <Bolt />,
            title: 'Easy application',
            subTitle: 'Apply in 10 minutes with minimal paperwork.',
          },
          {
            icon: <DollarCycle />,
            title: 'Access from $2K to $500K',
            subTitle: 'Fast application and a quick decision with funding possible in hours.',
          },
          {
            icon: <Calendar />,
            title: 'Flexible repayments',
            subTitle: 'Only pay interest on the funds you use.',
          },
          {
            icon: <CrossCircle />,
            title: 'No upfront security',
            subTitle: 'No property security required to access up to $150K in Prospa funding.',
          },
        ],
        type: ProductCVPType.LOC,
        ...(productOriginationStatuses?.lineOfCredit?.canApply && {
          primaryButtonProps: {
            onClick: () => {
              trackBAActionEvent('loc_get_started_click');
              openProductUrl(countryCode, token, DLS_TYPES.CAPITAL, ProductCVPType.LOC, cdpId);
            },
            label: PRODUCT_MODALS.BUTTON_TEXT,
            loading: productsPageCVPDataLoading,
            disabled: productsPageCVPDataLoading,
          },
        }),
        ...(!productOriginationStatuses?.lineOfCredit?.canApply && {
          alertProps: getAlertProps(productOriginationStatuses?.lineOfCredit?.reason),
        }),
      },
      {
        icon: <SBLIcon />,
        title: PRODUCT_MODALS.SBL.TITLE,
        subTitle: PRODUCT_MODALS.SBL.SUBTITLE,
        items: [
          {
            icon: <Bolt />,
            title: 'Easy application',
            subTitle: 'Apply in 10 minutes with minimal paperwork.',
          },
          {
            icon: <DollarCycle />,
            title: 'Borrow from $5K up to $150K',
            subTitle: 'Same day decision and fixed repayments for peace of mind.',
          },
          {
            icon: <Calendar />,
            title: 'Flexible repayment terms',
            subTitle:
              'Up to 3 years, with the freedom to make unlimited extra repayments to save on interest and pay off your loan faster.',
          },
          {
            icon: <CrossCircle />,
            title: 'No upfront security',
            subTitle: 'No property security required to access up to $150K in Prospa funding.',
          },
        ],
        type: ProductCVPType.SBL,
        ...(productOriginationStatuses?.smallBusinessLoan?.canApply && {
          primaryButtonProps: {
            onClick: () => {
              trackBAActionEvent('sbl_get_started_click');
              openProductUrl(countryCode, token, DLS_TYPES.CAPITAL, ProductCVPType.SBL, cdpId);
            },
            label: PRODUCT_MODALS.BUTTON_TEXT,
            loading: productsPageCVPDataLoading,
            disabled: productsPageCVPDataLoading,
          },
        }),
        ...(!productOriginationStatuses?.smallBusinessLoan?.canApply && {
          alertProps: getAlertProps(productOriginationStatuses?.smallBusinessLoan?.reason),
        }),
      },
      {
        icon: <SBLPlusIcon />,
        title: PRODUCT_MODALS.SBLP.TITLE,
        subTitle: PRODUCT_MODALS.SBLP.SUBTITLE,
        items: [
          {
            icon: <DollarCycle />,
            title: 'Easy application',
            subTitle: 'Get fast approvals on funding up to $500K.',
          },
          {
            icon: <Tune />,
            title: 'Up to 5 year terms',
            subTitle: 'Choose longer terms for lower weekly repayments.',
          },
          {
            icon: <Calendar />,
            title: 'Flexible repayments',
            subTitle:
              'Make unlimited extra repayments to save on interest and pay down your loan faster.',
          },
          {
            icon: <Lock />,
            title: 'Asset security',
            subTitle: 'Security may be required when accessing over $150K in Prospa funding.',
          },
        ],
        type: ProductCVPType.SBLP,
        ...(productOriginationStatuses?.businessLoanPlus?.canApply && {
          primaryButtonProps: {
            onClick: () => {
              trackBAActionEvent('blp_get_started_click');
              openProductUrl(countryCode, token, DLS_TYPES.CAPITAL, ProductCVPType.SBLP, cdpId);
            },
            label: PRODUCT_MODALS.BUTTON_TEXT,
            loading: productsPageCVPDataLoading,
            disabled: productsPageCVPDataLoading,
          },
        }),
        ...(!productOriginationStatuses?.businessLoanPlus?.canApply && {
          alertProps: getAlertProps(productOriginationStatuses?.businessLoanPlus?.reason),
        }),
      },
    ];
  }, [
    cdpId,
    countryCode,
    getAlertProps,
    productOriginationStatuses,
    productsPageCVPDataLoading,
    token,
  ]);

  const repaymentCalculatorPageProps = useMemo(() => {
    return {
      locAlertProps: getAlertProps(
        productOriginationStatuses?.lineOfCredit?.reason,
        productOriginationStatuses?.lineOfCredit?.canApply,
      ) as AlertProps & { show: boolean },
      sblAlertProps: getAlertProps(
        productOriginationStatuses?.smallBusinessLoan?.reason,
        productOriginationStatuses?.smallBusinessLoan?.canApply,
      ) as AlertProps & { show: boolean },
    };
  }, [getAlertProps, productOriginationStatuses]);

  return { productCVPPageProps, repaymentCalculatorPageProps, loading: productsPageCVPDataLoading };
};
