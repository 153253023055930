import { useMemo } from 'react';

import { Alert, Button, ButtonType, Card } from '@all-in-one-web/common-ui';
import { Sparkle } from '@prospa/salt-icons/react';

import { currencyFormatter } from '@utils';

import { isLocCalculations } from '../../containers/RepaymentCalculator/RepaymentCalculator';
import {
  type BusinessLoanRepaymentCalculations,
  type RepaymentCalculations,
  type RepaymentCalculatorPageProps,
} from '../../containers/RepaymentCalculator/types';
import styles from './RepaymentBreakdown.module.scss';

export type RepaymentBreakdownProps = {
  alertProps: RepaymentCalculatorPageProps['sblAlertProps'];
  loading: boolean;
  onQualifyClick: () => void;
  calculations: RepaymentCalculations;
};

type RepaymentBreakdownPair = {
  label: string;
  value: string;
};

export const RepaymentBreakdown = ({
  alertProps,
  loading,
  onQualifyClick,
  calculations,
}: RepaymentBreakdownProps) => {
  const { show: showAlert, ...restAlertProps } = alertProps;

  const repaymentBreakdownPairs: RepaymentBreakdownPair[] = useMemo(() => {
    if (isLocCalculations(calculations)) return [];

    const businessLoanCalculations: BusinessLoanRepaymentCalculations =
      calculations as BusinessLoanRepaymentCalculations;

    return [
      {
        label: 'Weekly repayment',
        value: currencyFormatter(businessLoanCalculations.weeklyRepayment),
      },
      {
        label: 'Payback amount',
        value: currencyFormatter(businessLoanCalculations.paybackAmount),
      },
      {
        label: 'Loan term',
        value: `${businessLoanCalculations.loanTermYears} years`,
      },
      {
        label: 'Number of repayments',
        value: businessLoanCalculations.weeklyInstalments.toString(),
      },
    ].filter(Boolean) as RepaymentBreakdownPair[];
  }, [calculations]);

  if (isLocCalculations(calculations)) return null;

  return (
    <div>
      <Card>
        <p className="pds-lead-semibold">Your repayment breakdown</p>
        <div className={styles.RepaymentBreakdownDetailsSection}>
          {repaymentBreakdownPairs.map(pair => {
            const pairId = pair.label.toLowerCase().replace(/\s+/g, '-');
            return (
              <div className={styles.RepaymentBreakdownDetailsPair} key={pairId}>
                <div className={styles.RepaymentBreakdownDetailsLabel}>{pair.label}</div>
                <div className={styles.RepaymentBreakdownDetailsValue} data-testid={pairId}>
                  {pair.value}
                </div>
              </div>
            );
          })}
        </div>
        {showAlert && <Alert className="mt-4" {...restAlertProps} />}
        <div className={styles.RepaymentBreakdownButtonSection}>
          {!alertProps.show && (
            <div className={styles.RepaymentBreakdownButtonSectionCaption}>
              <Sparkle className={styles.RepaymentBreakdownButtonSectionIcon} />
              Ready for the next step?
            </div>
          )}

          <Button
            className={styles.RepaymentBreakdownButtonSectionButton}
            label="See if you qualify"
            type={ButtonType.PRIMARY}
            onClick={onQualifyClick}
            loading={loading}
            disabled={alertProps.show || loading}
            data-testid="qualify-button"
          />
        </div>
      </Card>
    </div>
  );
};
