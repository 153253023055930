import { UtilitiesCalendar3Mons as CalendarIcon } from '@prospa/icons';

import { Alert, Button } from '@components';

import { ButtonSize, ButtonType } from '@constants';
import { currencyFormatter, formatDateInDayMonthYear } from '@utils';

import style from './LoanUpcomingRepayment.module.scss';
import type { LoanUpcomingRepaymentProps } from './types';

export const LoanUpcomingRepayment = ({
  paymentAmount,
  paymentFrequency,
  nextDebitDate,
  onMakeRepayment,
  daysUntilNextDebitDate,
  showLoanUpcomingRepayment,
  showAdditionalRepaymentButton,
}: LoanUpcomingRepaymentProps) => {
  if (showAdditionalRepaymentButton) {
    return (
      <Alert
        alertType="Default"
        icon={<CalendarIcon width="24" height="24" />}
        className={style.LoanUpcomingRepaymentContainer}
      >
        <div className={style.LoanUpcomingRepaymentContainer} data-testid="loan-upcoming-repayment">
          <span>Make a repayment of any amount towards your outstanding balance</span>
          <Button
            type={ButtonType.ICON_LINK_UNDERLINE}
            size={ButtonSize.SMALL}
            onClick={onMakeRepayment}
            className={style.LoanUpcomingRepaymentMakeRepaymentButton}
          >
            Make repayment
          </Button>
        </div>
      </Alert>
    );
  }
  if (!showLoanUpcomingRepayment || !nextDebitDate || !daysUntilNextDebitDate) return null;

  return (
    <Alert alertType="Default" icon={<CalendarIcon width="24" height="24" />}>
      <div className={style.LoanUpcomingRepaymentContainer} data-testid="loan-upcoming-repayment">
        <span>
          {`Next${
            paymentFrequency ? ` ${paymentFrequency.toLocaleLowerCase()}` : ''
          } direct debit repayment of`}
          <b>{` ${currencyFormatter(paymentAmount)} ${daysUntilNextDebitDate} `}</b>(
          {formatDateInDayMonthYear(nextDebitDate)})
        </span>
        {onMakeRepayment && (
          <Button
            type={ButtonType.ICON_LINK_UNDERLINE}
            size={ButtonSize.SMALL}
            onClick={onMakeRepayment}
            className={style.LoanUpcomingRepaymentMakeRepaymentButton}
          >
            Make additional repayment
          </Button>
        )}
      </div>
    </Alert>
  );
};
